import {
  IonBackButton,
  IonBreadcrumb,
  IonBreadcrumbs,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonList,
  IonMenuButton,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import { 
  arrowBack
} from 'ionicons/icons';
import { UserList, UserMenu } from "./MenuOfUserSession";
import { useAuth } from "../user/AuthContext";
import BadgeLoading from "../../components/ui/BadgeLoading";
import { specDesign } from "../../customModules/specSite";
import { useLayoutHome } from "../layoutHome/useLayoutHome";
import { useEffect, useRef } from "react";
import PartOfModule from "../../components/Module/PartOfModule";
import { useScrollUtils } from "./useScrollUtils";
import useBreakpoints from "../../libs/useBreakpoints";
import { AlertBilling } from "../instance/AlertBilling";
import { useDesign } from "../blockStack/DesignLoader";


export function LayoutAdmin({ 
  title,
  breadcrumbs,
  ToolbarLeft,
  ToolbarRight,
  ToolbarFull,
  TitleToolbarRight,
  defaultHref,
  forceBack = false,
  showGoBack = true,
  showMenu = true,
  showGoToProfile = true,
  showRefresher = true,
  mainSectionClass,
  className,
  enableContentScrollY = true,
  history,
  children
 }) {
  const { userAuth } = useAuth();
  const menuHeaderRef = useRef();

  const { handleScrollUpdate, mainIonContentRef } = useScrollUtils(); 
  const { isMinBreakpointActive } = useBreakpoints();

  const handleRefresh = () => {
    setTimeout(() => {
      window.location.reload();
    }, 400);
  };
  
  return (
    <IonPage id="admin">
      {!userAuth?.userDoc?.data ? (
        <div className="py-12 flex place-content-center content-center items-center font-brand-main">
          <BadgeLoading className="text-brand-dark" />
        </div>
      ) : (<>
        {/* menu header */}
        <IonHeader id="menu-header" className="z-30 shadow-none" ref={menuHeaderRef}>
          <IonToolbar className="main-toolbar" color="light">
            <IonButtons slot="start">
              {showMenu ? (
                <IonMenuButton menu="main"></IonMenuButton>
              ) : null}
              {showGoBack ? (
                forceBack ? (
                  <IonButton onClick={()=> { history.push(defaultHref); }} >
                    <IonIcon slot="icon-only" icon={arrowBack} />
                  </IonButton>
                ) : (
                  <IonBackButton defaultHref={defaultHref}></IonBackButton>
                )
              ) : null}
              {ToolbarLeft ? (
                <ToolbarLeft />
              ) : null}
            </IonButtons>
            {isMinBreakpointActive('lg') ? (<>
              {breadcrumbs?.length ? (
                <IonBreadcrumbs>
                  {breadcrumbs?.map(({ url, title }, index) => (
                    <IonBreadcrumb className="" routerLink={url} routerDirection="back" key={index}>{title}</IonBreadcrumb>
                  ))}
                </IonBreadcrumbs>
              ) : null}
              {title ? (
                <IonTitle>{title}</IonTitle>
              ) : null}
            </>) : null}

            <div className="header-title-logo absolute !top-0 !left-0 w-full items-center">
              <PartOfModule
                type="main"
                action="LayoutAdminHeaderTitle"
                param={{ history, menuHeaderRef }}
              />
            </div>
            
            <IonButtons slot="end">
              {isMinBreakpointActive('lg') ? (<>
                {TitleToolbarRight ? (
                  <TitleToolbarRight />
                ) : null}
              </>) : null}
              {ToolbarRight ? (
                <ToolbarRight />
              ) : null}
              <UserMenu menuTrigger="user-menu">
                <IonList>    
                  <UserList
                    menuTrigger="user-menu"
                    triggerMode="button"
                    showCurrentUser={true}
                    showCurrentUserPic={true}
                    showGoToProfile={showGoToProfile}
                    history={history}
                  />
                </IonList>
              </UserMenu>
            </IonButtons>
          </IonToolbar>
          {ToolbarFull ? (
            <ToolbarFull />
          ) : null}
        </IonHeader>

        {/* content */}
        <IonContent
          ref={mainIonContentRef}
          className={className + ' main-content'}
          scrollY={enableContentScrollY}
          scrollEvents={true}
          onIonScroll={handleScrollUpdate}
        >
          {showRefresher ? (
            <IonRefresher slot="fixed" pullFactor={0.5} pullMin={100} pullMax={200} onIonRefresh={handleRefresh}>
              <IonRefresherContent></IonRefresherContent>
            </IonRefresher>
          ) : null}

          {(title || breadcrumbs) ? (
            <>
              {!isMinBreakpointActive('lg') ? (<>
                <IonToolbar color="secondary" className="header-toolbar">
                  {breadcrumbs?.length ? (
                    <IonBreadcrumbs>
                      {breadcrumbs?.map(({ url, title }, index) => (
                        <IonBreadcrumb routerLink={url} routerDirection="back" key={index}>{title}</IonBreadcrumb>
                      ))}
                    </IonBreadcrumbs>
                  ) : null}
                  {title ? (
                    <IonTitle>{title}</IonTitle>
                  ) : null}
                  {TitleToolbarRight ? (
                    <IonButtons slot="end">
                      <TitleToolbarRight />
                    </IonButtons>
                  ) : null}
                </IonToolbar>
              </>) : null}
            </>
          ) : null}

          {children}
          
          <PartOfModule
            type="main"
            action="LayoutAdminUnderContent"
            param={{ history }}
          />

          <AlertBilling userAuth={userAuth} history={history} />
        </IonContent>
      </>)}
    </IonPage>
  );
}