import * as invoices from "./invoices";
import * as contracts from "./contracts";
import dataTypeInvoiceCustomer from "./dataTypeInvoiceCustomer";
import dataTypeInvoiceTaxes from "./dataTypeInvoiceTaxes";
import dataTypeInvoiceItems from "./dataTypeInvoiceItems";
import dataTypePaymentConfig from "./dataTypePaymentConfig";
import { RoutePaymentsVerify } from "./RoutePaymentsVerify";
import dataTypeInvoiceBook from "./dataTypeInvoiceBook";
import { columnRightClasses } from "../entity/RouteEntityCrudForm";
import CreditCollectionsEntity from "./creditCollections";


export default function () {
  return {
    name: 'Ventas',
    slug: 'sales',
    scope: 'instance',
    bundles: ['starter'],
    entities: [
      'invoices',
      'payments',
      'creditCollections',
      'invoiceBooks',
      'contracts',
      'contractsModels'
    ],
    permissions: [
      { slug: 'invoices', label: 'Facturas', actionsToAdd: ['tab:sales', 'print']},
      { slug: 'payments', label: 'Pagos' },
      ...CreditCollectionsEntity.permissions,
      { slug: 'invoiceBooks', label: 'Talonarios de facturas' },
      { slug: 'contracts', label: 'Contrataciones' },
      { slug: 'contractsModels', label: 'Modelos de contratos' },
    ],
    routesAdmin: {
      'payments': {
        'verify': {
          permission: { resource: 'instance.payments', action: ['update'] },
          Content: RoutePaymentsVerify
        }
      },
    },
    components: {
      'invoices': {
        crud: {
          ListItemExtraActions: invoices.ListItemExtraActionsInvoices,
          FormToolbarEnd: invoices.FormToolbarEndInvoices,
          ShowItemExtraActions: invoices.ShowItemExtraActionsInvoices
        },
        codedDataTypes: {
          'serialCode': dataTypeInvoiceBook,
          'customer': dataTypeInvoiceCustomer,
          'items': dataTypeInvoiceItems,
          'taxes': dataTypeInvoiceTaxes,
        }
      },
      'contracts': {
        crud: {
          settings: () => ({
            columnRightTaxonomyTypesSlugs: null,
          }),
          'FormAppendColumnRight': {
            classes: columnRightClasses,
            Render: contracts.FormAppendColumnRight
          },
          'FormAppend': contracts.FormAppend,
          'FormAppend-modelId': contracts.ContractModelForeignForm
        }
      },
      'contractsModels': {
        codedDataTypes: {
          'paymentConfig': dataTypePaymentConfig
        }
      },
      'creditCollections': CreditCollectionsEntity.components
    },
    events: {
      'invoices': {
        onFormChange: invoices.hooksOnFormChange,
        afterRead: invoices.afterRead
      },
      'contracts': {
        afterRead: contracts.afterRead,
        afterSave: contracts.afterSave
      },
      'creditCollections': CreditCollectionsEntity.events
    }
  }
}