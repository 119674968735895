import React, { Fragment } from 'react';
import BtnSignUpModal from '../../../components/ui/BtnSignUpModal';
import IonContactBTN from '../../../components/ui/IonContactBTN';
import { IonButton } from '@ionic/react';
import { compileStringTemplate, stackClasses } from '../../../libs/utils';
import Icon from '../../../components/ui/Icon';


export default function () {
  return {
    part: 'btnList',
    template: BtnList
  };
};

export const btnListClasses = `
  flex flex-wrap flex-row flex-column 
  gap-0 gap-0.5 gap-1 gap-1.5 gap-2 gap-2.5 gap-3 gap-4 gap-5 gap-6 gap-7 gap-8 gap-9 gap-10
  items-center justify-center
  items-start justify-start
  items-end justify-end
  items-stretch justify-stretch
  items-baseline justify-baseline
`;

export function BtnList (props) {
  let { id, strings = {}, specDesign = {}, params = {}, classes = {}, history, instance } = props;

  let styles = {};
  let btnListClasses = 'flex flex-wrap';

  if (params.type === 'row') {
    btnListClasses += ' flex-row';
  } else if (params.type === 'col') {
    btnListClasses += ' flex-col';
  }

  if (params.align) {
    btnListClasses += ` items-${params.align}`;
  }

  if (params.justify) {
    btnListClasses += ` justify-${params.justify}`;
  }

  if (params.gap) {
    btnListClasses += ` gap-${params.gap}`;
  }

  return (
    <div id={id} className={`${btnListClasses} ${classes.btnListContainer}`} style={styles}>
      {strings.items?.map(({
        ctaLabel,
        ctaType,
        ctaLink,
        ctaWhatsapp,
        ctaSignUp,
        btnSize,
        btnColor,
        btnFill,
        onClick,
        ionIcon,
        ionIconAlt,
        faIcon,
        faIconAlt,
        // classes,
        isCurrent
      }, index) => {
        let classesToUse = stackClasses(classes, strings.items[index].classes);
        return (
          <Fragment key={index}>
            {ctaType === "whatsapp" ? (
              <IonContactBTN
                label={ctaLabel}
                number={ctaWhatsapp?.useBrandPhone ? specDesign?.stringsVars?.brand?.whatsappNumber : ctaWhatsapp?.phoneNumber}
                message={compileStringTemplate(ctaWhatsapp?.phoneMessage, specDesign?.stringsVars)}
                fill={btnFill}
                color={btnColor}
                size={btnSize}
              />
            ) : null}
            {ctaType === "signup" && ctaSignUp ? (
              <BtnSignUpModal 
                strings={{
                  ctaLabel: ctaSignUp.ctaLabel,
                  modalTitle: ctaSignUp.modalTitle,
                  modalContent: ctaSignUp.modalContent
                }}
                history={history}
                instance={instance}
                design="small"
                fill={btnFill}
                color={btnColor}
                size={btnSize}
              />
            ) : null}
            {ctaType === "link" ? (
              ctaLink?.ctaTarget === "router" ? (
                <IonButton
                  routerLink={ctaLink?.ctaUrl}
                  fill={btnFill}
                  color={btnColor}
                  size={btnSize}
                  className={classesToUse?.btn}
                >
                  <Icon {...{...strings.items[index]}} />
                  {ctaLabel}
                </IonButton>
              ) : (
                <IonButton
                  href={ctaLink?.ctaUrl}
                  target={ctaLink?.ctaTarget}
                  fill={btnFill}
                  color={btnColor}
                  size={btnSize}
                  className={classesToUse?.btn}
                >
                  <Icon {...{...strings.items[index]}} />
                  {ctaLabel}
                </IonButton>
              )
            ) : null}
            {ctaType === "button" ? (
              <IonButton 
                onClick={() => onClick && onClick(strings.items[index])}
                fill={btnFill}
                color={btnColor}
                size={btnSize}
                className={classesToUse?.btn}
              >
                <Icon {...{...strings.items[index]}} slot="start" classes={classesToUse} />
                {ctaLabel}
              </IonButton>
            ) : null}
          </Fragment>
        );
      })}
    </div>
  );
}
  
