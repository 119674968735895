import _ from 'lodash';
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useMessages } from '../message/useMessages';


const secretaryContext = createContext();

export const useSecretary = () => {
  const context = useContext(secretaryContext);
  if (!context) throw new Error("There is no Secretary provider");
  return context;
};

export function SecretaryProvider({ children }) {
  const trackContainerRef = useRef(null);
  const inputContainerRef = useRef(null);
  const inputRef = useRef(null);
  const [ text, setText ] = useState('');
  const messagesLibs = useMessages({ 
    contextConfig: {
      place: 'conversation',
      host: 'user',
      profileId: 'secretary'
    }
  });
  let {
    ConversationsModel, MessagesModel, 
    loading, setLoading, 
    setContext, context, 
    sendMessage, chatParams, publicProfileOfUser, 
    conversationDoc, messages
  } = messagesLibs;

  const onSend = async ({ type, text }) => {
    await sendMessage({ type, text });
  };

  return (
    <secretaryContext.Provider
      value={{
        trackContainerRef, inputContainerRef, inputRef,
        text, setText, onSend, messages, chatParams,
        loading
      }}
    >
      {children}
    </secretaryContext.Provider>
  );
}
