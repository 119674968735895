import { useEffect, useState } from 'react';
import { useForm } from 'react-final-form';
import { useCreditCollectionsStore } from './useCreditCollectionsStore';
import { inputClasses } from '../../../components/EntityTaxonomyForm';
import { getAmountNum, ShowAmount, toAmount } from '../../../components/Form/utilsCurrency';
import dataTypeInvoiceForCreditCollection from './dataTypeInvoiceForCreditCollection';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { usePanel } from '../../panel/usePanel';
import toast from 'react-hot-toast';
import { withPrefix } from '../../instance/utilsInstance';
import dayjs from 'dayjs';


const TotalAndInvoice = ({ instance }) => {
  const form = useForm();
  const {
    contactClientDoc,
    contractsOfContact,
    paymentsByContract,
    selectedPaymentsIds,
    saveInvoiceForCreditCollection
  } = useCreditCollectionsStore();
  const [ invoiceData, setInvoiceData ] = useState(null);
  const history = useHistory();
  const { attachPrefix } = usePanel();
  
  const calculateTotalAmount = () => {
    let totalAmount = 0;
    selectedPaymentsIds.forEach(paymentId => {
      const payment = paymentsByContract[Object.keys(paymentsByContract).find(key => paymentsByContract[key].find(p => p.id === paymentId))]?.find(p => p.id === paymentId);
      if (payment) {
        totalAmount += getAmountNum(payment.data.amount);
      }
    });
    return toAmount(totalAmount);
  };

  const invoiceDataGetter = () => {
    let data = {
      number: '001-001-0000111111',
      invoiceBookId: '002274c472a9fki4crkuc',
      issuedDate: dayjs().toISOString(),
      saleCondition: 'cash',

      customer: {
        type: 'saved',
        contactId: contactClientDoc?.id
      },

      // payments summary by contract
      items: (() => {
        const items = [];
        const paymentsByContractItems = {};

        selectedPaymentsIds.forEach(paymentId => {
          for (const contractId in paymentsByContract) {
            const payment = paymentsByContract[contractId].find(p => p.id === paymentId);
            if (payment) {
              if (!paymentsByContractItems[contractId]) {
                paymentsByContractItems[contractId] = {
                  contractId: contractId,
                  payments: [],
                  totalAmount: 0
                };
              }
              paymentsByContractItems[contractId].payments.push(payment);
              paymentsByContractItems[contractId].totalAmount += getAmountNum(payment.data.amount);
              break; // Payment found, move to next selectedPaymentId
            }
          }
        });

        for (const contractId in paymentsByContractItems) {
          const contractPaymentsData = paymentsByContractItems[contractId];
          items.push({
            qty: toAmount(1), // Or toAmount(contractPaymentsData.payments.length) if we want to show qty of payments
            descripcion: `Pagos correspondientes al contrato nro. ${contractId}`, // Improve description later if needed, maybe contract number from contractsOfContact
            price: toAmount(contractPaymentsData.totalAmount),
            totalPrice: toAmount(contractPaymentsData.totalAmount),
            taxes: {
              exempt: toAmount(0),
              vat5: toAmount(0),
              vat10: toAmount(0)
            },
          });
        }
        return items;
      })()

      // totalPrice: '',
      // taxes: ''
    };
    return data;
  };

  const handleSaveInvoice = async (docToCreate) => {
    setInvoiceData(docToCreate);
    const { invoiceDoc } = await saveInvoiceForCreditCollection(docToCreate?.data, instance);
    history.push(attachPrefix(`/a/entity/${withPrefix(instance, 'invoices')}/${invoiceDoc?.id}`));
    toast('Factura creada con éxito');
  };

  useEffect(() => {
    // Sync selected payments with form
    form.change('paymentsIds', selectedPaymentsIds);
    const totalAmount = calculateTotalAmount();
    form.change('amountOfPayments', totalAmount);
  }, [selectedPaymentsIds, form]);

  // console.log('paymentsByContract', paymentsByContract)
  // console.log('contractsIds', contractsIds)
  // console.log('paymentsByContract', paymentsByContract)
  // console.log('selectedPaymentsIds', selectedPaymentsIds)
  
  return (
    <div className="ml-4 flex flex-row gap-4 align-middle">
      {/* Total de pagos seleccionados */}
      {selectedPaymentsIds.length > 0 && (
        <div className="flex flex-col">
          <span className={`${inputClasses.fieldLabel} !mb-0`}>
            Total de pagos
          </span>
          <ShowAmount amount={form.getState().values.amountOfPayments} />
        </div>
      )}

      {/* Botón de crear factura */}
      <dataTypeInvoiceForCreditCollection.RenderForm
        instance={instance}
        value={invoiceData}
        onChange={handleSaveInvoice}
        getData={invoiceDataGetter}
      />
    </div>
  );
};

export default TotalAndInvoice;
