/**
 * ## Example usage:
 * 
 * const colors = {
 *   primary: '#211cf4',
 *   secondary: '#1ac3ff',
 *   tertiary: '#9e67f7'
 * };
 * 
 * @param {} colors = { primary: '#ccc', ... }
 * @returns 
 */
export function generateColorPalette(colors) {
  // Helper function to convert hex to RGB
  function hexToRgb(hex) {
    const bigint = parseInt(hex.slice(1), 16);
    return {
      r: (bigint >> 16) & 255,
      g: (bigint >> 8) & 255,
      b: bigint & 255,
    };
  }

  // Helper function to convert RGB to hex
  function rgbToHex(r, g, b) {
    return (
      "#" +
      ((1 << 24) + (r << 16) + (g << 8) + b)
        .toString(16)
        .slice(1)
        .toUpperCase()
    );
  }

  // Helper function to calculate contrast color
  function getContrastYIQ(hexcolor) {
    const { r, g, b } = hexToRgb(hexcolor);
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? "#000000" : "#FFFFFF";
  }

  // Helper function to calculate shade
  function shadeColor(color, percent) {
    const { r, g, b } = hexToRgb(color);
    return rgbToHex(
      Math.floor(r * (1 - percent / 100)),
      Math.floor(g * (1 - percent / 100)),
      Math.floor(b * (1 - percent / 100))
    );
  }

  // Helper function to calculate tint
  function tintColor(color, percent) {
    const { r, g, b } = hexToRgb(color);
    return rgbToHex(
      Math.floor(r + (255 - r) * (percent / 100)),
      Math.floor(g + (255 - g) * (percent / 100)),
      Math.floor(b + (255 - b) * (percent / 100))
    );
  }

  const palette = {};

  Object.entries(colors).forEach(([colorName, colorValue='']) => {
    palette[colorName] = colorValue;
    palette[`${colorName}-contrast`] = getContrastYIQ(colorValue);
    palette[`${colorName}-shade`] = shadeColor(colorValue, 10);
    palette[`${colorName}-tint`] = tintColor(colorValue, 10);
  });

  return palette;
}
