import _ from 'lodash';
import { useEffect, useState, useRef } from "react";
import { Link } from 'react-router-dom';
import { useAuth } from "../../modules/user/AuthContext";
import { animateScroll } from 'react-scroll';
import { FaBars } from 'react-icons/fa';
import { PartLogo } from "../../modules/blockStack/parts/PartLogo";
import { useScrollUtils } from "../../modules/panel/useScrollUtils";
import { stackClasses } from "../../libs/utils";
import { urlPrefix, withPrefix } from "../../modules/instance/utilsInstance";
import { Background } from '../../modules/blockStack/parts/PartBackground';
import { useModule } from '../../libs/ModuleContext';
import { logInOutline, createOutline } from "ionicons/icons";
import { IonIcon } from '@ionic/react';
// import useBreakpoints from '../../libs/useBreakpoints';
// import config from '../../config';


export const MenuHome = (props) => {
  let {
    pageDoc,
    HeaderLeft,
    HeaderMenu,
    fullMenuHeader,
    specDesign,
    instance,
    match
  } = props;
  const menuRef = useRef();
  const { mainIonContentRef, isNavbarOpen, setIsNavbarOpen, isScrollUnderOffset } = useScrollUtils();
  const { user } = useAuth();
  const [menuHeight, setMenuHeight] = useState();
  // const { isMinBreakpointActive } = useBreakpoints();
  const homePath = urlPrefix(instance, '/');
  const panelPath = urlPrefix(instance, '/a/panel');
  const editPath = urlPrefix(instance, `/a/pages/creator/#/docId/${pageDoc?.id}`);
  const showMenuTrigger = HeaderMenu || user?.userDoc?.id;
  const { isAllowed } = useModule();
  
  const logoParamsRef = useRef(specDesign?.blocks?.menuMain?.logo || [{
    screen: 'xxs',
    source: 'profileImg',
    size: 'xxs',
    resolution: 'xs',
    shape: 'square',
    round: 'full',
    showSiteName: true
  }]);

  const bgStyles = useRef(specDesign?.blocks?.menuMain?.bgStyles || [{
    screen: 'xxs',
    type: 'color',
    bgColor: 'dark-shade'
  }]);

  const classes = stackClasses(props.classes, {
    link: `py-1.5 px-4 bg-brand-light hover:bg-brand-light-tint active:bg-brand-light-shade text-brand-light-contrast text-sm rounded`,
  }); // TODO add menu main part

  const showFullHeader = isNavbarOpen || fullMenuHeader || isScrollUnderOffset
  // useMemo(() => {
  //   const topOffset = config.modules.panel.scrollYtoShowFixedNavbar;
  //   const contentScrollTop = mainIonContentRef?.current?.scrollTop;
  //   console.log('memo', topOffset, contentScrollTop, !isMinBreakpointActive('lg'))
  // return ;
  // }, [isNavbarOpen, fullMenuHeader, isScrollUnderOffset]);

  useEffect(() => {
    const handleResize = () => {
      setMenuHeight(menuRef.current.getBoundingClientRect().height);
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  const MenuItems = () => (<>
    {HeaderMenu ? (
      <HeaderMenu menuHeight={menuHeight} classes={classes}></HeaderMenu>
    ) : ''}
    {/* edit page */}
    {!match.url.includes('/a/pages/creator') && pageDoc?.id && isAllowed(withPrefix(instance, 'pages'), ['update']) ? (
      <Link
      className={classes.link}
      to={editPath}>
        <IonIcon className="inline-block -mb-1 mr-1 relative w-5 h-5" icon={createOutline}></IonIcon>
        Editar
      </Link>
    ) : ''}
    {/* go to panel */}
    {user?.userDoc?.id ? (
      <Link
      className={classes.link}
      to={panelPath}>
        <IonIcon className="inline-block -mb-1 mr-1 relative w-5 h-5" icon={logInOutline}></IonIcon>
        Panel
      </Link>
    ) : ''}
  </>);

  const BurguerTrigger = ({ className }) => (
    <div className={`justify-self-end ${className}`}>
      <button
        className={`p-2 outline-none ${isNavbarOpen ? 'text-brand-secondary' : 'text-brand-light'}`}
        onClick={() => setIsNavbarOpen(!isNavbarOpen)}
      >
        <FaBars size={21} />
      </button>
    </div>
  );
  
  return (
    <header ref={menuRef}>
      <div 
        id="menu-header" 
        className={`
          !z-[300] fixed w-full top-0 shadow-sm overflow-hidden
          ${showFullHeader ? 'transition-opacity-full' : 'transition-opacity-none'}
        `}
      >
        <Background
          params={bgStyles.current}
          specDesign={specDesign}
        >
          <div className={`flex justify-between place-items-center px-4 lg:px-10 w-full`}>
            {/* logos  */}
            <div className="py-2">
              <Link
                to={homePath}
                title={specDesign?.siteName}
                onClick={() => animateScroll.scrollToTop()}
                className="flex flex-row items-center"
              >
                <PartLogo
                  params={logoParamsRef.current}
                  specDesign={specDesign}
                  instance={instance}
                />
              </Link>
            </div>

            {/* HeaderLeft */}
            {HeaderLeft ? (
              <HeaderLeft menuHeight={menuHeight}></HeaderLeft>
            ) : ''}

            {/* menu desktop */}
            <div className={`text-xs hidden sm:flex flex-row items-end gap-2`}>
              <MenuItems />
            </div>

            {/* menu movil hamburger */}
            {showMenuTrigger ? (<BurguerTrigger className="sm:hidden" />) : null}
          </div>

          {/* menu movil expanded */}
          <div className={`sm:block md:hidden bg-transparent transition-all duration-300 overflow-hidden ease-in-out
            ${isNavbarOpen ? "max-h-96" : "max-h-0"}
          `}>
            <div className="">
              <div className="py-4 mb-2 mx-4 text-xs flex flex-col gap-2 sm:hidden border-t border-brand-light">
                <MenuItems />
              </div>
            </div>
          </div>
        </Background>
      </div>

      {/* menu desktop on top */}
      <div className={`!z-[200] absolute top-0 flex flex-row flex-nowrap place-content-end px-4 lg:px-10 py-4 w-full text-xs space-x-2`}>
        <div className={`text-xs hidden sm:flex flex-row items-end gap-2`}>
          <MenuItems />
        </div>
        <div className="block sm:hidden">
          {/* menu movil hamburger */}
          {showMenuTrigger ? (<BurguerTrigger />) : null}
        </div>
      </div>
    </header>
  );
};