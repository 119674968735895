import { IonIcon } from "@ionic/react";
import { AnimationAddClassWhenVisible } from "../../../components/ui/AnimationAddClassWhenVisible";
import { lineBreaks } from "../../../libs/utils";
import { Background } from "../parts/PartBackground";
import { Text } from "../parts/PartText";
import { Card } from "../parts/PartCard";
import { cardContainerStylesField } from "../parts/stylesFields";
import { CardContainer } from "../parts/PartCardContainer";
import { Image } from "../parts/PartImage";
import { BtnsList, sectionCardBtnsFields } from "./BlockSectionCardBtns";

export default function () {
  return {
    type: "textGrid",
    variant: "compact",
    title: "Compacto",
    template: TextGridBCompact,
    presets,
    params: {
      bgStyles: {
        name: "Fondo",
        type: "part",
        part: "background",
      },
      cardContainerStyles: cardContainerStylesField,

      // intro
      introCardStyles: {
        name: "Tarjeta de introducción",
        type: "part",
        part: "card",
      },
      introTitleStyles: {
        name: "Intro: Título del bloque",
        descriptionText: "Estilos según tamaño de pantalla",
        type: "part",
        part: "text",
      },
      introTitleCardStyles: {
        name: "Intro: Tarjeta del título del bloque",
        descriptionText: "Estilos según tamaño de pantalla",
        type: "part",
        part: "card",
      },
      introSubtitleStyles: {
        name: "Intro: Subtítulo del bloque",
        descriptionText: "Estilos según tamaño de pantalla",
        type: "part",
        part: "text",
      },
      introSubtitleCardStyles: {
        name: "Intro: Tarjeta del subtítulo del bloque",
        descriptionText: "Estilos según tamaño de pantalla",
        type: "part",
        part: "card",
      },

      // items
      itemCardStyles: {
        // Añade estilos para la tarjeta del item
        name: "Tarjeta de cada item",
        type: "part",
        part: "card",
      },
      imgCardStyles: {
        name: "Items: Tarjeta de la imagen",
        descriptionText: "Estilos según tamaño de pantalla",
        type: "part",
        part: "card",
      },
      titleStyles: {
        // Añade estilos para el título
        name: "Items: Estilos para títulos",
        descriptionText: "Estilos según tamaño de pantalla",
        type: "part",
        part: "text",
      },
      titleCardStyles: {
        // Añade estilos para la tarjeta del título
        name: "Items: Tarjeta del Título",
        descriptionText: "Estilos según tamaño de pantalla",
        type: "part",
        part: "card",
      },
      contentStyles: {
        // Estilos para el contenido
        name: "Contenido",
        descriptionText: "Estilos según tamaño de pantalla",
        type: "part",
        part: "text",
      },
      contentCardStyles: {
        // Añade estilos para la tarjeta del contenido
        name: "Items: Tarjeta del Contenido",
        descriptionText: "Estilos según tamaño de pantalla",
        type: "part",
        part: "card",
      },
      btnListStyles: {
        name: "Botones",
        descriptionText: "Estilos según tamaño de pantalla",
        type: "part",
        part: "card",
      },
    },
    // fields of text tab
    strings: {
      title: {
        type: "textArea",
        name: "Título",
      },
      subtitle: {
        type: "textArea",
        name: "Subtítulo",
      },
      // {items: [{icon,title,content}]}
      items: {
        type: "collection",
        name: "items",
        fields: {
          // 'icon': {
          //   type: '',
          //   name: 'icono'
          // },
          img: {
            name: "Imagen",
            type: "part",
            part: "image",
          },
          title: {
            type: "textArea",
            name: "Título",
          },
          content: {
            type: "textArea",
            name: "Contenido",
          },
          btnList: {
            type: "collection",
            name: "Botones",
            fields: sectionCardBtnsFields,
          },
        },
      },
    },
    partsMap: [
      {
        label: "Bloque",
        background: ["bgStyles"],
        card: ["cardContainerStyles"],
      },
      {
        label: "Introducción",
        card: ["introCardStyles"],
      },
      {
        label: "Titulo de introducción",
        strings: ["title"],
        text: ["introTitleStyles"],
        card: ["introTitleCardStyles"],
      },
      {
        label: "Subtitulo de introducción",
        strings: ["subtitle"],
        text: ["introSubtitleStyles"],
        card: ["introSubtitleCardStyles"],
      },
      {
        label: "Items",
        strings: ["items"],
        card: ["itemCardStyles"],
      },
      {
        label: "Imagen de item",
        card: ["imgCardStyles"],
      },
      {
        label: "Título de item",
        text: ["titleStyles"],
        card: ["titleCardStyles"],
      },
      {
        label: "Contenido de item",
        text: ["contentStyles"],
        card: ["contentCardStyles"],
      },
      {
        label: "Botones",
        card: ["btnListStyles"],
      },
    ],
  };
}

export function TextGridBCompact(props) {
  let {
    title,
    subtitle,
    items,
    bgStyles,
    cardContainerStyles,
    introCardStyles,
    introTitleStyles,
    introTitleCardStyles,
    introSubtitleStyles,
    introSubtitleCardStyles,
    itemCardStyles,
    titleStyles,
    titleCardStyles,
    contentStyles,
    contentCardStyles,
    imgCardStyles,
    history,
    instance,
    specDesign,
  } = props;

  return (
    <Background
      params={bgStyles}
      classes={{ bgContainer: "snap-start" }}
      specDesign={specDesign}
    >
      <CardContainer params={cardContainerStyles} specDesign={specDesign}>
        {/* Titles */}
        {title || subtitle ? (
          <AnimationAddClassWhenVisible
            classToAdd="animate-fadeIn"
            classDefault="duration-1000 delay-[100ms]"
          >
            <Card
              params={introCardStyles}
              specDesign={specDesign}
              classes={{ cardContainer: "space-y-4 mb-8" }}
            >
              {title ? (
                <Card params={introTitleCardStyles} specDesign={specDesign}>
                  <Text
                    textParams={introTitleStyles}
                    classDefault="text-4xl text-center md:text-4xl font-brand-main font-bold"
                    specDesign={specDesign}
                  >
                    {lineBreaks(title)}
                  </Text>
                </Card>
              ) : null}
              {subtitle ? (
                <Card params={introSubtitleCardStyles} specDesign={specDesign}>
                  <Text
                    textParams={introSubtitleStyles}
                    classDefault="text-lg text-center lg:text-xl font-brand-main text-gray-500 mb-2"
                    specDesign={specDesign}
                  >
                    {lineBreaks(subtitle)}
                  </Text>
                </Card>
              ) : null}
            </Card>
          </AnimationAddClassWhenVisible>
        ) : null}

        <div className="flex flex-wrap md:grid md:grid-cols-3 gap-3 sm:gap-4 lg:gap-6 justify-center items-stretch">
          {items?.map(
            (
              { title, content, ionIcon, faIcon, img, btnList, btnListStyles },
              index
            ) => (
              <Card
                key={index}
                params={itemCardStyles}
                specDesign={specDesign}
                classes={{
                  cardBgContainer:
                    "w-full flex flex-col items-center justify-center",
                }}
              >
                <AnimationAddClassWhenVisible
                  classToAdd="transition-opacity"
                  classDefault="duration-1000 delay-[300ms]"
                >
                  <div className="font-brand-secondary flex flex-col items-center justify-center w-full h-full">
                    {/* Imagen/Icono */}
                    {ionIcon || faIcon ? (
                      <Card
                        params={imgCardStyles}
                        specDesign={specDesign}
                        classes={{ cardContainer: "flex justify-center" }}
                      >
                        {ionIcon ? (
                          <IonIcon
                            icon={ionIcon}
                            className="text-6xl mx-auto"
                            color="primary"
                          />
                        ) : null}
                        {faIcon
                          ? faIcon({
                              className:
                                "inline-block text-6xl mb-1.5 text-brand-primary mx-auto",
                            })
                          : null}
                      </Card>
                    ) : null}
                    {img ? (
                      <AnimationAddClassWhenVisible
                        classToAdd="transition-opacity"
                        classDefault="duration-1000 delay-[300ms]"
                      >
                        <div className="flex justify-center">
                          <Image
                            imageParams={img}
                            cardParams={imgCardStyles}
                            specDesign={specDesign}
                            instance={instance}
                          />
                        </div>
                      </AnimationAddClassWhenVisible>
                    ) : null}

                    {/* Título */}
                    {title ? (
                      <Card
                        params={titleCardStyles}
                        specDesign={specDesign}
                        classes={{
                          cardContainer: "flex justify-center w-full",
                        }}
                      >
                        <Text
                          textParams={titleStyles}
                          classDefault="font-bold font-brand-main text-black text-xl 2xl:text-2xl mb-2 text-center max-w-prose"
                          specDesign={specDesign}
                        >
                          {lineBreaks(title)}
                        </Text>
                      </Card>
                    ) : null}

                    {/* Contenido */}
                    {content ? (
                      <Card
                        params={contentCardStyles}
                        specDesign={specDesign}
                        classes={{
                          cardContainer: "flex justify-center w-full",
                        }}
                      >
                        <Text
                          textParams={contentStyles}
                          classDefault="text-black text-center max-w-prose 2xl:text-lg"
                          specDesign={specDesign}
                        >
                          {lineBreaks(content)}
                        </Text>
                      </Card>
                    ) : null}

                    {/* Botones */}
                    {btnList?.length ? (
                      <Card
                        params={btnListStyles}
                        specDesign={specDesign}
                        classes={{
                          cardContainer: "flex justify-center w-full mt-auto",
                        }}
                      >
                        <BtnsList
                          items={btnList}
                          specDesign={specDesign}
                          history={history}
                          instance={instance}
                        />
                      </Card>
                    ) : null}
                  </div>
                </AnimationAddClassWhenVisible>
              </Card>
            )
          )}
        </div>
      </CardContainer>
    </Background>
  );
}

const presets = {
  base: {
    bgStyles: [
      {
        bgColor: "#ffffff",
        screen: "xxs",
        bgVideoUrl: "",
        bgOverlayAlpha: 0,
        type: "color",
      },
    ],
    cardContainerStyles: [
      {
        screen: "xxs",
        marginX: 4,
        marginY: 24,
      },
      {
        screen: "md",
        marginX: 16,
        marginY: 32,
      },
      {
        padding: 40,
        screen: "lg",
        marginY: 40,
      },
      {
        screen: "xl",
        marginX: 56,
        marginY: 40,
      },
      {
        screen: "2xl",
        marginX: 64,
        marginY: 56,
      },
    ],
    introCardStyles: [
      {
        screen: "xxs",
        marginY: 4,
        align: "center",
        width: "full",
      },
      {
        screen: "md",
        marginY: 8,
      },
    ],
    introTitleStyles: [
      {
        textSize: "3xl",
        fontVariations: ["bold"],
        textAlign: "center",
        screen: "xxs",
        textColor: "#000000",
        font: "main",
        width: "full",
      },
      {
        textSize: "4xl",
        screen: "lg",
      },
      {
        textSize: "5xl",
        screen: "2xl",
      },
    ],
    introSubtitleStyles: [
      {
        textSize: "xl",
        textAlign: "center",
        screen: "xxs",
        textColor: "#000000",
        font: "secondary",
      },
      {
        textSize: "2xl",
        screen: "md",
      },
      {
        textSize: "3xl",
        screen: "XL",
      },
    ],
    imgCardStyles: [
      {
        marginY: 4,
        align: "center",
        width: "fit",
        screen: "xxs",
      },
    ],
    titleStyles: [
      {
        textSize: "lg",
        fontVariations: ["bold"],
        textAlign: "center",
        screen: "xxs",
        textColor: "#000000",
        font: "secondary",
      },
      {
        textSize: "xl",
        screen: "lg",
      },
      {
        textSize: "2xl",
        screen: "2xl",
      },
    ],
    contentStyles: [
      {
        textSize: "base",
        textAlign: "center",
        screen: "xxs",
        textColor: "#000000",
        font: "secondary",
      },
      {
        textSize: "md",
        screen: "md",
      },
      {
        textSize: "lg",
        screen: "lg",
      },
    ],
  },
  "base-details": {
    bgStyles: [
      {
        bgVideoUrl: "",
        bgOverlayAlpha: 0,
        type: "color",
        screen: "xxs",
        bgColor: "#ffffff",
      },
    ],
    cardContainerStyles: [
      {
        marginY: 24,
        marginX: 4,
        screen: "xxs",
      },
      {
        marginY: 32,
        screen: "md",
        marginX: 16,
      },
      {
        padding: 40,
        screen: "lg",
        marginY: 40,
      },
      {
        marginX: 72,
        marginY: 40,
        screen: "xl",
      },
      {
        marginY: 56,
        screen: "2xl",
        marginX: 72,
      },
    ],
    introTitleStyles: [
      {
        fontVariations: ["bold"],
        textAlign: "center",
        screen: "xxs",
        textColor: "primary",
        font: "main",
        textSize: "3xl",
      },
      {
        screen: "md",
        textSize: "3xl",
      },
      {
        textSize: "4xl",
        screen: "lg",
      },
      {
        screen: "2xl",
        textSize: "5xl",
      },
    ],
    introSubtitleStyles: [
      {
        font: "secondary",
        textSize: "xl",
        textColor: "#000000",
        textAlign: "center",
        screen: "xxs",
      },
      {
        screen: "lg",
        textSize: "2xl",
      },
    ],
    introTitleCardStyles: [
      {
        align: "center",
        width: "full",
        screen: "xxs",
      },
    ],
    imgCardStyles: [
      {
        marginY: 4,
        align: "center",
        width: "fit",
        screen: "xxs",
      },
    ],
    titleStyles: [
      {
        font: "secondary",
        textColor: "#000000",
        textAlign: "center",
        screen: "xxs",
        fontVariations: ["bold"],
        textSize: "lg",
      },
      {
        textSize: "xl",
        screen: "md",
      },
      {
        textSize: "2xl",
        screen: "2xl",
      },
    ],
    titleCardStyles: [
      {
        screen: "xxs",
        align: "center",
        marginY: 2,
        width: "full",
      },
      {
        align: "center",
        round: null,
        screen: "lg",
      },
    ],
    itemCardStyles: [
      {
        align: "center",
        screen: "xxs",
        marginY: 2,
      },
    ],
    introCardStyles: [
      {
        align: "center",
        marginY: 4,
        width: "full",
        screen: "xxs",
      },
      {
        marginY: 8,
        screen: "md",
      },
    ],
    contentStyles: [
      {
        textSize: "base",
        textAlign: "center",
        screen: "xxs",
        textColor: "#000000",
        font: "secondary",
      },
      {
        textSize: "md",
        screen: "md",
      },
      {
        textSize: "lg",
        screen: "lg",
      },
    ],
  },
};
