import _ from 'lodash';
import { LayoutAdmin } from "../panel/LayoutAdmin";
import { useEffect, useState } from 'react';
import { InstanceInlineSelectorForMain } from '../instance/InstanceSelector';
import { usePanel } from '../panel/usePanel';
import IonBtnLoading from '../../components/ui/IonBtnLoading';
import { migrationsList, migrationsMainList, useMigrations } from './useMigrations';
import { IonButton, IonItem, IonList, IonLabel, IonTitle, IonCard, IonCardHeader, IonCardContent, IonToolbar, IonButtons } from '@ionic/react';
import { AlertLogger } from '../panel/AlertLogger';
import { useSettings } from '../panel/useSettings';
import { getJson } from '../../libs/utils';
import Model from '../../libs/ModelClass';
import { withPrefix } from '../instance/utilsInstance';
import { getConfig } from '../../config';
import toast from 'react-hot-toast';


export function Content(props) {
  let {
    selectedInstance,
    attachPrefix
  } = props;
  const settings = useSettings();
  settings.loadScope('main');
  const {
    isMigrating,
    setIsMigrating,
    migrateMain,
    migrateAllInstances,
    logger,
    clearMigrationSettings,
    clearMigrationMainSettings
  } = useMigrations();
  const lastMigrationInstance = settings.get('main', 'lastMigrationInstance', null) || { key: migrationsList[migrationsList.length - 1] };
  const lastMigrationMain = settings.get('main', 'lastMigrationMain', null) || { key: migrationsMainList[migrationsMainList.length - 1] };

  const doMigrateMain = async () => {
    let fromMigration = lastMigrationMain.key || migrationsMainList[migrationsMainList.length - 1];;
    let toMigration = migrationsMainList[0];

    // Find the index of the last migration in the migrationsList keys
    const lastMigrationIndex = migrationsMainList.indexOf(lastMigrationMain.key);

    // If lastMigration is found, start from the next migration
    if (lastMigrationIndex !== -1 && lastMigrationIndex > 0) {
      fromMigration = migrationsMainList[lastMigrationIndex - 1];
    }

    // Execute the migrations
    await migrateMain(fromMigration, toMigration);

    // Save the last migration
    await settings.set('main', 'lastMigrationMain', { key: toMigration, date: new Date().toISOString() });
  };


  const doMigrate = async () => {
    let fromMigration = lastMigrationInstance.key || migrationsList[migrationsList.length - 1];
    let toMigration = migrationsList[0];

    // Find the index of the last migration in the migrationsList keys
    const lastMigrationIndex = migrationsList.indexOf(lastMigrationInstance.key);

    // If lastMigration is found, start from the next migration
    if (lastMigrationIndex !== -1 && lastMigrationIndex > 0) {
      fromMigration = migrationsList[lastMigrationIndex - 1];
    } 

    // Execute the migrations
    await migrateAllInstances(fromMigration, toMigration);

    // Save the last migration
    await settings.set('main', 'lastMigrationInstance', { key: toMigration, date: new Date().toISOString() });
  };

  const syncData = async () => {
    try {
      const contractsDocsStatic = await getJson('/data/sales/contractsModels.json');
      if (contractsDocsStatic?.length) {
        const ContractModelModel = Model.extend(withPrefix('main', getConfig().modules.sales.contractsModelsEntitySlug));
        for (const docStatic of contractsDocsStatic) {
          // find by nameSlug and update
          const existingDoc = await ContractModelModel.filterOne({ nameSlug: docStatic.nameSlug });
          if (existingDoc) {
            existingDoc.data = { ...docStatic };
            existingDoc.data.id = existingDoc.id;
            await existingDoc.save();
          }
          else {
            await ContractModelModel.createOrUpdate(docStatic);
          }
        }
      }
      toast.success('Contratos actualizados');
    } catch (error) {
      toast.error(error.message);
      console.error(error);
    }
  };

  const syncDataCanvasPrints = async () => {
    try {
      const canvasPrintsDocsStatic = await getJson('/data/canvas/canvasPrints.json');
      if (canvasPrintsDocsStatic?.length) {
        const CanvasPrintModelModel = Model.extend(withPrefix('main', getConfig().modules.canvas.canvasPrintsEntitySlug));
        for (const docStatic of canvasPrintsDocsStatic) {
          // find by nameSlug and update
          const existingDoc = await CanvasPrintModelModel.filterOne({ nameSlug: docStatic.nameSlug });
          if (existingDoc) {
            existingDoc.data = { ...docStatic };
            existingDoc.data.id = existingDoc.id;
            await existingDoc.save();
          }
          else {
            await CanvasPrintModelModel.createOrUpdate(docStatic);
          }
        }
      }
      toast.success('Impresiones de lienzo actualizadas');
    } catch (error) {
      toast.error(error.message);
      console.error(error);
    }
  };

  return (
    <div className="p-4 space-y-10">

      <IonCard>
        <IonCardHeader>
          <IonToolbar>
            <IonTitle slot="start">Migraciones de instancias</IonTitle>

            {/* show migration btn */}
            <IonButtons slot="end">
              <IonBtnLoading
                label="Ejecutar"
                onClickAsync={doMigrate}
                fill="solid"
                color="danger"
                size="small"
              />
            </IonButtons>
          </IonToolbar>
        </IonCardHeader>

        <IonCardContent>
          {/* show migrationsList */}
          <IonList className="mt-4">
            {migrationsList.map((key, index) => (
                <IonItem key={key} className={index >= migrationsList.indexOf(lastMigrationInstance.key) ? 'text-gray-400' : ''}>
                  <IonLabel className={index === migrationsList.indexOf(lastMigrationInstance.key) - 1 ? 'font-bold' : ''}>
                    {key}
                    {/* show date if exist */}
                    {lastMigrationInstance.key === key && lastMigrationInstance.date ? ` (${lastMigrationInstance.date})` : ''}
                  </IonLabel>
                  {/* <IonToggle 
                    checked={} 
                    onIonChange={} 
                  /> */}
                </IonItem>
              ))}
          </IonList>

          <IonBtnLoading
            label="Reiniciar migraciones"
            onClickAsync={clearMigrationSettings}
          />
        </IonCardContent> 
      </IonCard>


      {/* MAIN */}

      <IonCard>
        <IonCardHeader>
          <IonToolbar>
            <IonTitle slot="start">Migraciones de MAIN</IonTitle>

            {/* show migration btn */}
            <IonButtons slot="end">
              <IonBtnLoading
                label="Ejecutar"
                onClickAsync={doMigrateMain}
                fill="solid"
                color="danger"
                size="small"
              />
            </IonButtons>
          </IonToolbar>
        </IonCardHeader>

        <IonCardContent>
          {/* show migrationsMainList */}
          <IonList className="mt-4">
            {migrationsMainList.map((key, index) => (
                <IonItem key={key} className={index >= migrationsMainList.indexOf(lastMigrationMain.key) ? 'text-gray-400' : ''}>
                  <IonLabel className={index === migrationsMainList.indexOf(lastMigrationMain.key) - 1 ? 'font-bold' : ''}>
                    {key}
                    {/* show date if exist */}
                    {lastMigrationMain.key === key && lastMigrationMain.date ? ` (${lastMigrationMain.date})` : ''}
                  </IonLabel>
                  {/* <IonToggle 
                    checked={} 
                    onIonChange={} 
                  /> */}
                </IonItem>
              ))}
          </IonList>

          <IonBtnLoading
            label="Reiniciar migraciones main"
            onClickAsync={clearMigrationMainSettings}
          />
        </IonCardContent>
      </IonCard>


      {/* Logs */}
      <AlertLogger logs={logger.logs} isOpen={logger.isModalOpen} />

      {/* sync data */}
      <IonBtnLoading
        label="Sincronizar contratos"
        onClickAsync={syncData}
      />

      {/* sync data canvas prints */}
      <IonBtnLoading
        label="Sincronizar impresiones de lienzo"
        onClickAsync={syncDataCanvasPrints}
      />
    </div>
  );
}

export function RouteEntityUpdates(props) {
  const { selectedInstance, attachPrefix } = usePanel();
  const [ selectedInner, setSelectedInner ] = useState(selectedInstance);

  useEffect(() =>{
    setSelectedInner(selectedInstance);
  }, [selectedInstance]);

  return (
    <LayoutAdmin 
      history={props.history}
      breadcrumbs={[{
        title: "Actualizaciones de datos"
      }]}
      TitleToolbarRight={() => (
        <InstanceInlineSelectorForMain {...props} selectedInstance={selectedInner} setSelectedInstance={setSelectedInner} design="labelInsideBtn" />
      )}
    >
      <Content {...props} selectedInstance={selectedInner} attachPrefix={attachPrefix} />
    </LayoutAdmin>
  );
}

