import _ from "lodash";
import { Link } from "react-router-dom";
import { FaWhatsapp, FaInstagram, FaFacebook } from "react-icons/fa";
import { AnimationAddClassWhenVisible } from "../../../components/ui/AnimationAddClassWhenVisible";
import { compileStringTemplate, lineBreaks } from "../../../libs/utils";
import { BtnList } from "../parts/PartBtnList";
import { IonIcon } from "@ionic/react";
import { mailOutline, callOutline, logoFacebook } from "ionicons/icons";
import { urlPrefix } from "../../instance/utilsInstance";
import { sectionCardBtnsFields } from "./BlockSectionCardBtns";
import { useLayoutHome } from "../../layoutHome/useLayoutHome";
import { Background } from "../parts/PartBackground";
import { getConfig } from "../../../config";
import { CardContainer } from "../parts/PartCardContainer";
import { cardContainerStylesField } from "../parts/stylesFields";

export const phoneObjectFields = {
  useBrandPhone: {
    type: "boolean",
    name: "Utilizar mismo que la marca",
    defaultValue: true,
  },
  phoneNumber: {
    type: "phone",
    name: "Número personalizado",
  },
};

export const whatsappObjectFields = {
  ...phoneObjectFields,
  phoneMessage: {
    type: "textArea",
    name: "Mensaje predeterminado",
  },
};

export const emailObjectFields = {
  emailDirection: {
    type: "text",
    name: "Email",
  },
  emailMessage: {
    type: "textArea",
    name: "Mensaje predeterminado",
  },
};

export default function () {
  return {
    type: "pageFooter",
    variant: "contactsLinksWithoutMap",
    template: PageFooterContactsWithoutMap,
    presets,
    params: {
      bgStyles: {
        name: "Fondo",
        type: "part",
        part: "background",
      },
      blockCardStyles: cardContainerStylesField,
      textsColor: {
        name: "Colores de los textos",
        type: "color",
        defaultValue: "#ffffff",
      },
      socialLinksColors: {
        name: "Colores de enlaces sociales",
        type: "object",
        fields: {
          borderColor: {
            type: "color",
            name: "Borde",
            defaultValue: "#ffffff",
          },
          bgColor: {
            type: "color",
            name: "Fondo",
          },
          textColor: {
            type: "color",
            name: "Texto",
            defaultValue: "#ffffff",
          },
        },
      },
      lastbgStyles: {
        name: "Fondo del último footer",
        type: "part",
        part: "background",
      },
      lastFooterTextColor: {
        name: "Color del texto del último footer",
        type: "color",
        defaultValue: "#ffffff",
      },
    },
    strings: {
      socialLinks: {
        type: "object",
        name: "Enlaces sociales",
        fields: {
          socialLinkTitle: {
            type: "text",
            name: "Título de los enlaces",
          },
          linkWebsiteLabel: {
            type: "text",
            name: "Enlace a la página: Etiqueta a mostrar",
          },
          linkWebsite: {
            type: "text",
            name: "Enlace a la página: URL",
          },
          linkFacebook: {
            type: "text",
            name: "Redes Sociales: Enlace a Facebook",
          },
          linkInstagram: {
            type: "text",
            name: "Redes Sociales: Enlace a Instagram",
          },
        },
      },
      phoneCall: {
        type: "object",
        name: "Teléfono para llamadas",
        fields: phoneObjectFields,
      },
      whatsapp: {
        type: "object",
        name: "Teléfono para Whatsapp",
        fields: whatsappObjectFields,
      },
      email: {
        type: "object",
        name: "Correo electrónico",
        fields: emailObjectFields,
      },
      address: {
        type: "object",
        name: "Dirección",
        fields: {
          addressString: {
            type: "textArea",
            name: "Ubicación en texto",
            descriptionText: "Dirección y referencias sobre como llegar",
          },
        },
      },
      cardContentStyles: {
        name: "Tarjeta del contenido: textos y botones",
        descriptionText:
          "Incluye al título, subtítulo y botones, estilos según tamaño de pantalla",
        type: "part",
        part: "card",
      },
      cardTextsStyles: {
        name: "Tarjeta del contenido: sólo textos",
        descriptionText:
          "Incluye al título y subtítulo, estilos según tamaño de pantalla",
        type: "part",
        part: "card",
      },
      logoStyles: {
        name: "Logo",
        descriptionText: "Estilos según tamaño de pantalla",
        type: "part",
        part: "logo",
      },
      logoCardStyles: {
        name: "Tarjeta del Logo",
        descriptionText: "Estilos según tamaño de pantalla",
        type: "part",
        part: "card",
      },
      titleStyles: {
        name: "Título",
        descriptionText: "Estilos según tamaño de pantalla",
        type: "part",
        part: "text",
      },
      titleCardStyles: {
        name: "Tarjeta del Título",
        descriptionText: "Estilos según tamaño de pantalla",
        type: "part",
        part: "card",
      },
      subtitleStyles: {
        name: "Subtítulo",
        descriptionText: "Estilos según tamaño de pantalla",
        type: "part",
        part: "text",
      },
      subtitleCardStyles: {
        name: "Tarjeta del Subtítulo",
        descriptionText: "Estilos según tamaño de pantalla",
        type: "part",
        part: "card",
      },
      btnListCardStyles: {
        name: "Tarjeta de los botones",
        descriptionText: "Estilos según tamaño de pantalla",
        type: "part",
        part: "card",
      },
      showMadeWithLove: {
        type: "boolean",
        name: "Mostrar hecho con amor desde",
        defaultValue: true,
      },
      madeWithLoveLabel: {
        type: "text",
        name: "Hecho con amor: etiqueta para mostrar a la derecha",
      },
      showPoliticsLinks: {
        type: "boolean",
        name: "Mostrar enlaces de póliticas",
        defaultValue: true,
        descriptionText: "Políticas de privacidad y Seguridad de datos",
      },
      showPanelLink: {
        type: "boolean",
        name: "Mostrar acceso para usuarios",
        defaultValue: true,
        descriptionText: "Enlace al panel de administración",
      },
      btnList: {
        type: "collection",
        name: "Botones",
        fields: sectionCardBtnsFields,
      },
    },
    partsMap: [
      {
        label: "Bloque",
        background: ["bgStyles"],
        card: ["blockCardStyles"],
      },
      {
        label: "Primer Bloque",
        background: ["bgStyles"],
      },
      {
        label: "Enlaces Sociales",
        strings: ["socialLinks"],
      },
      {
        label: "Teléfono",
        strings: ["phoneCall"],
      },
      {
        label: "Whatsapp",
        strings: ["whatsapp"],
      },
      {
        label: "Email",
        strings: ["email"],
      },
      {
        label: "Dirección",
        strings: ["address"],
      },
      {
        label: "Último Bloque",
        background: ["lastbgStyles"],
      },
      {
        label: "Hecho con Amor",
        strings: ["showMadeWithLove", "madeWithLoveLabel"],
      },
      {
        label: "Enlaces de Políticas",
        strings: ["showPoliticsLinks"],
      },
      {
        label: "Acceso de Usuarios",
        strings: ["showPanelLink"],
      },
      {
        label: "Botones Fijos",
        strings: ["btnList"],
      },
    ],
  };
}

export function PageFooterContactsWithoutMap(props) {
  let {
    blockCardStyles,
    bgStyles,
    lastbgStyles,
    showPanelLink,
    showPoliticsLinks,
    btnList,
    textsColor,
    lastFooterTextColor,
    socialLinks = {},
    phoneCall,
    whatsapp,
    email,
    address,
    socialLinksColors,
    showMadeWithLove,
    madeWithLoveLabel,
    instance,
    specDesign,
    history,
  } = props;
  let {
    socialLinkTitle,
    linkWebsite,
    linkWebsiteLabel,
    linkFacebook,
    linkInstagram,
  } = socialLinks;

  const { offsetBottom } = useLayoutHome();

  let panelUrl = instance
    ? instance !== "main"
      ? `/${instance}/login`
      : "/login"
    : "/login";

  let classes = {
    ctaBtn: `
      w-12 h-12 pt-2.5
      text-brand-primary-contrast text-2xl text-center cursor-pointer rounded-full
      border-2
      hover:scale-110 transition-all duration-300
    `,
  };

  linkFacebook = compileStringTemplate(linkFacebook, specDesign.stringsVars);
  linkFacebook = _.startsWith(linkFacebook, "http")
    ? linkFacebook
    : linkFacebook
    ? "https://www.facebook.com/" + linkFacebook
    : null;
  linkInstagram = compileStringTemplate(linkInstagram, specDesign.stringsVars);
  linkInstagram = _.startsWith(linkInstagram, "http")
    ? linkInstagram
    : linkInstagram
    ? "https://www.instagram.com/" + linkInstagram
    : null;

  let emailToUse = compileStringTemplate(
    email?.emailDirection,
    specDesign.stringsVars
  );
  let phoneCallNumber = phoneCall?.useBrandPhone
    ? specDesign.stringsVars?.brand?.callNumber
    : phoneCall?.phoneNumber;
  let whatsappNumber = whatsapp?.useBrandPhone
    ? specDesign.stringsVars?.brand?.whatsappNumber
    : whatsapp?.phoneNumber;

  let colorsMap = props.colors || specDesign.colors || getConfig().colors || {};
  const getColorHex = (val) => {
    return val?.hex || colorsMap[val] || val;
  };
  let colorText = getColorHex(textsColor) || getColorHex("dark-contrast");
  let colorTextLastFooter =
    getColorHex(lastFooterTextColor) || getColorHex("dark-contrast");

  let socialLinksBorderColor =
    getColorHex(socialLinksColors?.borderColor) || colorText;
  let socialLinksBgColor = getColorHex(socialLinksColors?.bgColor);
  let socialLinksTextColor =
    getColorHex(socialLinksColors?.textColor) || colorText;

  return (
    <>
      <Background
        params={bgStyles}
        specDesign={specDesign}
        instance={instance}
        classes={{ bgContainer: "snap-start" }}
        classDefault="bg-brand-dark"
      >
        <CardContainer
          params={blockCardStyles}
          specDesign={specDesign}
          className="py-10 md:py-12 px-4 sm:px-2 md:px-8 lg:px-0"
        >
          {/* Título principal */}
          <div className="container-width text-center mb-4 md:mb-8">
            {/* {brandIcon ? (
              // Mostrar un ícono como marca
              <img 
                src={brandIcon} 
                alt="Marca" 
                className="mx-auto w-20 h-20 md:w-28 md:h-28 object-contain"
              />
            ) : (
              // Mostrar texto como marca
              <h1 className="text-3xl md:text-4xl font-bold" style={{ color: colorText }}>
                {brandName || "Nombre de la Marca"}
              </h1>
            )} */}
            <h1 className="text-3xl text-white text-center font-bold md:text-left">
              Titulo Principal
            </h1>
          </div>

          {/* Contenedor principal */}
          <div className="container-width flex flex-col md:flex-row gap-6">
            {/* Primera columna: Datos de contacto */}
            <div className="flex-1 text-white">
              <h3
                className="text-base font-bold mb-2 lg:mb-4"
                style={{ color: colorText }}
              >
                CONTACTO
              </h3>
              <div className="text-sm">
                {phoneCallNumber && (
                  <p>
                    Tel.:{" "}
                    <a
                      href={`tel:+${phoneCallNumber}`}
                      className="underline"
                      style={{ color: colorText }}
                    >
                      +{phoneCallNumber}
                    </a>
                  </p>
                )}
              </div>
              <div className="text-sm">
                {emailToUse && (
                  <p>
                    Correo:{" "}
                    <a
                      href={`mailto:${emailToUse}`}
                      className="hover:underline"
                      style={{ color: colorText }}
                    >
                      {emailToUse}
                    </a>
                  </p>
                )}
              </div>
              <div className="text-sm lg:mr-20">
                {address?.addressString && (
                  <p>
                    Dirección:{" "}
                    {lineBreaks(
                      compileStringTemplate(
                        address.addressString,
                        specDesign.stringsVars
                      )
                    )}
                  </p>
                )}
              </div>
            </div>
            {/* Tercera columna: Información Institucional adicional */}
            <div className="flex-1">
              <h3
                className="text-base font-bold mb-2 lg:mb-4"
                style={{ color: colorText }}
              >
                INSTITUCIONAL
              </h3>
              <div className="mb-2 text-sm ">
                {showPoliticsLinks && (
                  <>
                    <p>
                      <Link
                        to={urlPrefix(instance, "/m/p/privacy-policy")}
                        className="hover:underline"
                        style={{ color: colorText }}
                      >
                        Política de Privacidad
                      </Link>
                    </p>
                    <p>
                      <Link
                        to={urlPrefix(instance, "/m/p/data-security")}
                        className="hover:underline"
                        style={{ color: colorText }}
                      >
                        Seguridad de Datos
                      </Link>
                    </p>
                  </>
                )}
                {showPanelLink && (
                  <p>
                    <Link
                      to={panelUrl}
                      className="hover:underline"
                      style={{ color: colorText }}
                    >
                      Administración
                    </Link>
                  </p>
                )}
              </div>
            </div>
            {/* Segunda columna: Redes sociales */}
            <div className="flex-1">
              <h3
                className="text-base font-bold mb-2 lg:mb-4"
                style={{ color: colorText }}
              >
                REDES SOCIALES
              </h3>
              <div className="flex gap-4">
                {linkFacebook && (
                  <a
                    href={linkFacebook}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: socialLinksTextColor, fontSize: "1.8rem" }}
                    className="hover:scale-110"
                  >
                    <FaFacebook />
                  </a>
                )}
                {linkInstagram && (
                  <a
                    href={linkInstagram}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: socialLinksTextColor, fontSize: "1.8rem" }}
                    className="hover:scale-110"
                  >
                    <FaInstagram />
                  </a>
                )}
                {whatsappNumber && (
                  <a
                    href={`https://wa.me/${whatsappNumber}?text=${whatsapp.phoneMessage}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: socialLinksTextColor, fontSize: "1.8rem" }}
                    className="hover:scale-110"
                  >
                    <FaWhatsapp />
                  </a>
                )}
              </div>
            </div>
          </div>
        </CardContainer>
      </Background>

      {/* Nuevo contenedor para "hecho con amor" y "potenciado por" */}
      <Background
        params={lastbgStyles}
        specDesign={specDesign}
        instance={instance}
        classDefault="bg-brand-darker"
      >
        <div className="container-width py-4 px-4 flex flex-col md:flex-row justify-center items-center gap-2 md:gap-0 text-gray-500 text-[9px]">
          {showMadeWithLove && (
            <>
              <div
                className="flex items-center"
                style={{ color: colorTextLastFooter }}
              >
                <span>Hecho con</span>
                <img
                  src="/icons/heart.svg"
                  alt="amor"
                  className="beating mx-1"
                />
                <span>{madeWithLoveLabel}</span>
              </div>
              <span
                className="hidden md:block mx-2"
                style={{ color: colorTextLastFooter }}
              >
                |
              </span>
            </>
          )}
          <div style={{ color: colorTextLastFooter }}>
            <span className="mr-1.5">Potenciado por</span>
            <a href="/" className="underline hover:text-opacity-80">
              {_.capitalize(getConfig().domain)}
            </a>
          </div>
        </div>
      </Background>
    </>
  );
}

const presets = {
  base: {
    // Define your 'base' preset here
    bgStyles: [
      {
        bgColor: "#222222", // Example: Dark gray background
        screen: "xxs",
        bgVideoUrl: "",
        bgOverlayAlpha: 0,
        type: "color",
      },
    ],
    textsColor: "#ffffff", // Example: White text color
    socialLinksColors: {
      borderColor: "#ffffff", // Example: White border for social links
      bgColor: "transparent", // Example: Transparent background for social links
      textColor: "#ffffff", // Example: White text color for social links
    },
    lastbgStyles: [
      {
        bgColor: "#111111", // Example: Darker gray for the last footer section
        screen: "xxs",
        bgVideoUrl: "",
        bgOverlayAlpha: 0,
        type: "color",
      },
    ],
    lastFooterTextColor: "#D2D2D2", // Example: Light gray text color for the last footer
    // ... add default values for other parameters in your 'base' preset
  },

  "base-details": {
    bgStyles: [
      {
        bgColor: "primary-shade",
        bgOverlayAlpha: 0,
        screen: "xxs",
        type: "color",
        bgVideoUrl: "",
      },
    ],
    socialLinksColors: {
      borderColor: "secondary",
      bgColor: "null",
      textColor: "secondary",
    },
    lastbgStyles: [
      {
        screen: "xxs",
        bgVideoUrl: "",
        bgOverlayAlpha: 0,
        bgColor: "secondary-tint",
        type: "color",
      },
    ],
    textsColor: null,
    lastFooterTextColor: "primary-shade",
  },
};
