import T2W  from 'numbers2words';

/**
 * Convierte un número a palabras en un idioma específico.
 * @param {number} number - El número a convertir.
 * @param {string} locale - El código de idioma (por ejemplo, 'en' para inglés, 'es' para español).
 * @returns {string} - El número convertido a palabras en el idioma especificado.
 */
const numberToWordsLocalized = (number = 0, locale = 'EN_US') => {
      console.log('numberToWordsLocalized', number, locale);
  
  try {
    let translator = new T2W(locale);
    return translator.toWords(number);
  } catch (error) {
    console.error(`Error converting number to words: ${error.message}`);
    return '';
  }
};

export default numberToWordsLocalized;