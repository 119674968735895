import { IonItem, IonLabel, IonList, IonListHeader, IonSkeletonText, IonThumbnail } from "@ionic/react";

export const SkeletonLoading = () => {
  return (
    <IonList lines="none">
      <IonListHeader>
        <IonSkeletonText animated={true} style={{ width: '80px' }}></IonSkeletonText>
      </IonListHeader>
      <IonItem>
        {/* <IonThumbnail slot="start">
          <IonIcon style={{ width: '100%', height: '100%' }} icon={musicalNotes}></IonIcon>
        </IonThumbnail> */}
        <IonLabel>
          <h3>
            <IonSkeletonText animated={true} style={{ width: '140px' }}></IonSkeletonText>
          </h3>
          <p>
            <IonSkeletonText animated={true} style={{ width: '120px' }}></IonSkeletonText>
          </p>
          <p>
            <IonSkeletonText animated={true} style={{ width: '100px' }}></IonSkeletonText>
          </p>
        </IonLabel>
      </IonItem>
    </IonList>
  );
}
