import _ from 'lodash';
import { RawInputObjectForm, RawInputSelectDocumentExtended } from '../../components/Form';
import { inputClasses } from '../../components/EntityTaxonomyForm';
import { useEffect, useMemo, useRef, useState } from 'react';
import { withPrefix } from '../instance/utilsInstance';
import { ObjectFormWrapped } from '../../components/Form/RawInputObjectForm';
import { objectFormToTaxonomyTypes } from '../entity/ObjectForm';
import { IonButton, IonIcon } from '@ionic/react';
import { menuOutline } from 'ionicons/icons';
import { DocCard, viewCardInlineClasses } from '../entity/docCardsVariants';
import EntityGetOneDocument from '../../components/EntityGetOneDocument';
import { getConfig } from '../../config';


const invoiceCustomerBussinessFields = {
  bussinessName: {
    type: 'text',
    name: 'Nombre o razón social',
    required: true
  },
  bussinessRuc: {
    type: 'text',
    name: 'RUC',
    required: true
  },
  bussinessAddress: {
    type: 'textArea',
    name: 'Dirección'
  },
  bussinessPhone: {
    type: 'text',
    name: 'Teléfono/s'
  }
};

const requiredFields = [ 'bussinessName', 'bussinessRuc' ];

const RenderForm = (props) => {
  const { instance, value, onChange } = props;
  const [ contactId, setContactId ] = useState();
  const [ contactDoc, setContactDoc ] = useState();
  const [ casualData, setCasualData ] = useState({});
  const [ hasErrors, setHasErrors ] = useState(false);
  const [ dataMode, setDataMode ] = useState();
  const invoiceCustomerTaxonomyTypesDataList = useMemo(() => objectFormToTaxonomyTypes(invoiceCustomerBussinessFields), []);
  const casualObjectFormApiRef = useRef();
  const inputSelectDocumentApiRef = useRef();
  const formApiRef = useRef();

  useEffect(() => {
    if (_.size(value)) {
      // Asigna valores iniciales solo si `value` está definido y tiene contenido
      setContactId(value?.type === 'saved' ? value.contactId : null);
      setCasualData(value?.type === 'casual' ? value : {});
      if (value?.type === 'casual') {
        inputSelectDocumentApiRef.current?.reset();
      }
      setDataMode(value?.type);
    } else {
      // Reinicia los valores si `value` está vacío
      setContactId(null);
      setContactDoc(null);
      setCasualData({});
      setHasErrors(false);
      setDataMode();
      formApiRef.current?.form.reset();
    }
  }, [value]);

  const assignContact = (contactId) => {
    setContactId(contactId);
    onChange({
      type: 'saved',
      contactId: contactId
    });
    setDataMode('saved');
  };
  
  const assignCasualData = () => {
    casualObjectFormApiRef.current?.closeModal();
    onChange({
      type: 'casual',
      ...casualData
    });
    setDataMode('casual');
    setContactId(null);
  };

  const openModal = (mode) => {
    if (mode === 'saved') {
      inputSelectDocumentApiRef.current?.openModal();
    }
    else if (mode === 'casual') {
      casualObjectFormApiRef.current?.openModal();
    }
  };
  
  return (
    <div className="">
      {/* Modal con lista de contactos */}
      <RawInputSelectDocumentExtended
        instance={instance}
        entitySlug={withPrefix(instance, 'contacts')}
        title="Contacto"
        btnLabel="Contacto"
        value={contactId}
        onChange={assignContact}
        onAfterChange={(value) => {
          setContactDoc(value); 
        }}
        className={inputClasses.fieldInput || ''}
        showTriggerBtn={false}
        apiGetter={(api) => inputSelectDocumentApiRef.current = api}
      />

      {/* Modal con formulario de datos casuales */}
      <ObjectFormWrapped
        defaultValue={casualData}
        onFormChange={setCasualData}
        fieldsRequired={requiredFields}
        setHasErrors={setHasErrors}
        apiGetter={(api) => formApiRef.current = api}
      >
        <RawInputObjectForm
          value={casualData}
          showDataCard={value?.type === 'casual'}
          handleSubmit={assignCasualData}
          baseFieldName="customer"
          btnLabel="Casual"
          instance={instance}
          entitySlug={withPrefix(instance, 'contacts')}
          title="Casual"
          taxonomyTypesDataList={invoiceCustomerTaxonomyTypesDataList}
          fieldsRequired={requiredFields}
          showSaveButton={true}
          saveBtnLabel="Asignar"
          saveBtnColor="primary"
          disableBtnSave={hasErrors}
          apiGetter={(api) => casualObjectFormApiRef.current = api}
          showTriggerBtn={false}
        />
      </ObjectFormWrapped>

      {/* triggers btns */}
      <div className="mt-2 flex flex-row flex-wrap gap-2">
        <IonButton onClick={() => openModal('saved')} size="small" fill={(dataMode === 'saved' || !dataMode) ? 'outline' : 'clear'} color={!dataMode ? 'primary' : 'medium' }>
          <IonIcon icon={menuOutline} slot="start" size="small"></IonIcon>
          <span className="text-xs">Contacto guardado</span>
        </IonButton>
        <IonButton onClick={() => openModal('casual')} size="small" fill={(dataMode === 'casual' || !dataMode) ? 'outline' : 'clear'} color={!dataMode ? 'primary' : 'medium'}>
          <IonIcon icon={menuOutline} slot="start" size="small"></IonIcon>
          <span className="text-xs">Datos casuales</span>
        </IonButton>
      </div>
    </div>
  );
};

const RenderShow = (props) => {
  const { displayedValue, instance } = props;

  return (
    <div className="">
      {(displayedValue?.type === 'saved' && displayedValue?.contactId) ? (
        <EntityGetOneDocument
        entitySlug={withPrefix(instance, getConfig().modules.contacts.contactsEntitySlug)}
        docId={displayedValue.contactId}>
          {(entitySpecs) => (
            <DocCard
              {...props}
              doc={entitySpecs.doc}
              entitySpecs={entitySpecs}
              outstandingOnly={true}
              design="flat"
              classes={viewCardInlineClasses}
            />
          )}
        </EntityGetOneDocument>
      ) : null}
      {displayedValue?.type === 'casual' ? (
        <div className="">
          Datos casuales
        </div>
      ) : null}
    </div>
  );
};

const dataTypeInvoiceCustomer = {
  RenderForm,
  RenderShow,
  RenderShowList: RenderShow
};

export default dataTypeInvoiceCustomer;
