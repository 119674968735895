import React from 'react';
import { IonAccordion, IonAccordionGroup, IonLabel, IonItem, IonTitle } from '@ionic/react';
import { useCreditCollectionsStore } from '../creditCollections/useCreditCollectionsStore';
import PaymentsByContractSelector from '../creditCollections/PaymentsByContractSelector';
import ObjectShow from '../../entity/ObjectShow';
import { withPrefix } from '../../instance/utilsInstance';
import { getConfig } from '../../../config';
import _ from 'lodash';
import { viewCardInlineClasses, viewColClasses, viewRowClasses } from '../../entity/docCardsVariants';
import { usePanel } from '../../panel/usePanel';
import { stackClasses } from '../../../libs/utils';
import { inputClasses } from '../../../components/EntityTaxonomyForm';

const contractsFields = {
  name: { type: 'text', name: 'Nombre' },
  customerId: { type: 'text', name: 'Cliente' },
  modelId: { type: 'text', name: 'Modelo' },
  issuedDate: { type: 'date', name: 'Fecha de Emisión' },
  status: { type: 'text', name: 'Estado' },
};

const ContractsListAccordion = ({
  title = "Contrataciones",
  contractsDocs = [],
}) => {
  const { instance } = usePanel(); // Hook para obtener la instancia
  const contractsEntitySlug = withPrefix(instance, getConfig().modules.sales.contractsEntitySlug);

  return (
    <div>
      {title && (
        <span className={inputClasses.fieldLabel}>
          {title}
        </span>
      )}
      <IonAccordionGroup expand="inset" className="m-0">
        {contractsDocs.map((contractDoc) => (
          <IonAccordion key={contractDoc.id}>
            <IonItem slot="header">
              <div className="pt-2 flex flex-col place-content-stretch gap-1">
                <ObjectShow
                  instance={instance}
                  entitySlug={contractsEntitySlug}
                  value={contractDoc}
                  fields={_.pick(contractsFields, ['name'])} // Campos a mostrar
                  design="flat"
                  classes={{
                    viewColClasses
                  }}
                />
                <ObjectShow
                  instance={instance}
                  entitySlug={contractsEntitySlug}
                  value={contractDoc}
                  fields={_.pick(contractsFields, ['issuedDate', 'status'])} // Campos a mostrar
                  design="flat"
                  classes={{
                    cardContainer: 'flex flex-row gap-2 items-stretch w-full',
                    ...viewColClasses
                  }}
                />
              </div>
            </IonItem>
            <div slot="content">
              <PaymentsByContractSelector
                contractsDocs={[contractDoc]} // Enviar contrato actual
              />
            </div>
          </IonAccordion>
        ))}
      </IonAccordionGroup>
    </div>
  );
};

export default ContractsListAccordion;
