import _ from 'lodash';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import Model from "../../../libs/ModelClass";
import { withPrefix } from "../../instance/utilsInstance";
import dayjs from 'dayjs';
import toast from 'react-hot-toast';

const contractsEntitySlug = "contracts";
const contactsEntitySlug = "contacts";
const usersProfilesEntitySlug = "usersProfiles";
const creditCollectionsEntitySlug = "creditCollections";

export const useCreditCollectionsStore = create(devtools((set, get) => ({
  allDocsByEntity: {
    contracts: [],
    contacts: [],
    usersProfiles: [],
  },
  contactClientDoc: null,
  contractsIds: [],
  contractsOfContact: [],
  paymentsByContract: {},
  selectedPaymentsIds: [],

  resetState: () => {
    set({
      contactClientDoc: null,
      contractsIds: [],
      contractsOfContact: [],
      paymentsByContract: {},
      selectedPaymentsIds: []
    });
  },

  // TODO por implementar
  // debe crear el doc de invoice, también el de creditCollection, ambos deben ser actualizados con referencias mutuas y retornar { invoiceDoc, creditCollectionDoc}
  saveInvoiceForCreditCollection: async (invoiceData, instance) => {
    if (!invoiceData) throw new Error('invoiceData is required');
    if (!get().selectedPaymentsIds?.length) throw new Error('selectedPaymentsIds is required');

    const InvoiceModel = Model.extend(withPrefix(instance, 'invoices'));
    const CreditCollectionModel = Model.extend(withPrefix(instance, creditCollectionsEntitySlug));
    const PaymentsModel = Model.extend(withPrefix(instance, 'payments'));

    // 1. Create Credit Collection
    const creditCollectionDoc = await CreditCollectionModel.create({
      contactId: get().contactClientDoc.id, // Assuming contactClientDoc is available in the store
      date: new Date(), // Current date as collection date
      paymentsIds: get().selectedPaymentsIds,
      status: 'pending', // Initial status
      // ...other relevant fields for credit collection
    });

    if (!creditCollectionDoc?.id) {
      throw new Error('Error creating credit collection');
    }

    // 2. Create Invoice
    console.log('invoiceData', invoiceData)
    const invoiceDoc = await InvoiceModel.create({
      ...invoiceData,
      creditCollectionId: creditCollectionDoc.id, // Reference to Credit Collection
      contactId: get().contactClientDoc.id, //  Assuming contactClientDoc is available
      // ...other invoice data
    });

    if (!invoiceDoc?.id) {
      // Rollback Credit Collection creation if Invoice fails? Or handle error and proceed?
      // For now, throwing error
      throw new Error('Error creating invoice');
    }

    // 3. Update Credit Collection with Invoice ID
    creditCollectionDoc.data.invoiceId = invoiceDoc.id;
    await creditCollectionDoc.save();

    // 4. Update Payments with Credit Collection ID - and invoiceId?
    const selectedPaymentsIds = get().selectedPaymentsIds;
    for (const paymentId of selectedPaymentsIds) {
      const paymentDoc = await PaymentsModel.findById(paymentId);
      if (paymentDoc) {
        paymentDoc.data.creditCollectionId = creditCollectionDoc.id;
        paymentDoc.data.invoiceId = invoiceDoc.id; // Optional: link payment to invoice as well
        paymentDoc.data.status = 'paid';
        paymentDoc.data.paidDate = new Date();
        await paymentDoc.save();
      }
    }

    // 5. Return both documents
    return { invoiceDoc, creditCollectionDoc };
  },

  fetchAllDocsByEntity: async (instance) => {
    let contractsDocs = await Model.extend(withPrefix(instance, contractsEntitySlug)).filterByAttributes({ deleted: 'false' });
    let contactsDocs = await Model.extend(withPrefix(instance, contactsEntitySlug)).filterByAttributes({ deleted: 'false' });
    let usersProfilesDocs = [];
    if (instance === 'main') {
      usersProfilesDocs = await Model.extend(usersProfilesEntitySlug).filterByAttributes({ deleted: 'false' });
    }
    set({
      allDocsByEntity: { contracts: contractsDocs, contacts: contactsDocs, usersProfiles: usersProfilesDocs },
    });
  },

  fetchPaymentsByContract: async (contractsIds, instance) => {
    if (contractsIds?.length) {
      let docs = await Model.extend(withPrefix(instance, 'payments')).filterByAttributes({
        contractId: { in: contractsIds },
        status: 'pending',
        deleted: 'false',
      });
      docs = _.sortBy(docs, doc => dayjs(doc.data.num));
      const groupedDocs = docs.reduce((acc, doc) => {
        const contractId = doc.data.contractId;
        if (!acc[contractId]) acc[contractId] = [];
        acc[contractId].push(doc);
        return acc;
      }, {});
      set({ paymentsByContract: groupedDocs });
    }
  },

  fetchContractsOfContact: async (selectedOption, instance) => {
    let contactClientDoc;
    let contractsOfContact;
    let customerType;

    if (selectedOption?.entity === 'contacts') {
      contactClientDoc = await Model.extend(withPrefix(instance, contactsEntitySlug)).findById(selectedOption.value);
      contractsOfContact = await Model.extend(withPrefix(instance, contractsEntitySlug)).filterByAttributes({ customerId: selectedOption.value, deleted: 'false' });
    } else if (selectedOption?.entity === 'contracts') {
      const contract = await Model.extend(withPrefix(instance, contractsEntitySlug)).findById(selectedOption.value);
      if (contract) {
        const customerId = contract.data.customerId;
        const userId = contract.data.userId;

        if (customerId) {
          contactClientDoc = await Model.extend(withPrefix(instance, contactsEntitySlug)).findById(customerId);
          customerType = 'contact';
        } else if (userId) {
          contactClientDoc = await Model.extend(withPrefix(instance, usersProfilesEntitySlug)).findById(userId);
          customerType = 'user';
        }

        if (contactClientDoc) {
          contractsOfContact = await Model.extend(withPrefix(instance, contractsEntitySlug)).filterByAttributes({ 
            [customerType === 'contact' ? 'customerId' : 'userId']: contactClientDoc.id, 
            deleted: 'false' 
          });
        }
      }
    } else if (selectedOption?.entity === 'usersProfiles') {
      contactClientDoc = await Model.extend(withPrefix(instance, usersProfilesEntitySlug)).findById(selectedOption.value);
      contractsOfContact = await Model.extend(withPrefix(instance, contractsEntitySlug)).filterByAttributes({ userId: selectedOption.value, deleted: 'false' });
    }

    // payments of contract -  Only fetch payments for the selected contract
    if (selectedOption?.entity === 'contracts' && selectedOption?.value) {
      await get().fetchPaymentsByContract([selectedOption.value], instance);
    }

    set({ 
      contractsOfContact, 
      contractsIds: contractsOfContact?.map(doc => doc.id) || [], 
      contactClientDoc,
      customerType
    });

    return { contactClientDoc, contractsOfContact };
  },

  updatePayments: async (paymentIds, paidDate, docId, instance) => {
    if (paymentIds?.length) {
      let PaymentsModel = Model.extend(withPrefix(instance, 'payments'));
      for (const paymentId of paymentIds) {
        let paymentDoc = await PaymentsModel.findById(paymentId);
        if (paymentDoc) {
          paymentDoc.data.status = 'paid';
          paymentDoc.data.paidDate = paidDate;
          paymentDoc.data.collectionId = docId;
          await paymentDoc.save();
        }
      }
      toast.success('Pagos actualizados correctamente');
    }
  },

  setSelectedPaymentsIds: (ids) => set({ selectedPaymentsIds: ids }),
})));
