import { useMemo, useState } from 'react';
import _ from 'lodash';
import { EntityTaxonomyForm, inputClasses } from '../EntityTaxonomyForm';
import { IonButton, IonModal, IonContent, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton as IonButtonIonic, IonIcon } from '@ionic/react';
import ObjectShow from '../../modules/entity/ObjectShow';
import { viewColClasses } from '../../modules/entity/docCardsVariants';
import { Form } from 'react-final-form';
import FormChangeSubscriber from './FormChangeSubscriber';
import IonBtnLoading from '../ui/IonBtnLoading';
import { 
  menuOutline
} from 'ionicons/icons';
import { objectFormToTaxonomyTypes } from '../../modules/entity/ObjectForm';


export const ObjectFormWrapped = (props) => {
  let {
    children,
    onSave,
    onValidation,
    onFormChange,
    defaultValue,
    doc,
    fieldsRequired,
    setHasErrors,
    apiGetter
  } = props;

  const handleSubmit = async (values) => {
    if (onSave) {
      await onSave(values);
    }
  };

  const validateForm = (values) => {
    const errors = {};
    // all required fields
    fieldsRequired?.forEach(field => {
      if (!values[field]) {
        errors[field] = ' ';
      }
    });
    onValidation && onValidation(values, errors);
    setHasErrors && setHasErrors(Object.keys(errors).length > 0);
    return errors;
  };

  const renderContent = ({ form, values, errors, handleSubmit, submitting }) => {
    apiGetter && apiGetter({
      form,
      values,
      errors,
      handleSubmit,
      submitting
    });

    return (<>
      {onFormChange ? (
        <FormChangeSubscriber doc={doc} onFormChange={onFormChange} />
      ) : null}
      <form 
        onSubmit={(event) => {
          // TODO: prevent submit on pressing enter on a field
          event.preventDefault(); // No funciona
        }}
      >
        {children}
      </form>
    </>);
  };

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={doc ? (doc.data || doc) : defaultValue}
      validate={validateForm}
      render={renderContent}
    />
  );
};

const RawInputObjectForm = (props) => {
  let {
    instance,
    entitySlug,
    title,
    baseFieldName,
    design = 'modal', // [ 'modal', 'block' ]
    showDataCard = true,
    showSaveButton = false,
    showCloseBtn = true,
    showTriggerBtn = true,
    saveBtnLabel,
    saveBtnColor,
    disableBtnSave = false,
    handleSubmit,
    name,
    value,
    fields,
    taxonomyTypesDataList,
    taxonomyTypesDocList,
    fieldsRequired,
    apiGetter
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  // apply prefix and render to fields
  const taxonomyTypesDataToUse = useMemo(() => {
    if (taxonomyTypesDocList) {
      return null;
    }

    if (!fields && taxonomyTypesDataList) {
      return taxonomyTypesDataList;
    }

    // without prefix
    if (!baseFieldName && !name) {
      // parse
      if (!_.isArray(fields) && _.isObject(fields)) {
        let fieldsDataList = objectFormToTaxonomyTypes(fields);
        return fieldsDataList;
      }
      // already parsed
      return fields;
    }

    // apply prefix
    else {
      let fieldsToPrefix = fields;

      // parse first
      if (!_.isArray(fieldsToPrefix) && _.isObject(fieldsToPrefix)) {
        fieldsToPrefix = objectFormToTaxonomyTypes(fieldsToPrefix);
      } 

      // apply prefix
      return fieldsToPrefix.map((data) => ({
        ...data,
        fieldName: `${baseFieldName || name}[ ${data.fieldName} ]`
      }));
    }
  }, [fields]);

  apiGetter && apiGetter({
    isOpenModal: isOpen,
    closeModal: () => setIsOpen(false),
    openModal: () => setIsOpen(true)
  });

  if (design === 'block') {
    return (
      <EntityTaxonomyForm
        fieldsRequired={fieldsRequired}
        taxonomyTypesDocList={taxonomyTypesDocList}
        taxonomyTypesDataList={taxonomyTypesDataToUse}
        classes={inputClasses}
      />
    );
  }
  return (
    <div className="space-y-2">
      {/* selected doc card */}
      {_.size(value) && showDataCard ? (
        <div className="rounded-md overflow-hidden border border-gray-200">
          <ObjectShow
            instance={instance}
            entitySlug={entitySlug}
            value={value}
            fields={fields}
            taxonomyTypesDocList={taxonomyTypesDocList}
            taxonomyTypesDataList={taxonomyTypesDataToUse}
            design="flat"
            classes={{
              cardContainer: 'p-1.5 flex flex-row gap-2 items-stretch w-full',
              ...viewColClasses
            }}
          />
        </div>
      ) : null}

      {/* trigger btn */}
      {showTriggerBtn ? (
        <IonButton onClick={() => setIsOpen(true)} size="small" fill="outline" color="medium" className="text-xs">
          <IonIcon icon={menuOutline} slot="start" size="small"></IonIcon>
          {title}
        </IonButton>
      ) : null}

      {/* modal form */}
      <IonModal isOpen={isOpen} onDidDismiss={() => setIsOpen(false)}>
        <IonHeader>
          <IonToolbar>
            <IonTitle slot="start">{title}</IonTitle>
            <IonButtons slot="end">
              {showCloseBtn ? (
                <IonButtons>
                  <IonButtonIonic onClick={() => setIsOpen(false)}>Cerrar</IonButtonIonic>
                </IonButtons>
              ) : null}

              {showSaveButton ? (
                <IonBtnLoading
                  label={saveBtnLabel || 'Guardar'}
                  size="small"
                  fill="solid"
                  color={saveBtnColor || 'primary'}
                  onClickAsync={handleSubmit}
                  disabled={disableBtnSave}
                />
              ) : null}
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div className="p-2 md:p-4 mb-64">
            <EntityTaxonomyForm
              fieldsRequired={fieldsRequired}
              taxonomyTypesDocList={taxonomyTypesDocList}
              taxonomyTypesDataList={taxonomyTypesDataToUse}
              classes={inputClasses}
            />
          </div>
        </IonContent>
      </IonModal>
    </div>
  );
};

export default RawInputObjectForm;