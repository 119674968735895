

export default function () {
  return {
    name: 'OmniChat',
    slug: 'omniChat',
    scope: 'global',
    bundles: ['core'],
    permissions: [
      // { slug: 'omniChat', label: 'OmniChat', type: 'system', actionsToAdd: ['section'] }
    ]
  };
};