import { createContext, createRef, useContext, useEffect, useState } from "react";
import config from "../../config";


const scrollContext = createContext();

export const useScrollUtils = () => {
  const context = useContext(scrollContext);
  if (!context) throw new Error("There is no ScrollUtils provider");
  return context;
};

export const ScrollUtilsProvider = ({ children }) => {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const [isScrollUnderOffset, setScrollUnderOffset] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const mainIonContentRef = createRef();
  const [scrollDetails, setScrollDetails] = useState({
    scrollTop: 0,
    scrollLeft: 0,
    scrollHeight: 0,
    scrollWidth: 0
  });
  const topOffset = config.modules.panel.scrollYtoShowFixedNavbar;

  const handleScrollUpdate = async (e) => {
    let details = {
      scrollTop: e.detail.scrollTop,
      scrollLeft: e.detail.scrollLeft,
      viewPortHeight: e.target.clientHeight,
      viewPortWidth: e.target.clientWidth
    };
    setScrollDetails(details);
  }  

  const resetScroll = () => {
    setScrollUnderOffset(false);
    setScrollPosition(0);
  };

  const handleScroll = (e) => {
    const scrollTop = e?.detail?.scrollTop || e || 0;
    setScrollUnderOffset(scrollTop >= topOffset);
    setScrollPosition(scrollTop);
  };

  const scrollTo = (id, moreOffsetPosition=0, duration=500, delay=0) => {
    const menuElement = document.getElementById('menu-header');
    if (menuElement) {
      // calc offset
      const headerOffset = menuElement.getBoundingClientRect().height;
      const element = document.getElementById(id);
      const elementPosition = element ? element.offsetTop : 0;
      const offsetPosition = elementPosition - headerOffset - moreOffsetPosition;

      const doScroll = () => {
        if (mainIonContentRef.current) {
          mainIonContentRef.current.scrollToPoint(0, offsetPosition, duration);
        }
      };

      if (delay) {
        setTimeout(() => { 
          console.log('scrollTo')
          doScroll();
        }, delay);
      } else {
        doScroll();
      }
    }
  };

  const getMenuHeight = () => {
    const menuElement = document.getElementById('menu-header');
    return menuElement ? menuElement.getBoundingClientRect().height : 0;
  };
  
  
  return (
    <scrollContext.Provider
      value={{
        scrollPosition, handleScroll,
        handleScrollUpdate,
        scrollDetails, setScrollDetails,
        isNavbarOpen, setIsNavbarOpen,
        isScrollUnderOffset, setScrollUnderOffset,
        resetScroll,
        scrollTo,
        getMenuHeight,
        mainIonContentRef
      }}
    >
      {children}
    </scrollContext.Provider>
  );
}


export const scrollTo = (id, moreOffsetPosition=0) => {
  const menuElement = document.getElementById('menu');
  if (menuElement) {
    // calc offset
    const headerOffset = 15 + menuElement.getBoundingClientRect().height;
    const element = document.getElementById(id);
    const elementPosition = element ? element.getBoundingClientRect().top : 0;
    const offsetPosition = elementPosition + window.scroolY - headerOffset - moreOffsetPosition;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
      duration: 1000,
    });
  }
}


export const useScrollTo = () => {
  const [ scrollOnceList, setScrollOnceList ] = useState([]);

  const scrollOnceTo = (elementId, offsetPosition=0) => {
    if (!scrollOnceList[elementId]) {
      setTimeout(() => { 
        scrollTo(elementId, offsetPosition); 
      }, 400);
      setScrollOnceList({ 
        ...scrollOnceList, 
        [elementId]: true
      });
    }
  }

  const scrollToDelay = (elementId, offsetPosition=0, delay=400) => {
    setTimeout(() => { 
      scrollTo(elementId, offsetPosition); 
    }, delay);
  }
  
  return { 
    scrollOnceTo,
    scrollToDelay
  };
}