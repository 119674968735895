import React, { useState, useMemo, useEffect } from 'react';
import Select from 'react-select';
import { useForm } from 'react-final-form';
import { useCreditCollectionsStore } from './useCreditCollectionsStore';
import BadgeLoading from '../../../components/ui/BadgeLoading';
import { inputClasses } from "../../../components/EntityTaxonomyForm";

const SelectorOfSubject = ({ instance }) => {
  const form = useForm();
  const [filterText, setFilterText] = useState('');
  const [innerValue, setInnerValue] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { contractsIds, resetState, allDocsByEntity, fetchAllDocsByEntity, fetchContractsOfContact, fetchPaymentsByContract } = useCreditCollectionsStore();
  
  // Fetch data when the component mounts
  useEffect(() => {
    fetchAllDocsByEntity(instance);
  }, [instance]);

  const docsByEntity = useMemo(() => {
    if (allDocsByEntity) {
      const toCompare = filterText.toLowerCase();
      const filterDocs = (docs, keys) =>
        docs.filter(doc =>
          keys.some(key => (doc.data[key]?.toString().toLowerCase() || '').includes(toCompare))
        );
      return {
        contracts: filterDocs(allDocsByEntity.contracts, ['name']),
        contacts: filterDocs(allDocsByEntity.contacts, ['name', 'citizenNumber', 'email', 'phone']),
        usersProfiles: instance === 'main'
          ? filterDocs(allDocsByEntity.usersProfiles, ['name', 'email', 'phone'])
          : [],
      };
    }
    return null;
  }, [allDocsByEntity, filterText, instance]);

  const options = useMemo(() => {
    if (docsByEntity) {
      return [
        {
          label: 'Contrataciones',
          options: docsByEntity.contracts.map(doc => ({
            label: `Contrato: ${doc.data.name || ''}`,
            value: doc.id,
            entity: 'contracts',
          })),
        },
        {
          label: 'Contactos',
          options: docsByEntity.contacts.map(doc => ({
            label: `Cliente: ${doc.data.name || ''} ${doc.data.citizenNumber ? `C.I.: ${doc.data.citizenNumber}` : ''}`,
            value: doc.id,
            entity: 'contacts',
          })),
        },
        {
          label: 'Usuarios',
          options: docsByEntity.usersProfiles.map(doc => ({
            label: `Usuario: ${doc.data.name || ''} ${doc.data.email ? `Email: ${doc.data.email}` : ''}`,
            value: doc.id,
            entity: 'usersProfiles',
          })),
        },
      ];
    }
    return [];
  }, [docsByEntity]);

  useEffect(() => {
    // Update payments when contractsIds change
    fetchPaymentsByContract(contractsIds, instance);
  }, [contractsIds, fetchPaymentsByContract, instance]);

  const handleSelectChange = async (selectedOption) => {
    setIsLoading(true);
    resetState();
    try {
      let { customerType, contactClientDoc } = await fetchContractsOfContact(selectedOption, instance);
      setInnerValue(selectedOption);
      if (selectedOption) {
        form.change('contractsIds', selectedOption.entity === 'contracts' ? [selectedOption.value] : null);
        // si directamente se seleccionó un user o un contacto
        form.change('customerId', selectedOption.entity === 'contacts' ? selectedOption.value : null);
        form.change('userId', selectedOption.entity === 'usersProfiles' ? selectedOption.value : null);
        // si se seleccionó un contrato del cual se obtuvo el contacto
        if (selectedOption.entity === 'contracts' && contactClientDoc) {
          if (customerType === 'user') {
            form.change('userId', contactClientDoc.id);
          } else {
            form.change('customerId', contactClientDoc.id);
          }
        }
      } else {
        form.change('contractsIds', null);
        form.change('customerId', null);
        form.change('userId', null);
      }
    } catch (error) {
      console.error('Error fetching contracts:', error);  
    }
    setIsLoading(false);
  };

  return (
    <div className={inputClasses?.fieldContainer || ''}>
      <div className={inputClasses?.fieldLabel}>
        <span>Clientes y Contrataciones</span>
      </div>

      {!docsByEntity ? (
        <div className="flex place-content-start font-brand-main">
          <BadgeLoading className="text-brand-dark" />
        </div>
      ) : (
        <Select
          options={options}
          isMulti={false}
          placeholder="Seleccionar"
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: 'lightgray',
              primary: 'darkgray',
            },
          })}
          value={innerValue}
          onChange={handleSelectChange}
          isDisabled={isLoading}
          menuPortalTarget={document.body}
          onInputChange={(text) => setFilterText(text)}
        />
      )}
    </div>
  );
};

export default SelectorOfSubject;
