import { useEffect, useMemo, useState } from "react";
import toast from 'react-hot-toast';
import _ from 'lodash';
import { useAuth } from "../user/AuthContext";
import { useModule } from "../../libs/ModuleContext";
import { Alert } from "../../components/ui/Alert";
import Model from "../../libs/ModelClass";
import config from "../../config";
import BadgeLoading from "../../components/ui/BadgeLoading";
import { useSettings } from "./useSettings";
import { installModules } from "../entity/RouteEntityModules";
import { getJson } from "../../libs/utils";
import { saveRoles } from "../usersSections/RouteInstanceCreate";


const mainRoute = '/';
const mainModules = ['panel', 'entity', 'user', 'instances', 'blockStack', 'usersSections'];

const Role = Model.extend('roles');
const UserProfile = Model.extend('usersProfiles');
const Credential = Model.extend('credentials');

export const useInstallIntance = ({ authData, modulesSlugsToInstall, bundlesSlugsToInstall, saveSeedData, logger }) => {
  const { modules, actionsByResource } = useModule();
  const settings = useSettings();
  const { userDoc } = authData;

  const instalableModules = useMemo(() => (
    modules
      .filter(m => _.size(m.entities) > 0)
      .filter(m => _.includes(modulesSlugsToInstall, m.slug))
  ), [modules]);

  async function installInstance(instanceDoc) {
    const isInstalled = await settings.getRemote(instanceDoc.data.hash, 'installed', false);
    if (isInstalled) {
      return true;
    }
    
    logger && await logger('Creando usuarios');

    // create superadmin permissions map
    let permissions = {}; 
    _.forEach(actionsByResource, ({ resource, actions }) => {
      // assign only instance permissions
      let actionsToAdd = _.filter(actions, a => a.startsWith('instance:'));
      if (actionsToAdd.length) {
        permissions[resource] = actionsToAdd;
      }
    });
  
    // create super-admin role
    let roleDoc = await Role.filterByAttributes({
      nameSlug: 'super-admin',
      instanceId: instanceDoc.id,
    });
    roleDoc = roleDoc[0] || await Role.create({
      name: config.modules.user.userTopRoleLabel,
      nameSlug: instanceDoc.data.hash + '.super-admin',
      permissions,
      instanceId: instanceDoc.id,
    });

    // create credentials to currentUser user
    let credentialDoc = await Credential.filterByAttributes({
      profile: userDoc.id,
      roles: roleDoc.id,
      instanceId: instanceDoc.id
    });
    credentialDoc = credentialDoc[0] || await Credential.create({
      profile: userDoc.id,
      roles: roleDoc.id,
      instanceId: instanceDoc.id,
      isActive: true,
      status: 'approved'
    });

    // install instance modules
    await installModules({
      modulesSpecs: instalableModules,
      instanceDoc,
      settings,
      logger
    });

    // flag as installed
    await settings.setRemote(instanceDoc.data.hash, 'installed', true);

    // flag as installed bundles
    for (let bundleSlug of (bundlesSlugsToInstall || [])) {
      await settings.setRemote(instanceDoc.data.hash, `bundles.${bundleSlug}`, { status: 'installed' });
    }

    // add initial data
    saveSeedData && await saveSeedData({ 
      instanceDoc,
      settings,
      logger
    });
  }

  return {
    installInstance
  }
};


export function Install(props) {
  const { history } = props;
  const { user, loginWithGoogleAndVerify } = useAuth();
  const { modules, actionsByResource } = useModule();
  const [error, setError] = useState("");
  const [superAdminEmail, setSuperAdminEmail] = useState();
  const [ saving, setSaving ] = useState(false);
  const settings = useSettings();
  const isSettingsLoaded = settings.loadScope('main');

  const instalableModules = useMemo(() => (
    // mostrar para main todos los modulos
    modules.filter(m => {
      if ( _.size(m.entities) > 0 ) {
        return true;
      }
      return false;
    })
    .filter(m => _.includes(mainModules, m.slug))
  ), [modules]);

  const verifyInstalled = async () => {
    if (isSettingsLoaded) {
      if (settings.get('main', 'installed', false)) {
        history.push(mainRoute);
      }
    }
  };

  console.log('modules', modules, instalableModules)

  useEffect(() => {
    verifyInstalled();
  }, [isSettingsLoaded]);
  
  const install = async () => {
    setSaving(true);
    let isInstalled = settings.get('main', 'installed', false);
    
    if (!isInstalled) {
      // create main instance
      const instanceDoc = await createMainInstance();
      console.log('instanceDoc', instanceDoc);
      // create role, userProfile, credentials for super-admin
      
      // create superadmin user
      const userProfiles = await UserProfile.filterByAttributes({ email: superAdminEmail });
      const user = userProfiles[0] || await UserProfile.create({
        email: superAdminEmail,
        name: 'Super Admin',
        instanceId: instanceDoc.id
      });
      console.log('user', user);

      // create super-admin role and credential
      // generate permissions map
      let permissions = {}; 
      _.forEach(actionsByResource, ({ resource, actions }) => {
        permissions[resource] = actions;
      });
      const role = await Role.create({
        name: config.modules.user.userTopRoleLabel,
        nameSlug: 'super-admin',
        permissions,
        instanceId: instanceDoc.id,
      });
      await Credential.create({
        profile: user.id,
        roles: role.id,
        instanceId: instanceDoc.id,
        isActive: true,
        status: 'approved'  
      });

      // add ownerId to main instance
      instanceDoc.data.ownerId = user.id;
      await instanceDoc.save();

      // save default roles
      let rolesData = await getJson('/data/user/mainRoles.json');
      if (rolesData) {
        await saveRoles({ rolesData, instanceDoc });
      }
      
      // install base modules
      await installModules({ modulesSpecs: instalableModules, instanceDoc: { id: 'main', data: { hash: 'main' } }, settings });
      
      // flag as installed
      await settings.setRemote('main', 'installed', true);

      // flag as installed core bundle
      await settings.setRemote('main', `bundles.core`, { status: 'installed' });

      toast('Sistema Instalado', { icon: 'ℹ️' });
      setTimeout(() => {
        toast.success('Será redirigido en breve');
        setSaving(false);
      }, 1500);
      setTimeout(() => {
        window.location.replace(config.modules.panel.mainPath);
      }, 3000);
    } else {
      toast.error('El sistema ya se encuentra instaldo');
      setSaving(false);
    }
  }

  const handleSuperAdmin = async () => {
    try {
      const credentials = await loginWithGoogleAndVerify(true);
      console.log('user?.userDoc?.id', credentials)
      setSuperAdminEmail(credentials?.user?.email);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleInstall = async () => {
    if (saving) {
      return;
    }
    try {
      await install(superAdminEmail);
    } catch (error) {
      setError(error.message);
      setSaving(false);
      console.error(error);
    }
  };

  const createMainInstance = async () => {
    const InstanceModel = Model.extend('instances');
    return await InstanceModel.create({
      hash: 'main',
      name: 'Main',
      isVisible: true
    });
  };
  
  const goToHome = () => {
    history.push(mainRoute); 
    history.go();
  };

  if (!isSettingsLoaded) {
    return (
      <div className="py-12 flex place-content-center content-center items-center font-brand-main">
        <BadgeLoading className="text-brand-dark" />
      </div>
    );
  }

  return (
    <div className="w-full max-w-xs m-auto">
      <div className="mt-10 px-5 py-5 border border-gray-300 rounded-lg shadow-xl">
        {error && <Alert message={error} />}
        <h1 className="mb-4 text-center text-2xl text-black">Instalación</h1>

        <button
          onClick={handleSuperAdmin}
          className="bg-slate-50 hover:bg-slate-200 text-black rounded-md border border-gray-500 py-2 px-4 w-full"
        >
          Asignar 
          <span className="ml-1 text-xs text-white bg-gray-600 px-2 py-0.5 rounded-full inline-block">
            superadmin
          </span>
        </button>
        <div className="p-2 text-sm text-center">
          {superAdminEmail}
        </div>

        {superAdminEmail ? (<>
          <hr className="border border-gray-300 my-5" />

          <button
            onClick={handleInstall}
            className="py-2 px-4 w-full bg-slate-700 hover:bg-slate-800 text-white text-lg rounded-md"
          >
            <div className="mx-auto align-middle">
              {!saving ? (
                <span>Instalar</span>
              ) : (<>
                <svg className="inline-block animate-spin -mt-1 mr-3 h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                <span>Instalando...</span>
              </>)}
            </div>
          </button>
        </>) : null}
      </div>

      {/* <div className="mt-14">
        <button className="underline" onClick={() => createMainInstance()}>
          createMainInstance
        </button>
      </div> */}

      <div className="mt-14">
        <button
          onClick={() => window.location.replace(config.modules.panel.mainPath) /* hard redirect to admin */ }
          className="text-black w-full underline"
        >
          Ir al panel de administración
        </button>
        <button
          onClick={goToHome}
          className="mt-1.5 text-black w-full underline"
        >
          Ir a la portada
        </button>
      </div>
    </div>
  );
}
