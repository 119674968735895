import _ from 'lodash';
import { LayoutAdmin } from "../panel/LayoutAdmin";
import { useAuth } from '../../modules/user/AuthContext';
import { useEffect, useState } from 'react';
import { IonButton, IonLabel } from '@ionic/react';
import { getConfig } from '../../config';
import { DocCard } from '../entity/docCardsVariants';
import { useEntityFullBySlug } from '../entity/Entity';
import { urlPrefix } from '../instance/utilsInstance';
import { usePanel } from '../panel/usePanel';


export function Content() {
  const config = getConfig();
  const { user } = useAuth();
  const { instance } = usePanel();
  const [ userDocRefreshed, setUserDoc ] = useState(user.userDoc);
  const entitySpecs = useEntityFullBySlug({ entitySlug: config.modules.user.usersEntitySlug });

  useEffect(() => {
    setUserDoc(user.userDoc);
  }, [user.userDoc.data]);

  return (
    <div>
      <IonButton routerLink={urlPrefix(instance, '/a/user/update-data')} routerDirection="forward" color="primary" size="small">
        <IonLabel className="ml-2 text-brand-primary-contrast">
          Actualizar datos
        </IonLabel>
      </IonButton>
      {userDocRefreshed ? (
        <DocCard
          doc={userDocRefreshed}
          entitySpecs={entitySpecs}
          classes={{cardContainer: 'mx-0'}}
        />
      ) : null}
    </div>
  );
}


export function AdminUserCustomerData(props) {
  const { user } = useAuth();
  const config = getConfig();

  return (
    <LayoutAdmin 
      history={props.history} 
      defaultHref={config.modules.panel.mainPath}
      title="Mis datos"
    > 
      <div className="ion-padding">
        {user?.userDoc?.id ? <Content/> : null}
      </div>
    </LayoutAdmin>
  );
}
