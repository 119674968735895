import _ from 'lodash';
import {
  home,
  homeOutline,
  link,
  linkOutline,
  personCircleOutline,
  personCircle,
  sparkles,
  idCard,
  arrowBackCircle,
  sparklesOutline,
  addOutline,
  businessOutline,
  idCardOutline,
  cogOutline,
  documentTextOutline,
  cashOutline,
  receiptOutline,
  cubeOutline,
  documentsOutline,
  fileTrayStackedOutline,
  diamondOutline,
  createOutline,
  peopleOutline,
  logInOutline,
  keyOutline,
  cartOutline,
  pricetagsOutline,
  shapesOutline,
  gridOutline,
  newspaperOutline,
  extensionPuzzleOutline,
  arrowBackCircleOutline
} from 'ionicons/icons';
import { RxRulerSquare } from 'react-icons/rx';
import { PiHandshake } from "react-icons/pi";
import { getInstanceFromLocation, withPrefix } from './modules/instance/utilsInstance';
import { VscSettings } from "react-icons/vsc";
import { LuSettings2 } from "react-icons/lu";


export const specBundles = [{
  slug: 'store',
  name: 'Tienda',
  // version: '1.0.0',
  description: 'Tienda virtual con páginas de catálogo y carrito de compras',
  needContract: true,
  contractPlans: {
    'pro': ['demo', 'monthly', 'yearly'],
    'advanced': ['demo', 'monthly', 'yearly'],
    'plus': ['demo', 'monthly', 'yearly'],
    'essential': ['free']
  }
}, {
  slug: 'core',
  name: 'Plataforma',
  // version: '1.0.0',
  description: 'Indispensables de la plataforma',
  needContract: false
}];

export const plansTitle = {
  essential: 'Esencial',
  pro: 'Pro',
  advanced: 'Avanzado',
  plus: 'Plus'
};

export const plansOptions = [{
  label: 'Demo',
  value: 'demo',
}, {
  label: 'Mensual',
  value: 'monthly',
}, {
  label: 'Anual',
  value: 'yearly',
}];

export const planDetails = {
  essential: [
    "Sitio web de ventas"
    // <>Catálogo de <b>hasta 10 ítems</b> con hasta 10 variaciones por ítem</>,
    // <span><b>Promo:</b> gana espacios para <b>5 ítems</b> más por <u>cada contacto que invites</u></span>
  ],
  pro: [
    <>Catálogo de <b>hasta 1000 ítems</b> con <i>hasta 35 variaciones</i> por ítem</>,
    "Creador de páginas personalizadas",
    "Páginas de ítems con bloques de contenido",
    <b>Atención preferencial</b>
  ],
  advanced: [
    <>Catálogo de <b>hasta 300 ítems</b> con <i>hasta 25 variaciones</i> por ítem</>,
    "Creador de páginas personalizadas",
    "Páginas de ítems con bloques de contenido"
  ],
  plus: [
    <>Catálogo de <b>hasta 50 ítems</b> con <i>hasta 15 variaciones</i> por ítem</>,
    <b>Creador de páginas personalizadas</b>,
    <b>Páginas de ítems con bloques de contenido</b>
  ]
};

// used in entity creator form
const tabsOptions = [{
  value: 'usersSections',
  label: 'Sección de Usuario'
}, {
  value: 'entities',
  label: 'Listados'
}, {
  value: 'configs',
  label: 'Configuraciones'
}];

// used in sidebar
export const getMenuPages = ({ menuName, userAuth, selectedInstance, instance }) => {
  instance = instance || 'main';
  let homeLink = instance === 'main' ? '/' : `/${instance}`;
  let pages;

  if (menuName === 'top') {
    pages =  [
      ///// instance y panel fusionados
      {
        sectionKey: 'instance',
        title: 'Panel', // selectedInstance?.data?.name
        ionIcon: linkOutline,
        ionIconAlt: link,
        permissions: { resource: 'usersSections', actions: ['tab:usersSections'] },
      },
      ///// panel individual
      // {
      //   sectionKey: 'panel',
      //   title: 'Panel',
      //   ionIcon: sparklesOutline,
      //   ionIconAlt: sparkles,
      //   permissions: { resource: 'panel', actions: ['tab:panel', 'instance:tab:panel'] } // base section
      // },
      ////// instance individual
      // {
      //   sectionKey: 'instance',
      //   title: 'Sitio', // selectedInstance?.data?.name
      //   ionIcon: linkOutline,
      //   ionIconAlt: link,
      //   permissions: { resource: 'usersSections', actions: ['tab:usersSections'] },
      // },
      {
        sectionKey: 'user',
        title: 'Ajustes',
        faIcon: ({ className }) => (
          <VscSettings className={className} />
        ),
        faIconAlt: ({ className }) => (
          <LuSettings2 className={className} />
        ),
        permissions: { resource: 'usersSections', actions: ['tab:usersSections'] }
      },
      {
        title: 'Portada',
        url: homeLink,
        ionIcon: arrowBackCircleOutline,
        ionIconAlt: arrowBackCircle,
        permissions: { resource: 'panel', actions: ['tab:panel', 'instance:tab:panel'] } // base section
      }
    ];
  }

  if (menuName === 'panel') {
    pages =  [
      // {
      //   title: 'Nuevo',
      //   url: `/a/panel`,
      //   ionIcon: addOutline,
      //   permissions: { resource: 'panel', actions: ['tab:panel', 'instance:tab:panel'] } // base section
      // },
      // {
      //   title: 'Episodio #',
      //   url: `/a/episode/#/docId/new`,
      //   ionIcon: addOutline,
      //   permissions: { resource: 'panel', actions: ['tab:panel', 'instance:tab:panel'] } // base section
      // }
    ];
  }

  if (menuName === 'user') {
    pages =  [
      {
        title: 'Mis Instancias',
        url: '/a/usersSections/instances',
        ionIcon: businessOutline,
        permission: { resource: 'usersSections', action: 'tab:instances' },
      },
      {
        tabKey: 'store',
        title: 'Datos personales',
        ionIcon: createOutline,
        permission: { resource: 'usersProfiles', action: 'owner:update' },
        items: [
          {
            title: 'Direcciones',
            url: '/a/user/data',
            ionIcon: businessOutline,
            permission: { resource: 'usersSections', action: 'tab:instances' },
          },
          {
            title: 'Datos de facturación',
            url: '/a/user/data',
            ionIcon: createOutline,
            permission: { resource: 'usersProfiles', action: 'owner:update' },
          }
        ]
      }
    ];
  }

  else if (menuName === 'instance') {
    pages =  [
      {
        title: 'Inicio',
        url: `/a/panel`,
        ionIcon: homeOutline,
        permissions: { resource: 'panel', actions: ['tab:panel', 'instance:tab:panel'] } // base section
      },
      {
        tabKey: 'store',
        title: 'Catálogo',
        ionIcon: gridOutline,
        permissions: { resource: 'panel', actions: ['tab:store', 'instance:tab:store'] },
        items: [
          {
            title: 'Pedidos',
            url: `/a/entity/${withPrefix(instance, 'carts')}/list`,
            permissions: { resource: withPrefix(instance, 'carts'), actions: ['section', 'instance:section'] },
            ionIcon: cartOutline
          },
          {
            title: 'Ítems',
            url: `/a/entity/${withPrefix(instance, 'cartItems')}/list`,
            permissions: { resource: withPrefix(instance, 'cartItems'), actions: ['section', 'instance:section'] },
            ionIcon: cubeOutline
          },
          {
            title: 'Categorías',
            url: `/a/entity/${withPrefix(instance, 'cartItemCategories')}/list`,
            permissions: { resource: withPrefix(instance, 'cartItemCategories'), actions: ['section', 'instance:section'] },
            ionIcon: pricetagsOutline
          },
          {
            title: 'Atributos de items',
            url: `/a/entity/${withPrefix(instance, 'cartItemFields')}/list`,
            permissions: { resource: withPrefix(instance, 'cartItemFields'), actions: ['section'] },
            ionIcon: shapesOutline
          }
        ]
      },
      {
        tabKey: 'sales',
        title: 'Ventas',
        // ionIcon: cartOutline,
        faIcon: () => (
          <PiHandshake className="text-2xl" />
        ),
        permissions: { resource: withPrefix(instance, 'invoices'), actions: ['tab:sales'] },
        items: [
          {
            title: 'Facturas',
            url: `/a/entity/${withPrefix(instance, 'invoices')}/list`,
            ionIcon: documentTextOutline,
            permission: { resource: withPrefix(instance, 'invoices'), action: 'section' },
          },
          {
            title: 'Cobros',
            url: `/a/entity/${withPrefix(instance, 'creditCollections')}/list`,
            ionIcon: cashOutline,
            permission: { resource: withPrefix(instance, 'creditCollections'), action: 'section' },
          },
          {
            title: 'Contrataciones',
            url: `/a/entity/${withPrefix(instance, 'contracts')}/list`,
            ionIcon: documentsOutline,
            permission: { resource: withPrefix(instance, 'contracts'), action: 'section' },
          },
          {
            title: 'Modelos de contratos',
            url: `/a/entity/${withPrefix(instance, 'contractsModels')}/list`,
            ionIcon: documentTextOutline,
            permission: { resource: withPrefix(instance, 'contractsModels'), action: 'section' },
          },
          {
            title: 'Talonarios de facturas',
            url: `/a/entity/${withPrefix(instance, 'invoiceBooks')}/list`,
            ionIcon: receiptOutline,
            permission: { resource: withPrefix(instance, 'invoiceBooks'), action: 'section' },
          },
          {
            title: 'Diseños para Impresión',
            url: `/a/entity/${withPrefix(instance, 'canvasPrints')}/list`,
            faIcon: RxRulerSquare,
            permission: { resource: withPrefix(instance, 'canvasPrints'), action: 'section' },
          },
        ]
      },
      {
        title: 'Contactos',
        url: `/a/entity/${withPrefix(instance, 'contacts')}/list`,
        ionIcon: peopleOutline,
        permissions: { resource: withPrefix(instance, 'contacts'), actions: ['tab:contacts'] }
      },
      {
        tabKey: 'entities',
        title: 'Listados',
        ionIcon: fileTrayStackedOutline,
        permissions: { resource: 'panel', actions: ['tab:lists', 'instance:tab:lists'] }
      },
      {
        tabKey: 'users',
        title: 'Accesos',
        ionIcon: logInOutline,
        permissions: { resource: 'panel', actions: ['tab:access', 'instance:tab:access'] },
        items: [
          {
            title: 'Usuarios',
            url: '/a/entity/usersProfiles/list',
            permissions: { resource: 'entities', actions: ['section', 'instance:section'] },
            ionIcon: peopleOutline
          },
          {
            title: 'Miembros',
            url: '/a/credentials/members',
            permissions: { resource: 'credentials', actions: ['members', 'instance:members'] },
            ionIcon: peopleOutline
          },
          {
            title: 'Credenciales',
            url: '/a/entity/credentials/list',
            permissions: { resource: 'entities', actions: ['section', 'instance:section'] },
            ionIcon: keyOutline
          },
          {
            title: 'Cargos',
            url: '/a/entity/roles/list',
            permissions: { resource: 'roles', actions: ['section', 'instance:section'] },
            ionIcon: keyOutline
          }
        ]
      },
      {
        tabKey: 'configs',
        title: 'Configuraciones',
        ionIcon: cogOutline,
        permissions: { resource: 'panel', actions: ['tab:config', 'instance:tab:config'] },
        items: [
          {
            title: 'Páginas públicas',
            url: `/a/entity/${withPrefix(instance, 'pages')}/list`,
            permissions: { resource: withPrefix(instance, 'pages'), actions: ['section', 'instance:section'] },
            ionIcon: newspaperOutline
          },
          {
            title: 'Templates de páginas',
            url: `/a/entity/pagesTemplates/list`,
            permissions: { resource: 'pagesTemplates', actions: ['section'] },
            ionIcon: newspaperOutline
          },
          {
            title: 'Branding de Instancia',
            url: '/a/instancesBrands/update',
            permissions: { resource: 'instancesBrands', actions: ['update', 'instance:update'] },
            ionIcon: diamondOutline
          },
          {
            title: 'Paquetes de módulos',
            url: '/a/instancesModules/update',
            permission: { resource: 'instancesModules', action: ['update', 'instance:update'] },
            ionIcon: extensionPuzzleOutline
          },
          {
            title: 'Pagos del servicio',
            url: `/a/instancesBilling/update`,
            permissions: { resource: 'instancesBilling', actions: ['update', 'instance:update'] },
            ionIcon: receiptOutline
          },
          {
            title: 'Entidades',
            url: `/a/entityCreator/list/#/instanceHash/${selectedInstance?.data?.hash}`,
            permissions: { resource: 'entities', actions: ['section'] },
            ionIcon: cogOutline
          },
          {
            title: 'Módulos',
            url: '/a/entities/modules',
            permissions: { resource: 'entities', actions: ['section'] },
            ionIcon: extensionPuzzleOutline
          },
          {
            title: 'General de la Instancia',
            url: `/a/instances/config`,
            permissions: { resource: 'instances', actions: ['config', 'instance:config'] },
            ionIcon: cogOutline
          },
          {
            title: 'Actualizaciones',
            url: `/a/entities/updates`,
            permissions: { resource: 'entities', actions: ['update'] },
            ionIcon: cogOutline
          },
        ]
      }
    ];
  }

  return pages;
};

let config = {
  env: 'prod', // window.location.hostname === 'localhost' ? 'dev' : 'prod',
  platformName: 'Tranqui Pro',
  platformImgPath: '/logo-platform/logo-black.svg',
  version: '1.0.0',
  prefixModels: false,
  currency: 'PYG',
  phoneCountry: 'py',
  localesNumberToTexts: ['ES_ES'],
  country: { countryId: 172, code: 'PYG', symbol: '₲', iso2: 'PY' },
  // whatsapp default message
  whatsAppTextBTN: function () { return `Hola ${this?.siteName}! me interesa saber más...` },

  /////////////////////////////////////////////////////
  //
  // BRAND
  //
  /////////////////////////////////////////////////////
  // assigned in last LayoutHome call
  
  /////////////////////////////////////////////////////
  //
  // PLATFORM
  //
  /////////////////////////////////////////////////////

  // reCaptcha
  reCaptchaSiteKey: "",
  // Maps
  googleMapApiKey: "AIzaSyDzzOk-fx4a_-Uw1sK5nlo735zFvn2h5wU",
  // Storage
  imgFolder: 'assetsImages',
  getImgPrefix: function (fileName, folder, instanceHash) { 
    instanceHash = instanceHash || getInstanceFromLocation();
    if (window.location.hostname === 'localhost') {
      return `http://localhost:9199/v0/b/${config.projectSlug}.appspot.com/o/${instanceHash}%2F${folder || config.imgFolder}%2F${fileName}?alt=media`;
    }
    return `https://firebasestorage.googleapis.com/v0/b/${config.projectSlug}.appspot.com/o/${instanceHash}%2F${folder || config.imgFolder}%2F${fileName}?alt=media`;
  },
  // Links
  getURLprefix: function () { return `${this.protocol}://${this.domain}` },
  
  imageDimensions: [
    { width: 2500, height: 2500, suffix: '' },   // full
    { width: 1300, height: 1300, suffix: '-xl' }, // xl
    { width: 800, height: 800, suffix: '-md' },   // md
    { width: 300, height: 300, suffix: '-xs' },   // xs
  ],
  // Filter url
  urlSeparator: '---',

  /////////////////////////////////////////////////////
  //
  // Modules
  //
  /////////////////////////////////////////////////////
  modules: {
    panel: {
      mainPath: '/a/panel',
      configPath: '/a/config',
      panelSystemVersionEntitySlug: 'systemVersions',
      settingsEntitySlug: 'settings',
      gps: {
        defaultDistanceForInput: 5,
        defaultDistanceMin: 3,
        defaultDistanceMax: 25,
      },
      scrollYtoShowToTopBtn: 300,
      scrollYtoShowFixedNavbar: 300,
      tabsOptions
    },

    entity: {
      entitiesEntitySlug: 'entities',
      taxonomyTypesEntitySlug: 'taxonomyTypes',
      filterMenuEntitySlug: 'filterMenu'
    },

    user: {
      userSetDataPath: '/a/user/set-data',
      usersEntitySlug: "usersProfiles",
      rolesEntitySlug: "roles",
      credentialsEntitySlug: "credentials",
      userDefaultRoleSlug: "user",
      userDefaultRoleSlugForInstances: "customer",
      userTopRoleSlug: "super-admin",
      userTopRoleLabel: "Administrador",
      fieldCity: 'city'
    },

    delivery: {
      packagesEntitySlug: "packagesToSend",
      deliveryEntitySlug: "deliveryOrders",
    },

    publicProfile: {
      publicProfileEntitySlug: 'publicProfiles',
      conectionsEntitySlug: 'conections'
    },

    pages: {
      pagesEntitySlug: 'pages'
    },
     
    instances: {
      instancesEntitySlug: 'instances',
      instancesBrandsEntitySlug: 'instancesBrands',
      instancesColorsPalettesEntitySlug: 'instancesColorsPalettes',
      instancesLayoutBlocksEntitySlug: 'instancesLayoutBlocks',
      instancesMainPartsEntitySlug: 'instancesMainParts',
      instancesMainStringsEntitySlug: 'instancesMainStrings'
    },

    message: {
      conversationsEntitySlug: 'conversations',
      messagesEntitySlug: 'messages',
    },

    cart: {
      cartsEntitySlug: 'carts',
      cartItemEntitySlug: 'cartItems',
      cartItemVariantsEntitySlug: 'cartItemVariants',
      cartItemCategoriesEntitySlug: 'cartItemCategories',
      cartItemTypesEntitySlug: 'cartItemTypes',
      cartItemFieldsEntitySlug: 'cartItemFields',
    },

    sales: {
      contractsEntitySlug: 'contracts',
      contractsModelsEntitySlug: 'contractsModels',
      invoicesEntitySlug: 'invoices',
      paymentsEntitySlug: 'payments',
      invoiceBooksEntitySlug: 'invoiceBooks',
    },

    contacts: {
      contactsEntitySlug: 'contacts',
    },

    canvas: {
      canvasPrintsEntitySlug: 'canvasPrints'
    },

    // accountManager: {
    //   accountManagerEntitySlug: 'accountManagers'
    // },

    // verification: {
    //   verificationModeratorRoleSlug: "super-admin",
    //   types: {
    //     publicProfiles: {
    //       entitySlug: 'verificationsOfProfiles'
    //     }
    //   }
    // }
  },

  // LEGACY
  // youtube video tutorial embed id
  embedId: null,
  // Cart
  whatsAppTextBTN: function () { return `Hola ${this?.siteName}! me interesa saber más de sus servicios.` },
};

export const getConfig = () => {
  return config;
};

export const setConfig = (newConfig = {}) => {
  config = _.assign(config, newConfig);
};

export default config;