import { IonIcon } from '@ionic/react';


const Icon = ({ slot = "start", ionIcon, ionIconAlt, faIcon, faIconAlt, isCurrent, classes }) => {
  let classesCurrent = isCurrent ? classes?.iconCurrent : '';
  return (
    (ionIconAlt || ionIcon)
      ? (
        <IonIcon
          slot={slot}
          icon={isCurrent && ionIconAlt ? ionIconAlt : ionIcon} 
          className={`${classes?.icon} ${classesCurrent}`}
        />
      ) 
      : (faIconAlt || faIcon) ? (
        ((isCurrent && faIconAlt) ? faIconAlt : faIcon)?.({
          slot,
          className: `${classes?.icon} ${classesCurrent}`
        })
      ) 
      : null
  );
};

export default Icon;