import { IonBadge, IonButton, IonIcon, IonItem, IonLabel, IonList, IonNote, IonTitle, IonToolbar, useIonRouter } from "@ionic/react";
import { usePanel } from "../panel/usePanel";
import _ from "lodash";
import BadgeLoading from "../../components/ui/BadgeLoading";
import {
  addOutline,
  peopleOutline,
  layersOutline
} from 'ionicons/icons';
import { getConfig } from "../../config";
import Model from "../../libs/ModelClass";
import IonBtnLoading from "../../components/ui/IonBtnLoading";
import { useAsyncMemo } from "use-async-memo";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import { usePrevious } from "../../libs/usePrevious";
import { OmniChatInputForStart } from "../omniChat/components/OmniChatInputForStart";
import { useSecretary } from "../omniChat/useSecretary";
import { AnimationAddClassWhenVisible } from "../../components/ui/AnimationAddClassWhenVisible";
import { BtnList } from "../blockStack/parts/PartBtnList";


const credentialsEntitySlug = getConfig().modules.user.credentialsEntitySlug;

const getCreateInstanceBtnDef = (overrides = {}) => ({
  ctaLabel: 'Crear instancia',
  ctaType: 'link',
  ctaLink: {
    ctaUrl: `/a/usersSections/instanceCreate`,
    ctaTarget: 'router'
  },
  btnSize: 'small',
  btnColor: 'medium',
  btnFill: 'clear',
  ionIcon: addOutline,
  ...overrides
});

const getListInstancesBtnDef = () => ({
  ctaLabel: 'Listar instancias',
  ctaType: 'link',
  ctaLink: {
    ctaUrl: `/a/usersSections/instances`,
    ctaTarget: 'router'
  },
  btnSize: 'small',
  btnColor: 'medium',
  btnFill: 'clear',
  ionIcon: layersOutline,
  // classes
});

const WelcomeHeader = ({ userAuth, instanceDoc, isOwner, classes }) => {
  return (
    <div className={classes?.fieldContainer}>
      <IonTitle size="large" className="px-0">
        Hola {userAuth.userDoc.data.name}
      </IonTitle>
      <IonTitle size="medium" color="medium" className="px-0">
        Bienvenido
        {instanceDoc?.data.hash !== 'main' ? (
          <span> a {instanceDoc?.data.name}</span>
        ) : null}
      </IonTitle>
      {isOwner ? (
        <IonBadge size="small" color="secondary" className="">Propietario</IonBadge>
      ) : null}
    </div>
  );
};


// In Main
{/* access from main as user */}
{/* access from main as super-admin */}
export const HomeForSuperAdminInMain = (props) => {
  let {
    instancesOfUser,
    instance,
    roleOfInstanceSlug,
    roleOfMainSlug,
    credentialOfInstanceDoc, 
    roleOfInstanceDoc,
    instanceDoc,
    isOwner
  } = props;
  const { history } = useIonRouter();
  const { 
    inputContainerRef, inputRef, text, setText, onSend, loading,
    conversationDoc
  } = useSecretary();

  let aboveBtns = [];

  if (instancesOfUser?.length) {
    aboveBtns.push(getCreateInstanceBtnDef());
    aboveBtns.push(getListInstancesBtnDef());
  } else {
    aboveBtns.push(getCreateInstanceBtnDef());
  }

  const onNewMessage = (newMessage) => {
    console.log('newMessage', newMessage)
  };

  return (
    <div className="p-16 md:p-0 space-y-10">
      <div className="z-[550] flex flex-col place-content-center md:max-w-xl xl:max-w-2xl 2xl:max-w-3xl mx-auto">
        <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[100ms]">
          <WelcomeHeader {...props} 
            classes={{ 
              fieldContainer: 'md:px-0 pt-16 pb-16 md:pt-32 md:pb-24'
            }}
          />
        </AnimationAddClassWhenVisible>
        {aboveBtns ? (
          <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[100ms]">
            <BtnList
              strings={{ items: aboveBtns }}
              params={{ type: 'row', gap: '2' }}
              classes={{ btnListContainer: 'w-fit' }}
            />
          </AnimationAddClassWhenVisible>
        ) : null}
      </div>
      {/* <OmniChatInputForStart
        onSend={onNewMessage}
        aboveBtns={aboveBtns}
        AboveInput={() => (
          <WelcomeHeader {...props} 
            classes={{ 
              fieldContainer: 'px-8 md:px-0 pt-24 pb-16 md:pt-32 md:pb-24'
            }}
          />
        )}
      /> */}
    </div>
  );
};

// In Instances
/* Instance guest unregistred */
export const HomeForUserAsForeign = (props) => {
  let {
    instance,
    userAuth,
    roleOfInstanceSlug,
    roleOfMainSlug,
    // credentialOfInstanceDoc, 
    roleOfInstanceDoc,
    instanceDoc,
    isOwner
  } = props;
  const [ credentialOfInstanceDocAsked, setCredentialOfInstanceDocAsked ] = useState();

  const credentialOfInstanceDoc = useAsyncMemo(async () => {
    if (credentialOfInstanceDocAsked) {
      return credentialOfInstanceDocAsked;
    }
    if (props.credentialOfInstanceDoc) {
      return props.credentialOfInstanceDoc;
    }
    const Credential = Model.extend(credentialsEntitySlug);
    let docs = await Credential.filterByAttributes({
      profile: userAuth?.userDoc?.id,
      instanceId: instanceDoc?.id,
      deleted: 'false'
    });
    docs = _.sortBy(docs, 'data.createdAt');
    return docs[0];
  }, [credentialOfInstanceDocAsked, userAuth?.userDoc?.id, instanceDoc?.id]);

  const previousCredentialDoc = usePrevious(credentialOfInstanceDoc);

  useEffect(() => {
    return credentialOfInstanceDoc?.onSnapshot(async newDoc => {
      if (
        previousCredentialDoc?.id === newDoc?.id
      ) {
        if (previousCredentialDoc?.data?.status !== newDoc?.data?.status) {
          setCredentialOfInstanceDocAsked(newDoc);
          if (newDoc?.data?.status === 'approved') {
            toast.success('Acceso concedido');
            window.location.reload();
          } else if (newDoc?.data?.status === 'denied') {
            toast.error('Acceso denegado');
          }
        }
      }
    });
  }, [credentialOfInstanceDoc?.id]);
  

  const askAccess = async () => {
    // solicitar acceso y crear credencial
    const Credential = Model.extend(credentialsEntitySlug);
    try {
      const newDoc = await Credential.create({
        instanceId: instanceDoc.id,
        profile: userAuth.userDoc.id,
        isActive: false,
        status: 'pending'
      });
      setCredentialOfInstanceDocAsked(newDoc);
      toast.success('Acceso solicitado');
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    }
  }

  return (
    <div className="p-4 sm:pt-4 space-y-10">
      <WelcomeHeader {...props} />

      {/* solicitar acceso y crear credencial */}
      <IonToolbar inset={true}>
        <IonItem lines="none">
          <IonIcon icon={peopleOutline} className="mr-2" slot="start"></IonIcon>
          {!credentialOfInstanceDoc ? (
            <IonBtnLoading
              type="button"
              label="Solicitar Acceso"
              onClickAsync={askAccess}
              color="primary"
              size="small"
              fill="clear"
            ></IonBtnLoading>
          ) : (<>
            {credentialOfInstanceDoc?.data?.status === 'pending' ? (
              <IonBadge size="small" color="warning">Pendiente</IonBadge>
            ) : null}
            {credentialOfInstanceDoc?.data?.status === 'denied' ? (
              <IonBadge size="small" color="danger">Rechazado</IonBadge>
            ) : null}
          </>)}
        </IonItem>
      </IonToolbar>
      {/* <IonNote color="medium" className="ion-margin-horizontal">
        No tienes credenciales de acceso
      </IonNote> */}
    </div>
  );
};


/* Instance user: owner and member */
export const HomeForUserAsMember = (props) => {
  let {
    instance,
    roleOfInstanceSlug,
    roleOfMainSlug,
    credentialOfInstanceDoc, 
    roleOfInstanceDoc,
    instanceDoc,
    isOwner
  } = props;
  
  return (
    <div className="p-4 sm:pt-4 space-y-10">
      <WelcomeHeader {...props} />
      {/* credenciales */}
      <IonToolbar inset={true}>
        <IonItem lines="none">
          <IonIcon icon={peopleOutline} className="mr-2" slot="start"></IonIcon>
          <IonTitle size="small">{roleOfInstanceDoc?.data.name}</IonTitle>
        </IonItem>
      </IonToolbar>
    </div>
  );
};

export const AdminHomeContent = (props) => {
  let {
    instance,
    isAllowed,
    userAuth,
    history
  } = props;
  const { isLoadingInstance, selectedInstance } = usePanel();
  instance = instance || 'main';
  const instancesOfUser = userAuth.instancesDocs?.filter(doc => doc.data.ownerId === userAuth.userDoc.id);
  const roleOfInstanceSlug = userAuth.roleOfInstanceDoc?.data.nameSlug;
  const credentialOfInstanceDoc = userAuth.credentialOfInstanceDoc;
  const roleOfInstanceDoc = userAuth.roleOfInstanceDoc;
  const roleOfMainSlug = userAuth.roleOfMainDoc?.data.nameSlug;
  const instanceDoc = userAuth.instanceDoc || selectedInstance;
  const isOwner = userAuth.userDoc.id === instanceDoc?.data.ownerId;

  const context = {
    userAuth,
    instancesOfUser,
    instance, roleOfInstanceSlug, roleOfMainSlug, instanceDoc, isOwner, credentialOfInstanceDoc, roleOfInstanceDoc
  };

  if (!selectedInstance?.id || isLoadingInstance) {
    return (
      <div className="py-12 flex place-content-center content-center items-center font-brand-main">
        <BadgeLoading className="text-brand-dark" />
      </div>
    );
  }

  return (<>
    {/* Main */}
    {/* Users in general */}
    {/* access from main as user */}
    {instance === 'main' && roleOfMainSlug === 'user' ? (
      <HomeForSuperAdminInMain {...props} {...context} /> // use same as super-admin
    ) : null}

    {/* Super-admin only */}
    {/* access from main as super-admin */}
    {instance === 'main' && roleOfMainSlug === 'super-admin' ? (
      <HomeForSuperAdminInMain {...props} {...context} />
    ) : null}

    {/* Instances */}
    {/* Instance guest unregistred */}
    {/* access from instance without credentials */}
    {instance !== 'main' && (!roleOfInstanceSlug ||  !credentialOfInstanceDoc?.data.isActive) ? (<>
      <HomeForUserAsForeign {...props} {...context} />
    </>) : null}

    {/* Instance user: owner and member */}
    {/* access from instance and credentials */}
    {instance !== 'main' && roleOfInstanceSlug && credentialOfInstanceDoc?.data.isActive ? (<>
      <HomeForUserAsMember {...props} {...context} />
    </>) : null}
  </>);
};