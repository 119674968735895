import _ from "lodash";
import { IonButton, IonIcon } from "@ionic/react";
import { useEffect, useState } from "react";
import { LoadingIcon } from "./BadgeLoading";


export default function IonBtnLoading({ 
  isLoading,
  label,
  icon,
  ionIcon,
  onClick,
  onClickAsync,
  className,
  classNameDisabled,
  type = 'button',
  color,
  size,
  loadingIconSize,
  iconSize,
  fill,
  shape,
  disabled = false,
  onClickDisabled
 }) {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (_.isUndefined(isLoading)) {
      return;
    }
    if (isLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [isLoading]);

  const onClickWrapper = async () => {
    if (disabled) {
      onClickDisabled && onClickDisabled();
      return;
    }
    if (onClick) {
      onClick();
    } 
    else if (!loading && onClickAsync) {
       setLoading(true);
       await onClickAsync();
       setLoading(false);
     }
  };

  return (
    <IonButton
      {...{
        type, color, size, fill, shape
      }}
      className={`
        ${ disabled ? (classNameDisabled || className) : className || ''}
      `}
      disabled={disabled || loading}
      onClick={onClickWrapper}
    >
      {loading ? (
        <LoadingIcon slot={label ? 'start' : 'icon-only'} className={(loadingIconSize || size) === 'sm' ? 'h-4 w-4' : 'h-6 w-6'} />
      ) : (
        ionIcon ? (
          <IonIcon slot={label ? 'start' : 'icon-only'} icon={ionIcon} size={iconSize || size} />
        ) : (
          <div slot="start" className="min-w-6 text-center">
            {icon}
          </div>
        )
      )}
      <span className={`ml-1`}>
        {label || null}
      </span>
    </IonButton>
  );
}