import _ from 'lodash';
import React, { useState } from "react";
import CanvasViewWorkspaceLazy from "../canvas/CanvasCreator/CanvasViewWorkspaceLazy";
import { PrintBtn } from "../print/PrintBtn";
import { useAsyncMemo } from "use-async-memo";
import { getConfig } from "../../config";
import { getPrefix, withPrefix } from "../instance/utilsInstance";
import Model from "../../libs/ModelClass";
import BadgeLoading from "../../components/ui/BadgeLoading";
import dayjs from 'dayjs';
import { getOptionLabel } from '../../components/DataTypes/dataTypeUtils';
import { getAmountNum, toAmount } from '../../components/Form/utilsCurrency';
import numberToWordsLocalized from '../../libs/utilsNumbers';
import { useEntityFullBySlug } from '../entity/Entity';

// Formato de datos de la factura
// disponible como variables para el diseño de impresión
//
// {
//   "id": "00227bd4f4d0419qzl00a",                   // Unique identifier of the invoice.
//   "number": "001-002-02423423",              // Official invoice number.
//   "invoiceBookId": "002274c472a9fki4crkuc",        // ID of the invoice book.
//   "createdAt": "2024-11-28T00:08:49.020Z",         // ISO 8601 timestamp of invoice creation.
//   "customer": {}, // Customer details (structure not shown).
//   "items": [],   // use invoiceItemsColumns to format items for print
//   "modifiedAt": "2024-12-23T17:46:18.727Z",  // ISO 8601 timestamp of last modification.
//   "deleted": false,                                // Flag indicating if the invoice is deleted.

//   "issuedDate": "23 de dic de 2024 14:44",         // Human-readable issue date.
//   "saleCondition": "Contado",                      // Sale condition (null in example).
//   "paymentMethod": "Efectivo",                     // Sales terms (null in example).

//   "bussinessName": "Nombre de la empresa",            // Business name.
//   "bussinessRuc": "80000000-0",                        // Business RUC.
//   "bussinessAddress": "Dirección de la empresa",       // Business address.

//   "invoiceItemsColumns": {                             // Derived data for print formatting.
//     "descriptions": "Pago xxxx200.000 este si es\nun texto largo con mas de\nlos 20 caracteres que...", // Multi-line item descriptions.
//     "prices": "200.000 ₲\n\n\n\n150.000 ₲\n\n\n\n",        // Multi-line item prices.
//     "quantities": "4\n\n\n\n6\n\n\n\n", // Multi-line item quantities.
//     "totals": "800.000 ₲\n\n\n\n900.000 ₲\n\n\n\n",         // Multi-line item totals.
//     "vat0": "200.000 ₲\n\n\n\n150.000 ₲\n\n\n\n",
//     "vat5": "200.000 ₲\n\n\n\n150.000 ₲\n\n\n\n",
//     "exempt": "200.000 ₲\n\n\n\n150.000 ₲\n\n\n\n"
//   },

//   "taxes": {                                 // Calculated taxes.
//     "vat5": {},                              // Tax details (structure not shown).
//     "exempt": {}                            // Tax details (structure not shown).
//   },
//   "totalPrice": {                            // Total invoice amount.
//     "country": {},                           // Currency/formatting details (structure not shown).
//     "qty": "1.700.000",                      // Formatted total amount.
//     "rawQty": 1700000                        // Total amount as a raw number.
//   },

//   // totals
//   "taxesExempt": "200.000 ₲",
//   "taxesVat5": "200.000 ₲",
//   "taxesVat10": "200.000 ₲",
//   "totalPriceString": "1.700.000 ₲",      // Total amount as a string.
//   "totalPriceWords": "un millón setecientos mil",      // Total price in words.
// }


// Utilizar lodash para reemplazar las variables con el delimitador "{{ var }}"
const parseLayersTexts = (layers, vars) => {
  if (!layers) {
    return [];
  }

  const templateSettings = { interpolate: /{{([\s\S]+?)}}/g };

  return layers.map((layer) => {
    if (layer?.type !== 'text') {
      return layer;
    }

    let content = layer.content;
    try {
      content = _.template(content, templateSettings)(vars);
    } catch (error) {
      console.error(`Error parsing layer content: ${error.message}`, layer, vars);
    }

    return {
      ...layer,
      content
    };
  });
};


// Transpilar los items de la factura
// el objetivo es convertir los items de la factura en un formato que pueda ser interpretado por el diseño de impresión
// de una manera en que las variables de los items de la factura sean distribuídas en columnas: nombre, cantidad, precio, total
// mediante variables que se agregan a dataToParse, a la vez que se distribuyen en varias líneas si es necesario,
// lo hace sin cortar palabras, y se limita la cantidad de caracteres por línea según el valor de printSettings.maxTextLength
//
// Ejemplo:
// dataToParse = {
//   invoice: {
//     items: [
//       { name: 'Item 1, extense name with more then 20 characters describing the item', quantity: 1, price: 100 },
//       { name: 'Item 2, extense name with more then 20 characters describing the item', quantity: 2, price: 200 },
//       { name: 'Item 3, extense name with more then 20 characters describing the item', quantity: 3, price: 300 },
//       { name: 'Item 4, extense name with more then 20 characters describing the item', quantity: 4, price: 400 },
//       { name: 'Item 5, extense name with more then 20 characters describing the item', quantity: 5, price: 500 }
//     ]
//   }
// };
//
// con printSettings = {
//   maxTextLength: 20,
//   maxListLength: 14
// };
//
// se convierte en:
// invoiceItemsColumns = {
//  descriptions: `
//    Item 1, extense name with more
//    then 20 characters describing
//    the item
//    Item 2, extense name with more
//    then 20 characters describing
//    the item
//  `,
//  quantities: `
//    1
//     
//     
//    2
//     
//     
//  `,
//  prices: `
//    100
//     
//     
//    200
//     
//     
// `,
//  totals: `
//    100
//     
//     
//    200
//     
//     
// `
// }

// Function to extract item data as strings
const getItemDataStrings = (item) => ({
  description: item.descripcion || item.name || '',
  qty: item?.qty?.qty || '',
  price: `${item?.price?.qty || ''} ${item?.price?.country?.symbol || ''}`,
  total: `${item?.totalPrice?.qty || ''} ${item?.totalPrice?.country?.symbol || ''}`,
  exempt: `${item?.taxes?.exempt?.qty || ''} ${item?.taxes?.exempt?.country?.symbol || ''}`,
  vat5: `${item?.taxes?.vat5?.qty || ''} ${item?.taxes?.vat5?.country?.symbol || ''}`,
  vat10: `${item?.taxes?.vat10?.qty || ''} ${item?.taxes?.vat10?.country?.symbol || ''}`,
});

// Function to split a string into lines with a maximum length
const splitStringIntoLines = (text, maxLength) => {
  let lines = [];
  let words = text.split(' ');
  let line = '';

  for (let i = 0; i < words.length; i++) {
    let word = words[i];
    if (line.length + word.length + 1 > maxLength) {
      lines.push(line);
      line = '';
    }
    line += (line ? ' ' : '') + word;
  }

  if (line) {
    lines.push(line);
  }

  return lines;
};

const transpileItemsToTextColumns = (itemsToParse, settings) => {
  itemsToParse = itemsToParse || [];
  let maxTextLength = settings?.maxTextLength || 20;

  if (!itemsToParse.length) {
    return itemsToParse;
  }

  itemsToParse = itemsToParse.reduce((acc, item) => {
    const itemData = getItemDataStrings(item);
    const descriptionLines = splitStringIntoLines(itemData.description, maxTextLength);
    
    for (let i = 0; i < descriptionLines.length; i++) {
      acc.push({
        description: descriptionLines[i],
        qty: i === 0 ? itemData.qty : '',
        price: i === 0 ? itemData.price : '',
        total: i === 0 ? itemData.total : '',
        exempt: i === 0 ? itemData.exempt : '',
        vat5: i === 0 ? itemData.vat5 : '',
        vat10: i === 0 ? itemData.vat10 : ''
      });
    }

    return acc;
  }, []);

  let result = {
    descriptions: itemsToParse.map((item) => item.description).join('\n'),
    quantities: itemsToParse.map((item) => item.qty).join('\n'),
    prices: itemsToParse.map((item) => item.price).join('\n'),
    totals: itemsToParse.map((item) => item.total).join('\n'),
    exempt: itemsToParse.map((item) => item.exempt).join('\n'),
    vat5: itemsToParse.map((item) => item.vat5).join('\n'),
    vat10: itemsToParse.map((item) => item.vat10).join('\n')
  };

  return result;
};

// Formatear los datos de la factura a texto
const formateInvoiceDataToTexts = async (invoiceData, invoiceTaxonomyTypes, entitySlug) => {
  ////// invoice data
  console.log('formateInvoiceDataToTexts', invoiceTaxonomyTypes);

  // issuedDate using dayjs
  if (invoiceData.issuedDate) {
    invoiceData.issuedDate = dayjs(invoiceData.issuedDate).format('lll');
  }
  // saleCondition label
  if (invoiceData.saleCondition) {
    invoiceData.isCash = invoiceData.saleCondition === 'cash' ? 'x' : '';
    invoiceData.isCredit = invoiceData.saleCondition === 'credit' ? 'x' : '';
    invoiceData.saleCondition = getOptionLabel(invoiceData, 'saleCondition', invoiceTaxonomyTypes);
  }
  // paymentMethod label
  if (invoiceData.paymentMethod) {
    invoiceData.paymentMethod = getOptionLabel(invoiceData, 'paymentMethod', invoiceTaxonomyTypes);
  }
  // taxes // TODO
  // total price as texts
  if (invoiceData.totalPrice) {
    invoiceData.totalPriceWords = numberToWordsLocalized(getAmountNum(invoiceData.totalPrice), getConfig().localesNumberToTexts);
    invoiceData.totalPriceString = `${invoiceData.totalPrice?.qty || ''} ${invoiceData.totalPrice?.country?.symbol || ''}`;
  }

  ////// contact data

  // contact name // TODO populate contact data from contact entity or use normalized data
  invoiceData.bussinessName = "";
  invoiceData.bussinessRuc = "";
  invoiceData.bussinessAddress = "";
  invoiceData.bussinessPhone = "";

  if (invoiceData.customer?.type === 'contact') {
    // get contact doc
    let contactDoc = await Model.extend(withPrefix(getPrefix(entitySlug), getConfig().modules.contacts.contactsEntitySlug))
      .findById(invoiceData.customer.contactId);
    if (contactDoc) {
      invoiceData.bussinessName = contactDoc.data.bussinessName || `${contactDoc.data.name || ''} ${contactDoc.data.lastName || ''}`;
      invoiceData.bussinessRuc = contactDoc.data.bussinessRuc || contactDoc.data.citizenNumber || '';
      invoiceData.bussinessAddress = contactDoc.data.bussinessAddress || contactDoc.data.address || '';
      invoiceData.bussinessPhone = contactDoc.data.bussinessPhone || contactDoc.data.phone || '';
    }
  }
  else if (invoiceData.customer?.type === 'casual') {
    let {
      bussinessName,
      bussinessRuc,
      bussinessAddress,
      bussinessPhone
    } = invoiceData.customer;

    invoiceData.bussinessName = bussinessName;
    invoiceData.bussinessRuc = bussinessRuc;
    invoiceData.bussinessAddress = bussinessAddress;
    invoiceData.bussinessPhone = bussinessPhone;
  }

  ////// taxes
  // sum taxable base
  invoiceData.taxBaseExempt = toAmount( _.sum(invoiceData.items.map((item) => getAmountNum(item.taxes.exempt))) );
  invoiceData.taxBaseVat5 = toAmount( _.sum(invoiceData.items.map((item) => getAmountNum(item.taxes.vat5))) );
  invoiceData.taxBaseVat10 = toAmount( _.sum(invoiceData.items.map((item) => getAmountNum(item.taxes.vat10))) );
  // taxable base to string
  invoiceData.taxBaseExempt = `${invoiceData.taxBaseExempt?.qty || ''} ${invoiceData.taxBaseExempt?.country?.symbol || ''}`;
  invoiceData.taxBaseVat5 = `${invoiceData.taxBaseVat5?.qty || ''} ${invoiceData.taxBaseVat5.country?.symbol || ''}`;
  invoiceData.taxBaseVat10 = `${invoiceData.taxBaseVat10?.qty || ''} ${invoiceData.taxBaseVat10.country?.symbol || ''}`;
  // tax vat amount
  invoiceData.taxVat5 = `${invoiceData.taxes?.vat5?.qty || ''} ${invoiceData.taxes?.vat5.country?.symbol || ''}`;
  invoiceData.taxVat10 = `${invoiceData.taxes?.vat10?.qty || ''} ${invoiceData.taxes?.vat10.country?.symbol || ''}`; 
  // total tax vat amount
  invoiceData.totalTaxVat = toAmount( getAmountNum(invoiceData.taxes?.vat5) + getAmountNum(invoiceData.taxes?.vat10) );
  invoiceData.totalTaxVat = `${invoiceData.totalTaxVat?.qty || ''} ${invoiceData.totalTaxVat?.country?.symbol || ''}`;

  return invoiceData;
};


const parseInvoiceToPrint = async (invoiceDoc, entitySlug, invoiceTaxonomyTypes) => {
  if (
    !invoiceDoc?.data?.invoiceBookId
    || !invoiceTaxonomyTypes
  ) {
    return {};
  }
  let invoiceBookDoc = await Model.extend(withPrefix(getPrefix(entitySlug), getConfig().modules.sales.invoiceBooksEntitySlug))
    .findById(invoiceDoc.data.invoiceBookId);
  if (!invoiceBookDoc.data.printDesign) { return { invoiceBookDoc }; }
  let printDesignDoc = await Model.extend(withPrefix(getPrefix(entitySlug), getConfig().modules.canvas.canvasPrintsEntitySlug))
    .findById(invoiceBookDoc.data.printDesign);
  if (!printDesignDoc.data?.canvas) { return { invoiceBookDoc, printDesignDoc }; }

  let canvasWorkspaces = printDesignDoc.data?.canvas?.workspaces || printDesignDoc.data?.canvas || [];
  let printSettings = _.omit(printDesignDoc.data?.canvas || {}, 'workspaces');

  let dataToParse = {
    invoice: await formateInvoiceDataToTexts({ ...invoiceDoc.data }, invoiceTaxonomyTypes, entitySlug)
  };

  // Transpilar los items de la factura, agregar columnas: descriptions, quantities, prices, totals
  dataToParse.invoiceItemsColumns = transpileItemsToTextColumns(invoiceDoc.data.items, printSettings);
  
  canvasWorkspaces = canvasWorkspaces?.map((workspace) => {
    return {
      ...workspace,
      layers: parseLayersTexts(workspace.layers, dataToParse)
    };
  });
  let canvasWorkspaceId = canvasWorkspaces[0]?.id;

  console.log('template variables', dataToParse, invoiceDoc);

  return {
    invoiceBookDoc,
    printDesignDoc,
    canvasWorkspaces,
    canvasWorkspaceId
  };
};


const PrintInvoiceModalContent = ({ invoiceDoc, entitySlug, printableId }) => {
  let { taxonomyTypesDocList } = useEntityFullBySlug({ entitySlug });
  let invoiceTaxonomyTypes = taxonomyTypesDocList;

  const [ exportedSVG, setExportedSVG ] = useState(null); // Estado para guardar el SVG

  const {
    invoiceBookDoc,
    printDesignDoc,
    canvasWorkspaces,
    canvasWorkspaceId
  } = useAsyncMemo(() => parseInvoiceToPrint(invoiceDoc, entitySlug, invoiceTaxonomyTypes), [invoiceDoc, entitySlug, invoiceTaxonomyTypes?.length]) || {};

  // Manejar la exportación del SVG desde CanvasViewWorkspace
  const handleExportSVG = (svg) => {
    setExportedSVG(svg); // Guardar el SVG en el estado
  };

  return (
    <div className="m-2 md:m-4 -w-full flex flex-col justify-center items-center">
      {(
        invoiceDoc?.data && printDesignDoc && canvasWorkspaceId
      ) ? (
        <>
          <div className="hidden">
            <div className="min-w-64 w-full min-h-64 h-64 lg:w-96 lg:h-96 flex justify-center items-center border border-gray-200 shadow-md">
              {/* Canvas invisible para generar el SVG */}
              <CanvasViewWorkspaceLazy
                workspaces={canvasWorkspaces}
                workspaceId={canvasWorkspaceId} // Selecciona el primer workspace por defecto
                invisible={true}
                onExportSVG={handleExportSVG}
              />
            </div>
          </div>
        </>
      ) : null}

      {!(invoiceBookDoc && printDesignDoc && canvasWorkspaceId) ? (
        <div className="py-12 flex place-content-center content-center items-center font-brand-main">
          <BadgeLoading className="text-brand-dark" />
        </div>
      ) : (
        <div id={printableId} className="">
          <div
            className="border border-gray-300 shadow-md rounded"
            dangerouslySetInnerHTML={{ __html: exportedSVG }}
          />
        </div>
      )}
    </div>
  );
};


export const BtnPrintInvoice = ({ invoiceDoc, entitySlug, color, fill, size }) => {
  const printableId = 'printable-container';

  return (
    <PrintBtn
      modalTitle="Impresión de Factura"
      buttonLabel="Imprimir"
      color={color}
      fill={fill}
      size={size}
      printableId={printableId}
    >
      <PrintInvoiceModalContent
        invoiceDoc={invoiceDoc}
        entitySlug={entitySlug}
        printableId={printableId}
      />
    </PrintBtn>
  );
};
