import { useState } from "react";
import printJS from "print-js";
import { IonButton, IonButtons, IonModal, IonHeader, IonTitle, IonToolbar, IonContent, IonIcon } from "@ionic/react";
import { printOutline } from "ionicons/icons";

const getStyle = ({ contentId }) => {
  const style = `
    @media print {
      #${contentId} {
        font-family: Arial, sans-serif;
        font-size: 12px;
        color: black;
      }
      .page-break {
        page-break-after: always;
      }
    }
  `;

  return style;
};
  
export const PrintBtn = (props) => {
  let { printableId, modalTitle, buttonLabel, color, fill, size, children } = props;
  const [showModal, setShowModal] = useState(false);

  const handlePrint = () => {
    const printContainer = document.getElementById(printableId);
    if (!printContainer) {
      alert("No hay contenido para imprimir.");
      return;
    }

    // Extraer el contenido para imprimir
    const html = printContainer.innerHTML;

    // Usar printJS para imprimir el contenido
    printJS({
      printable: html,
      type: "raw-html",
      style: getStyle({ contentId: printableId })
    });
  };

  return (
    <>
      <IonButton onClick={() => setShowModal(true)} color={color || "medium"} fill={fill || "clear"} size={size || "small"}>
        <IonIcon slot="start" icon={printOutline} className="text-xl"></IonIcon>
        {buttonLabel || 'Imprimir'}
      </IonButton>
      {showModal && (
        <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)} backdropDismiss className="wide">
          <IonHeader>
            <IonToolbar>
              <IonTitle>{modalTitle || "Impresión"}</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setShowModal(false)}>Cerrar</IonButton>
                <IonButton color="primary" fill="solid" expand="full" onClick={handlePrint}>
                  <IonIcon slot="start" icon={printOutline} className="text-xl" />
                  Imprimir
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent scrollY={true} scrollX={true}>
            {children}
          </IonContent>
        </IonModal>
      )}
    </>
  );
};
  