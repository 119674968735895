import { columnRightClasses } from "../../entity/RouteEntityCrudForm";
import ColumnRight from "./ColumnRight";
import * as events from "./events";
import RelatedData from "./RelatedData";
import SelectorOfSubject from "./SelectorOfSubject";
import TotalAndInvoice from "./TotalAndInvoice";


const CreditCollectionsEntity = {
  entitySlug: 'creditCollections',
  permissions: [
    { slug: 'creditCollections', label: 'Cobros de créditos' }
  ],
  components: {
    crud: {
      'settings': () => ({
        showBtnAdd: true,
        showBtnSave: false
      }),
      // para mostrar total a pagar e interacción de facturación, que finaliza guardando y saliendo
      'FormToolbarEnd': TotalAndInvoice,
      'FormPrepend-paidDate': SelectorOfSubject,
      'FormAppend-paidDate': RelatedData,
      'FormAppendColumnRight': {
        classes: columnRightClasses,
        Render: ColumnRight
      }
    }
  },
  events
}

export default CreditCollectionsEntity;