import _ from 'lodash';
import { usePartOfModule } from "../../components/Module/PartOfModule";


export const useModuleSettings = ({ entitySlug, contractModelMetaData }) => {
  const moduleSettingsFn = usePartOfModule({
    type: 'crud',
    entitySlug,
    action: 'settings',
    param: {}
  });
  const moduleSettings = _.defaults((moduleSettingsFn && moduleSettingsFn({ contractModelMetaData })) || {}, {
    title: false,
    reorder: false,
    showBtnAdd: true,
    showBtnShow: true,
    showBtnSave: true,
    showBtnDelete: true,
    showBtnUpdate: true,
    showBtnMove: true,
    showInstanceSelector: false,
    // addButtonPermissionAction,
    // addButtonLabel,    
    redirectOnSave: true,
    queryToCountForLimits: null,
    fieldForCountLimit: null
  });

  return {
    moduleSettings
  };
}