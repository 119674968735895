import _ from 'lodash';
import { getConfig } from '../../config';
import { useState } from 'react';
import { inputClasses } from '../../components/EntityTaxonomyForm';
import { withPrefix } from '../instance/utilsInstance';
import { RawInputSelectDocumentExtended } from '../../components/Form';


const RenderForm = (props) => {
  const { instance, value, onChange } = props;
  
  const [ bookId, setBookId ] = useState();
  const [ bookDoc, setBookDoc ] = useState();

  // useEffect para obtener el valor inicial

  const assignBook = (bookDoc) => {
    setBookId(bookDoc?.id);
    setBookDoc(bookDoc);
    let currentSerial = _.parseInt(bookDoc?.data?.lastNum) + 1;
    onChange({
      bookId: bookId,
      serial: currentSerial
    });
  };

  console.log('bookId', bookId, bookDoc, value)

  return (
    <div className="">
      <RawInputSelectDocumentExtended
        instance={instance}
        entitySlug={withPrefix(instance, getConfig().modules.sales.invoiceBooksEntitySlug)}
        title="Talonario"
        btnLabel="Talonario"
        value={bookId}
        onAfterChange={assignBook}
        className={inputClasses.fieldInput || ''}
        showTriggerBtn={true}
      />
    </div>
  );
};

const RenderShow = (props) => {
  const { displayedValue, instance } = props;

  return (
    <div className="">

    </div>
  );
};

const dataTypeInvoiceBook = {
  RenderForm,
  RenderShow,
  RenderShowList: RenderShow
};

export default dataTypeInvoiceBook;
