import { useEffect, useMemo, useRef, useState } from "react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemGroup,
  IonLabel,
  IonList,
  IonRippleEffect,
  IonToolbar
} from "@ionic/react";
import { menuController } from '@ionic/core/components';
import config, { getConfig, getMenuPages } from "../../config";
import { useModule } from "../../libs/ModuleContext";
import { usePanel } from "./usePanel";
import { useAuth } from "../user/AuthContext";
import PartOfModule from "../../components/Module/PartOfModule";
import MenuOfSections from "./MenuOfSections";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import SimpleBar from "simplebar-react";


export const useMenuIsSelected = () => {
  let { selectedInstance, instance, attachPrefix } = usePanel();
  let location = useLocation();
  const { userAuth } = useAuth();
  
  const checkIsCurrentPage = (page) => {
    // for go to front page
    if (page?.url === '/') { 
      return false;
    }
    // for top sections menú
    if (page?.sectionKey) {
      let innerPages = getMenuPages({ menuName: page.sectionKey, userAuth, selectedInstance, instance });
      let hasInnerSelected = innerPages?.find((innerPage) => {
        // for inner pages sections with sub items
        if (innerPage?.items) {
          let hasInnerSelected = innerPage.items.find((innerPage) => location.pathname.includes(attachPrefix(innerPage.url)));
          return hasInnerSelected;
        }
        else {
          return location.pathname.includes(attachPrefix(innerPage.url));
        }
      });
      return hasInnerSelected;
    }
    if (page && !page.url && !page.sectionKey) {
      return true;
    }
    return location.pathname.includes(attachPrefix(page.url));
  };

  return {
    checkIsCurrentPage
  };
};

const TopMenu = ({ pages, selectedSection, onSelectSection, className }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  let { checkIsCurrentPage } = useMenuIsSelected();

  const getItemClass = (page, index, isCurrent) => {
    if (isCurrent) {
      return 'bg-brand-primary-tint';
    }
    if (hoveredIndex === index || selectedSection === page) {
      return 'bg-brand-medium-tint';
    }
    return '';
  };

  return (
    <div className={`flex flex-col items-center ${className}`}>
      {pages.map((page, index) => {
        let isCurrent = !!checkIsCurrentPage(page);
        let faIcon = isCurrent ? page.faIconAlt : page.faIcon;
        return (
          <div
            key={index}
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
            onClick={() => onSelectSection(page)}
            className="ripple-parent flex flex-col items-center w-full cursor-pointer transition-all duration-200"
          >
            <div className={`
              flex justify-center items-center p-2 rounded-lg mb-1 
              ${getItemClass(page, index, isCurrent)}
            }
            `}> 
              {(page.ionIconAlt || page.ionIcon) ? (
                <IonIcon 
                  icon={isCurrent && page.ionIconAlt ? page.ionIconAlt : page.ionIcon} 
                  className={`text-3xl ${isCurrent ? 'text-brand-medium-contrast' : 'text-brand-medium-contrast'}`}
                />
              ) : null}
              {(page.faIconAlt || page.faIcon) ? (
                ((isCurrent && page.faIconAlt) ? page.faIconAlt : page.faIcon)?.({
                  className: `text-3xl ${isCurrent ? 'text-brand-medium-contrast' : 'text-brand-medium-contrast'}`
                })
              ) : null}
            </div>
            <span className="text-center text-[11px] text-brand-medium-contrast">{page.title}</span>
            <IonRippleEffect />
          </div>
        );
      })}
    </div>
  );
};

export function MenuMainContent({ 
  history,
  triggerId,
  menuName
}) {
  const location = useLocation();
  const moduleLibs = useModule(); 
  const { selectedInstance, instance } = usePanel();
  const { user, userAuth } = useAuth();
  const [selectedSection, setSelectedSection] = useState();
  const topMenuContentRef = useRef(null);
  let { checkIsCurrentPage } = useMenuIsSelected();

  const openMenu = async () => {
    await menuController.open(triggerId);
  };

  const closeMenu = async () => {
    await menuController.close(triggerId);
  };

  const handleSelectSection = (section) => {
    setSelectedSection(section);
    if (section.url) {
      history.push(section.url);
    }
  };

  const topSectionsMenuSpecs = useMemo(() => {
    if (selectedInstance && userAuth?.userDoc) {
      return getMenuPages({ menuName: 'top', userAuth, isAllowed: moduleLibs.isAllowed, selectedInstance, instance });
    }
    return [];
  }, [userAuth?.userDoc, instance, selectedInstance?.id]);

  useEffect(() => {
    topSectionsMenuSpecs.find((page, index) => {
      let isCurrent = !!checkIsCurrentPage(page);
      if (isCurrent) {
        setSelectedSection(page);
        return page;
      }
    });
  }, [location, topSectionsMenuSpecs]);

  return (<>
    <IonContent className="!p-0 !m-0 side-menu" scrollY={false}>
      <div className="flex flex-row">
        {/* top menu */}
        <SimpleBar
          ref={topMenuContentRef}
          className="h-full max-h-full min-w-[64px] max-w-[64px] w-[64px]"
        >
          <TopMenu
            pages={topSectionsMenuSpecs}
            selectedSection={selectedSection}
            onSelectSection={handleSelectSection}
            className="py-4 gap-8"
          />
        </SimpleBar>

        {/* section side menu */}
        <div className="w-full max-w-full">
          <SimpleBar
            className="h-full max-h-full w-full max-w-full pr-2"
          >
            <PartOfModule
              type="main"
              action="SideMenuSectionHeader"
              param={{ selectedSection, userAuth, history, triggerId, openMenu, closeMenu, ...moduleLibs }}
            />
            
            <MenuOfSections selectedSection={selectedSection} {...{openMenu, closeMenu}} />

            <PartOfModule
              type="main"
              action="SideMenuSectionFooter"
              param={{ selectedSection, userAuth, history, triggerId, openMenu, closeMenu, ...moduleLibs }}
            />
          </SimpleBar>
        </div>
      </div>

    </IonContent>
  </>);
}
