import _ from 'lodash';
import { RawInputObjectForm } from '../../components/Form';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ObjectFormWrapped } from '../../components/Form/RawInputObjectForm';
import { objectFormToTaxonomyTypes } from '../entity/ObjectForm';


const taxesFields = {
  exempt: {
    type: 'numberAmount',
    name: 'Exentas'
  },
  vat5: {
    type: 'numberAmount',
    name: 'IVA 5%'
  },
  vat10: {
    type: 'numberAmount',
    name: 'IVA 10%'
  }
};

const requiredFields = [];

export const RawInputInvoiceTaxes = (props) => {
  const { title, instance, entitySlug, value, onChange, param } = props;
  const [ formData, setFormData ] = useState({});
  const [ hasErrors, setHasErrors ] = useState(false);
  const taxesTaxonomyTypesDataList = useMemo(() => objectFormToTaxonomyTypes(taxesFields), []);
  const modalApiRef = useRef();
  const formApiRef = useRef();

  useEffect(() => {
    if (_.size(value)) {
      setFormData(value);
      formApiRef.current?.form.reset(value);
    } else {
      setFormData({});
      formApiRef.current?.form.reset({});
    }
  }, [value]);
  
  const assignDataAsValue = () => {
    modalApiRef.current?.closeModal();
    onChange(_.cloneDeep(formData));
  }
  
  return (
    <div className="">
      <ObjectFormWrapped
        defaultValue={formData}
        onFormChange={setFormData}
        fieldsRequired={requiredFields}
        setHasErrors={setHasErrors}
        apiGetter={(api) => formApiRef.current = api}
      >
        <RawInputObjectForm
          value={formData}
          handleSubmit={assignDataAsValue}
          showDataCard={true}
          showSaveButton={true}
          showCloseBtn={false}
          showTriggerBtn={!param?.disabled}
          baseFieldName="customer"
          btnLabel={title}
          instance={instance}
          entitySlug={entitySlug}
          title={title}
          taxonomyTypesDataList={taxesTaxonomyTypesDataList}
          fieldsRequired={requiredFields}
          saveBtnLabel="Asignar"
          saveBtnColor="primary"
          disableBtnSave={hasErrors}
          apiGetter={(api) => modalApiRef.current = api}
        />
      </ObjectFormWrapped>
    </div>
  );
};

const RenderShow = (props) => {
  const { displayedValue } = props;

  console.log('invoice taxes', displayedValue);

  return (
    <div className="">
      Taxes
    </div>
  );
};

const dataTypeInvoiceTaxes = {
  RenderForm: RawInputInvoiceTaxes,
  RenderShow
};

export default dataTypeInvoiceTaxes;
