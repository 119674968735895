import _ from 'lodash';
import InvoiceItemsTableLazy from './InvoiceItemsTableLazy';


const RenderForm = (props) => {
  const { value, onChange } = props;
  return (
    <InvoiceItemsTableLazy value={value} onChange={onChange} />
  );
};

const RenderShow = (props) => {
  const { displayedValue } = props;

  console.log('invoice items', displayedValue);

  return (
    <div className="">
    </div>
  );
};

const dataTypePaymentConfig = {
  RenderForm,
  RenderShow
};

export default dataTypePaymentConfig;
