import { LayoutAdmin } from "./LayoutAdmin";
import { useModule } from "../../libs/ModuleContext";
import PartOfModule from "../../components/Module/PartOfModule";
import config from "../../config";
import { usePanel } from "./usePanel";


export function AdminTabHome(props) {
  const { isAllowed, user } = useModule();
  const { instance } = usePanel();

  return (
    <LayoutAdmin 
      history={props.history}
      // title="Inicio"
      defaultHref={config.modules.panel.mainPath}
      {...props}
    >
      <PartOfModule
        type="main"
        action="AdminHomeBeforeContent"
        param={{ isAllowed, user, instance }}
      />

      <PartOfModule
        type="main"
        action="AdminHomeContent"
        param={{ isAllowed, user, instance }}
      />

      <PartOfModule
        type="main"
        action="AdminHomeAfterContent"
        param={{ isAllowed, user, instance }}
      />
    </LayoutAdmin>
  );
}