import {useMemo } from 'react';
import _ from 'lodash';
import { objectFormToTaxonomyTypes } from './ObjectForm';
import { DocCard } from './docCardsVariants';


const ObjectShow = (props) => {
  let { instance, entitySlug, fields, taxonomyTypesDataList, taxonomyTypesDocList, value, classes={} } = props;

  const taxonomyTypesDocListToUse = useMemo(() => {
    if (taxonomyTypesDocList) {
      return taxonomyTypesDocList;
    }
    if (_.isArray(fields)) {
      return fields.map((data, id) => ({ id, data }));
    }
    let fieldsDataList = taxonomyTypesDataList || objectFormToTaxonomyTypes(fields);
    return fieldsDataList.map((data, index) => ({
      id: index,
      data: {
        ...data,
        nameSlug: data.fieldName
      }
    }));
  }, [fields]);

  return (
    <DocCard
      {...props}
      instance={instance}
      doc={value?.data ? value : { data: value, id: 0 }}
      entitySpecs={{
        entitySlug,
        taxonomyTypesDocList: taxonomyTypesDocListToUse
      }}
      classes={classes}
      outstandingOnly={false}
    />
  );
};

export default ObjectShow;