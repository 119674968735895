import { useEffect, useState } from "react";
import config from "../../config";
import { useAuth } from "../user/AuthContext";
import Model from "../../libs/ModelClass";
import { getContractModelMetaData } from "./RouteInstanceModules";
import { useIonLoading } from "@ionic/react";


const instancesEntitySlug = config.modules.instances.instancesEntitySlug;
const InstanceModel = Model.extend(instancesEntitySlug);

/**
 * To use in usePanel
*/ 
export const useWithInstances = (props) => {
  const { history, currentInstance } = props;
  const [ selectedInstance, setInstanceState ] = useState();
  const [ instancesDocs, setInstancesDocs ] = useState([]);
  const [ contractModelMetaData, setContractModelMetaData ] = useState([]);
  const [ isLoadingInstance, setIsLoading ] = useState(true);
  const userSpecs = useAuth();
  const [present, dismiss] = useIonLoading();
  
  useEffect(() => {
    setInstancesDocs(userSpecs.user?.instancesDocs);
  }, [userSpecs.user?.instancesDocs]);

  useEffect(() => {
    fetchAndAssignInstance(currentInstance);
  }, [currentInstance]);

  // useEffect(() => {
  //   if (isLoadingInstance) {
  //     present({
  //       message: 'Accediendo',
  //       duration: 2500
  //     });
  //   } else {
  //     dismiss();
  //   }
  // }, [isLoadingInstance, present, dismiss]);

  const fetchAndAssignInstance = async (instance) => {
    setIsLoading(true);
    let instanceDoc = await fetchInstanceByHash(instance);
    if (instanceDoc) {
      const contractModelMetaData = await getContractModelMetaData(instanceDoc.data.plan?.contractModelId);
      setContractModelMetaData(contractModelMetaData);
      await setSelectedInstance(instanceDoc);
    }
    setIsLoading(false);
  };

  const setSelectedInstance = async (instanceDoc, doRedirect = true) => {
    if (doRedirect && currentInstance !== instanceDoc.data.hash) {
      let newBasePath = instanceDoc.data.hash !== 'main' ? `/${instanceDoc.data.hash}` : '';
      let newPanelPath = newBasePath + config?.modules?.panel?.mainPath;
      history.replace(newPanelPath);
    }
    await userSpecs.refreshInstance(instanceDoc);
    setInstanceState(instanceDoc);
  };

  const attachPrefix = (url, instanceDocToUse) => {
    instanceDocToUse = instanceDocToUse || selectedInstance;
    return instanceDocToUse && instanceDocToUse?.data.hash !== 'main'
      ? `/${instanceDocToUse?.data.hash}${url}`
      : url;
  }

  const fetchInstanceByHash = async (instance) => {
    // use existing data
    let instanceDoc = instancesDocs.find((doc) => doc?.data.hash === instance);
    if (instanceDoc) {
      return instanceDoc;
    }
    // fetch
    return await InstanceModel.whereOne('hash', '==', instance || currentInstance);
  };

  return {
    isLoadingInstance,
    instancesDocs,
    setInstancesDocs,
    selectedInstance,
    setSelectedInstance,
    contractModelMetaData,
    attachPrefix,
    fetchInstanceByHash
  }
};