import { useState, useEffect } from 'react';
import FormFieldSelect from './FormFieldSelect';
import Model from '../../libs/ModelClass';
import { useEntityFullBySlug } from '../../modules/entity/Entity';
import _ from 'lodash';
import { getJson, sortDocsByField } from '../../libs/utils';


// copy normalized fields
const applyNormalizedFields = (optionData, docData, normalizedFields) => {
  normalizedFields?.forEach(field => {
    optionData[field] = docData[field] || docData.data ? docData.data[field] : undefined;
  });
  return optionData;
}
/**
 * Componente de campo de formulario para seleccionar un documento de un modelo.
 * @param {string} name - Nombre del campo en el formulario.
 * @param {string} title - Título del campo.
 * @param {entitySlug} entitySlug - Nombre del modelo utilizado para obtener las opciones.
 * @param {string} className - Clases CSS adicionales para el contenedor del campo.
 * @param {string} placeholder - Texto de marcador de posición para la opción predeterminada.
 * @param {Array} fieldsRequired - Campos adicionales requeridos para mostrar el error.
 * @param {function} validate - Función de validación personalizada para el campo.
 * @param {boolean} disabled - Indica si el campo está deshabilitado.
 * @param {boolean} showLabel - Indica si se muestra o no la etiqueta del campo.
 * @param {function} onSelect - Función de devolución de llamada cuando se selecciona una opción.
 * @param {boolean} editMode - Indica si el componente está en modo de edición.
 * @param {string} initialValue - Valor inicial del campo cuando no está en modo de edición.
 * @returns {JSX.Element} Componente de campo de formulario para seleccionar un documento de un modelo.
 */
const FormFieldSelectOneEntityDocument = (props) => {
  let {
    name,
    // title,
    entitySlug,
    inheritFilter,
    hardFilter,
    fromJson,
    fieldValue,
    fieldLabel,
    queryGetter,  
    optionsMapper,
    optionsGetter,
    // className,
    // placeholder,
    // fieldsRequired = null,
    // validate = null,
    // disabled = false,
    // showLabel = true,
    onSelect = ((selectedId, selectedItem) => null),
    // editMode = false,
    // initialValue = '',
    // classes
    values = {},
    form,
    normalizedFields
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [options, setOptions] = useState([]);
  const { taxonomyTypesDocList, mainAttr } = useEntityFullBySlug({ entitySlug });

  // muestra en el label el valor de la taxonomía principal [0]
  // let taxonomyTypeSelected;
  // if (taxonomyTypesDocList && taxonomyTypesDocList.length > 1) {
  //   taxonomyTypeSelected = taxonomyTypesDocList.find((taxonomyType) => taxonomyType.data?.sort === 0);
  // }
  
  let valuesToUse = values || form?.getState().values;

  queryGetter = queryGetter || (async (ExtendedModel) => {
    let docs;
    if (fromJson) {
      docs = await getJson(fromJson);
      docs = docs.map(data => new ExtendedModel(data));
    }
    // filter with inherit foreign keys
    else if (inheritFilter || hardFilter) {
      let queryFilter = {};
      let hasUndefined = false;
      
      _.forEach(inheritFilter, (value, key) => {
        queryFilter[key] = valuesToUse[key];
        if (_.isUndefined(valuesToUse[key]) || _.isNull(valuesToUse[key])) {
          hasUndefined = true;
        }
      });
      
      if (_.isObject(hardFilter)) {
        queryFilter = {
          ...hardFilter,
          ...queryFilter
        };
      }
      // find
      if (!hasUndefined) {
        docs = await ExtendedModel.filterByAttributes(queryFilter);
      }
      // empty
      else {
        docs = [];
      }
      // restart current field if is not the first refresh
      if (refresh) {
        form?.change(name, null);
        form?.resetFieldState(name);
      }
      setRefresh(refresh+1);
    } 
    // get all docs
    else {
      docs = await ExtendedModel.getAll();
      docs = docs.filter(doc => !doc.data.deleted);
    }
    return sortDocsByField(docs, 'sort');
  });

  optionsMapper = optionsMapper || ((docs) => {
    return docs.map((option) => {
      if (fromJson) {
        return option ? applyNormalizedFields({
          value: option[fieldValue],
          label: option.data.deleted === true
            ? option?.data[fieldLabel] + ' [eliminado]'
            : option?.data[fieldLabel]
        }, option, normalizedFields) : {}
      }
      return applyNormalizedFields({
        // TODO: si está elimnado mostrar sólo el documento seleccionado 
          // mostrando [elimnado], las demás opciones eliminadas no mostrar en el selector
        value: option?.id,
        label: option?.data.deleted === true
          ? option?.data[mainAttr] + ' [eliminado]'
          : option?.data[mainAttr]
      }, option, normalizedFields);
    });
  });

  let extraRefreshers = [];
  if (inheritFilter) {
    _.forEach(inheritFilter, (value, key) => {
      extraRefreshers.push(valuesToUse[key]);
    });
  }
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const ExtendedModel = Model.extend(entitySlug);
      const docs = await queryGetter(ExtendedModel);
      const docsOptions = optionsMapper(docs.filter(options => options?.data?.deleted !== true));
      // set options
      optionsGetter && optionsGetter(docsOptions || []);
      setOptions(docsOptions || []);
      setIsLoading(false);
    };
    if (entitySlug === 'entities') {
      fetchData();
    } else {
      taxonomyTypesDocList && mainAttr && fetchData();
    }
  }, [entitySlug, taxonomyTypesDocList, mainAttr, ...extraRefreshers]);

  return (
    <FormFieldSelect
      onSelect={(selectedId) => {
        const selectedItem = options.find((option) => option?.id === selectedId);
        onSelect && onSelect(selectedId, selectedItem);
      }}
      {...{ ...props, options }}
      disabled={isLoading}
    />
  );
};

export default FormFieldSelectOneEntityDocument;