import _ from "lodash";
import React, { useRef, useState } from "react";
import {
  IonButton,
  IonIcon,
  IonModal
} from "@ionic/react";
import { createOutline } from "ionicons/icons";
import CanvasViewWorkspaceLazy from "./CanvasCreator/CanvasViewWorkspaceLazy";
import CanvasCreatorLazy from "./CanvasCreator/CanvasCreatorLazy";
import { RawInputObjectForm } from "../../components/Form";
import { ObjectFormWrapped } from "../../components/Form/RawInputObjectForm";
import { inputClasses } from "../../components/EntityTaxonomyForm";
import ObjectShow from "../entity/ObjectShow";
import { viewColClasses } from "../entity/docCardsVariants";


const fields = {
  maxTextLength: {
    type: 'number',
    name: 'Ancho del nombre de los items',
    descriptionText: 'Cantidad de letras que se mostrarán en el nombre de los items, se distribuye en varias líneas si es necesario.',
    max: 200,
    min: 0,
  },
  maxListLength: {
    type: 'number',
    name: 'Cantidad máxima de items',
    descriptionText: 'Cantidad máxima de items que se mostrarán en la factura.',
    max: 200,
    min: 0,
  }
};

export const RawInputCanvasToPrint = (props) => {
  let { value, onChange } = props;
  const [showModal, setShowModal] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const formApiRef = useRef();
  
  // Apply new design
  const handleApply = (newDesign) => {
    onChange({ ...value, workspaces: newDesign });
    setShowModal(false);
  };

  // Live update Print settings
  const onFormChange = (newFormData) => {
    onChange({ ...value, ...newFormData });
  };

  return (<>    
    {/* Canvas */}
    <IonButton 
      onClick={() => setShowModal(true)}
      color="primary"
      fill="outline"
      size="small"
    >
      <IonIcon icon={createOutline} slot="start" />
      Editar Documento
    </IonButton>

    <IonModal
      isOpen={showModal}
      onDidDismiss={() => setShowModal(false)}
      backdropDismiss={false}
      className="wide"
    >
      <CanvasCreatorLazy
        title="Diseño para impresión"
        value={value?.workspaces}
        onChange={handleApply}
        onClose={() => setShowModal(false)}
      />
    </IonModal>

    {/* Print settings */}
    <div className={inputClasses.renderListTopMargin}>
      <ObjectFormWrapped
        defaultValue={value}
        onFormChange={onFormChange}
        fieldsRequired={[]}
        setHasErrors={setHasErrors}
        apiGetter={(api) => (formApiRef.current = api)}
      >
        <RawInputObjectForm
          value={value}
          design="block"
          showDataCard={false}
          btnLabel="Guardar"
          title="Editar Propiedades del Canvas"
          fields={fields}
          fieldsRequired={[]}
          showSaveButton={true}
          saveBtnLabel="Guardar"
          saveBtnColor="primary"
          disableBtnSave={hasErrors}
        />
      </ObjectFormWrapped>
    </div>
  </>);
};

const RenderShow = (props) => {
  let { displayedValue, instance, entitySlug } = props;

  if (!displayedValue || displayedValue.length === 0) {
    console.warn('RenderShow: displayedValue está vacío o no definido');
    return (
      <p className="text-center text-lg text-gray-600">
        No hay datos para mostrar.
      </p>
    );
  }

  const workspacesToUse = displayedValue?.workspaces ? displayedValue.workspaces : (_.isArray(displayedValue) ? displayedValue : [displayedValue]);

  return (
    <div>
      <div className="min-w-64 w-full min-h-64 h-64 lg:w-96 lg:h-96  flex justify-center items-center border border-gray-200 shadow-md">
        <CanvasViewWorkspaceLazy
          workspaces={workspacesToUse}
          workspaceId={workspacesToUse[0]?.id} // Selecciona el primer workspace por defecto
        />
      </div>

      <div className={inputClasses.renderListTopMargin}>
        <ObjectShow
          instance={instance}
          entitySlug={entitySlug}
          value={displayedValue}
          fields={fields}
          classes={viewColClasses}
          design="block"
        />
      </div>
    </div>
  );
};

const dataTypeCanvasToPrint = {
  RenderForm: RawInputCanvasToPrint,
  RenderShow
};

export default dataTypeCanvasToPrint;