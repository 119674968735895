import specTheme from "./specTheme";
import { setConfig } from "../config";


/////////////////////////////////////////
// Site identity
/////////////////////////////////////////

export const serverConfig = {
  projectSlug: 'tranqui-pro',
  domain: 'tranqui.pro',
  protocol: 'https'
};

export const siteIdentity = {
  siteName: 'Tranqui Pro',
  siteMoto: 'Tu aliado web!',
  // sector
  // customer
  // differentiation
  callNumber: '595991744029',
  whatsappNumber: "595991744029",
  email: "hola.tranquipy@gmail.com",
  addressString: 'Roberto Badén casi Cantailicia Lugo, San Lorenzo',
  defaultLocationGPS: { // defaultGps
    "lat": -25.321397098353774,
    "lng": -57.52855090970302
  },
  instagram: 'https://www.instagram.com/tranqui.pro/',
  facebook: "https://www.facebook.com/tranqui-pro",
  currency: 'PYG',
  phoneCountry: 'py',
  localesNumberToTexts: ['ES_ES'], // numbers2words
  country: { countryId: 172, code: 'PYG', symbol: '₲', iso2: 'PY' },

  // whatsapp default message
  whatsAppTextBTN: function () { return `Hola ${this?.siteName}! me interesa saber más sobre el Catálogo Virtual, mi nombre es ...` }
};

/////////////////////////////////////////
// MAIN PARTS
/////////////////////////////////////////

export const specMainParts = {
  logo: {
    profileImg: '',
    logoDark: '/logo-platform/logo-black.svg',
    logoLight: '/logo-platform/logo-white.svg',
  },

  blocks: {
    // header: blocks,
    // footer: blocks,
    menuMain: {
      // ...layoutBlocksDoc.data.menuMain
      logo: [{
        screen: 'xxs',
        source: 'logoLight',
        size: 8,
        resolution: 'full',
        shape: 'transparent',
        round: 'none',
        showSiteName: false
      }]
    }
  }
};

/////////////////////////////////////////
// Default strings for layout blocks
/////////////////////////////////////////

export const stringsMapLayoutBlocks = {
  header: {
    title: "Tu Aliado en Desarrollo de Software",
    subtitle: "Ofrecemos soluciones tecnológicas de calidad para micro, pequeñas y medianas empresas",
    ctaLabel: "Pedir presupuesto",
    ctaType: "whatsapp"
  },
  headerMini: {
    title: "Tu Aliado",
    subtitle: "en Desarrollo de Software",
    params: {
      mainBg: {
        type: 'gradient',
        bgColorDirection: 'to bottom right',
        bgColorFrom: '#211cf4', 
        bgColorTo: '#1ac3ff',
        // classes: {
        //   bgContainer: 'h-96'
        // }
      }
    }
  },
  contactCta: {
    title: "¡Contáctanos y recibe una asesoría gratuita!",
    subtitle: "Háblanos de tu proyecto y te ayudaremos a encontrar la mejor solución.",
    email: "hola.tranquipy@gmail.com",
    phoneNumber: "+595991744029",
    address: "Roberto Badén casi Cantailicia Lugo, San Lorenzo",
    schedule: "Abierto de lunes a viernes de 09:00 a 16:00 hs.",
    content: "Si tienes alguna pregunta o necesitas más información sobre nuestros servicios, no dudes en contactarnos. ¡Estamos aquí para ayudarte a tener la mejor experiencia en Tranqui Pro!",
    items: [
      {
        iconType: 'whatsapp',
        title: "WhatsApp",
        url: "https://wa.me/595991744029?text=¡Hola!+Tranqui+Pro+Estoy+interesado+en+obtener+más+información+sobre+los+servicios+de+Tranqui+Pro."
      },
      {
        iconType: 'phone',
        title: "Llamar",
        url: "tel:+595991744029"
      },
      {
        iconType: 'email',
        title: "Email",
        url: "mailto:hola.tranquipy@gmail.com"
      },
      // {
      //   iconType: 'location',
      //   title: "Ubicación",
      //   url: "https://goo.gl/maps/..."
      // }
    ]
  },
  ctaFooter: {
    params: {
      mainBg: {
        type: 'color',
        bgColor: '#1ac3ff'
      }
    }
  },
  footer: {
    backgroundImageUrl: "",
    linkWebsite: "https://www.tranqui.pro",
    linkWebsiteLabel: "www.tranqui.pro",
    socialLinkTitle: "Seguinos en:",
    linkFacebook: "https://www.facebook.com/tranqui-pro",
    linkInstagram: "https://www.instagram.com/tranqui.pro",
    email: "hola.tranquipy@gmail.com",
    number: "595991744029",
    numberString: "(0991) 744 029",
    contactMessage: "Hola Tranqui Pro! quiero más información sobre el Catálogo Virtual",
    // numberString: "(0991) 273 333",
    locationString: "San Lorenzo, Paraguay",
    parts: {
      ctaFloat: {
        ctaType: "whatsapp",
        ctaLabel: "Pedir presupuesto",
        contactMessage: "Hola Tranqui Pro! quiero más información sobre el Catálogo Virtual"
      }
    }
  },
  modalSignUp: {
    modalTitle: "Crea tu catálogo",
    modalContent: `
### Es fácil!

- Inicia sesión con Gmail
- Completa tus datos de usuario
- Personaliza tu catálogo
- Carga tus productos y servicios
- ¡Listo!

    `
  },
};

export const paramsMapLayoutBlocks = {
  header: {
    // design: 'mini',
    mainBg: { 
      type: 'gradient',
      bgColorFrom: '#211cf4', bgColorTo: '#1ac3ff', bgColorDirection: 'to bottom right'
    }
  },
  footer: {
    showCTA: true,
    showPoliticsLinks: true,
    showMadeWithLove: true
  }
};

export const specDesign = {
  /////////////////////////////////////////
  // SITE IDENTITY
  /////////////////////////////////////////
  stringsVars: {
    instance: {},
    brand: {
      ...siteIdentity,
    },
    // page: {
    //   title: ''
    // }
  },
  
  /////////////////////////////////////////
  // THEME
  /////////////////////////////////////////
  fonts: {
    ...specTheme.fonts
  },
  colors: {
    ...specTheme.colors
  },
  
  /////////////////////////////////////////
  // MAIN PARTS
  /////////////////////////////////////////
  ...specMainParts
};

/////////////////////////////////////////
// MAIN CONFIG
/////////////////////////////////////////

setConfig({
  ...serverConfig,
  mainSpecDesign: specDesign
});