import { useState, useEffect } from 'react';
import { collection, query, where, orderBy, doc, setDoc, getDoc, getDocs, updateDoc, writeBatch, deleteField, onSnapshot, startAt, endAt, limit, getFirestore, documentId } from "firebase/firestore";
import _ from 'lodash';
import { db, getGeofire } from '../firebase';
import config from '../config';
import { replaceUndefinedAndNaNWithNull, sortDocsByField } from './utils';


export function generateTimestampId() {
  // Obtener el timestamp actual en milisegundos
  const timestamp = Date.now();
  // Definir el timestamp futuro (miliseconds)
  const maxTimestamp = 4102455600000; // Timestamp para el 1 de enero de 2100
  // Calcular el complemento
  const invertedTimestamp = maxTimestamp - timestamp;
  // Convertir el timestamp invertido a hexadecimal
  const hexTimestamp = invertedTimestamp.toString(16).padStart(13, '0');
  // Generar un sufijo aleatorio para asegurar unicidad
  const randomSuffix = Math.random().toString(36).substring(2, 10); // Genera 8 caracteres
  // Combinar el timestamp invertido con el sufijo
  return `${hexTimestamp}${randomSuffix}`;
}

export default class Model {
  static collectionName;
  static db = db;

  id;
  data;

  constructor(data) {
    if (typeof data === 'string') {
      this.collectionName = data;
      return this;
    }
    this.id = data.id;
    this.data = data;
    return this;
  }

  ////////////////////////////////////////////////////////
  // Static methods
  ////////////////////////////////////////////////////////

  static extend(collectionName) {
    function extendRecursively(BaseClass) {
      return class ExtendedModel extends BaseClass {
        static collectionName = collectionName;
  
        constructor(data) {
          super(data);
        }
      };
    }
  
    return extendRecursively(this);
  }  

  static setFirestoreInstance(dbInstance) {
    this.db = dbInstance;
  }

  static getCollectionRef() {
    if (!this.db) {
      throw new Error("Firestore instance not set. Call setFirestoreInstance() before using the model.");
    }
    return collection(this.db,
      config.prefixModels 
        ? config.env + '-' + this.collectionName 
        : this.collectionName
    );
  }

  static async count() {
    const querySnapshot = await getDocs(this.getCollectionRef());
    return querySnapshot.size;
  }  

  static async findById(id) {
    const docRef = doc(this.getCollectionRef(), id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return new this({ id: docSnap.id, ...docSnap.data() });
    }
    return null;
  }

  static async findOneBy(field, value) {
    try {
      const querySnapshot = await getDocs(query(this.getCollectionRef(), where(field, '==', value)));
      if (!querySnapshot.empty) {
        const doc = querySnapshot.docs[0];
        return new this({ id: doc.id, ...doc.data() });
      }
      return null;
    } catch (error) {
      console.error(error);
    }
  }

  static async getAll() {
    const querySnapshot = await getDocs(this.getCollectionRef());
    let records = [];
    querySnapshot.forEach((doc) => {
      records.push(new this({ id: doc.id, ...doc.data() }));
    });
    return records;
  }

  static async where(fieldName, operator, value) {
    if (!value) {
      return [];
    }
    const q = query(this.getCollectionRef(), where(fieldName, operator, value));
    const querySnapshot = await getDocs(q);
    const models = [];
    querySnapshot.forEach((doc) => {
      models.push(new this({ id: doc.id, ...doc.data() }));
    });
    return models;
  }

  static async whereOne(fieldName, operator, value) {
    if (!value) {
      return null;
    }
    const records = await this.where(fieldName, operator, value);
    return records?.length ? records[0] : null;
  }

  static async create(data) {
    if (data.id) {
      return this.createWithId(data, data.id);
    }
    const timestampId = generateTimestampId();
    data.createdAt = new Date().toISOString();
    data.modifiedAt = new Date().toISOString();
    data.deleted = data.deleted || false;
    const sanitizedData = replaceUndefinedAndNaNWithNull(data);
    const docRef = doc(this.getCollectionRef(), timestampId);
    await setDoc(docRef, sanitizedData);
    const newDoc = new this({ id: docRef.id, ...sanitizedData });
    await newDoc.save();
    return newDoc;
  }
  
  static async createMany(data) {
    const docsAdded = [];
    for (const docData of data) {
      const newDoc = await this.create(docData);
      docsAdded.push(newDoc);
    }
    return docsAdded;
  }

  static async createWithId(data, id) {
    data.createdAt = new Date().toISOString();
    data.modifiedAt = new Date().toISOString();
    data.deleted = data.deleted || false;
    const docRef = doc(this.getCollectionRef(), id);
    await setDoc(docRef, data);
    return new this({ id, ...data });
  }  

  static async createOrUpdate(data) {
    if (data instanceof Model) {
      const existingModel = await this.findById(data.id);
      // update
      if (existingModel) {
        data.modifiedAt = new Date().toISOString();
        existingModel.data = { ...existingModel.data, ...data.data };
        await existingModel.save();
        return existingModel;
      } 
      // create
      else {
        // TODO test and fix
        data.createdAt = new Date().toISOString();
        data.modifiedAt = new Date().toISOString();
        data.deleted = data.deleted || false;
        await data.save();
        return data;
      }
    } else {
      const id = data?.id;
      const existingModel = id ? await this.findById(id) : null;
      // update
      if (existingModel) {
        data.modifiedAt = new Date().toISOString();
        existingModel.data = { ...existingModel.data, ...data };
        await existingModel.save();
        return existingModel;
      } 
      // create
      else {
        data.createdAt = new Date().toISOString();
        data.modifiedAt = new Date().toISOString();
        data.deleted = data.deleted || false;
        const newData = { ...data };
        if (id) {
          newData.id = id;
        }
        const newModel = await this.create(newData);
        return newModel;
      }
    }
  }

  /**
   * Filtra los registros de la colección basándose en los atributos y valores especificados.
   * @param {Object} attrValObject - Objeto que contiene los atributos y valores para filtrar los registros.
   * @param {Object} options - Opciones adicionales para la consulta.
   * @returns {Array} - Un arreglo de registros filtrados.
   *
   * @example
   * // Filtrar usuarios por edad igual a 30 y estado activo
   * const filteredUsers = await UserModel.filterByAttributes({
   *   // FilterQuery
   *   age: 30,
   *   active: true,
   *   active: "true",
   *   price: { gte: 100, lte: 500, eq: 650 },
   *   status: [ "active", "draft " ],
   *   gps: { lat: '', lng: '', distance: '' },
   * }, {
   *   // options
   *   limit: 20,
   *   startAt: 1,
   *   endAt: 100,
   *   orderBy: { field: 'createdAt', direction: 'desc' }
   * });
   */
  static async filterByAttributes(attrValObject, options) {
    const { queryRef, geoField } = this.buildQuery(attrValObject, options);
    
    const querySnapshot = await getDocs(queryRef);
    return this.processQuerySnapshot(querySnapshot, { geoField });
  }

  static async filterOne(attrValObject, options, cb) {
    const { queryRef, geoField } = this.buildQuery(attrValObject, options);
    const querySnapshot = await getDocs(queryRef);
    const records = this.processQuerySnapshot(querySnapshot, { geoField });
    if (cb) {
      cb(records);
    }
    return records?.length ? records[0] : null;
  }

  /**
   * Filtra los registros de la colección basándose en los atributos y valores especificados y retorna un Observable con el resultado.
   * @param {Object} attrValObject - Objeto que contiene los atributos y valores para filtrar los registros.
   * @param {Object} options - Opciones adicionales para la consulta.
   * @returns {} - Un Observable que emite un arreglo de registros filtrados cuando hay cambios en la colección.
   */
  static filterByAttributesOnSnapshot(attrValObject, options, cb) {
    const { queryRef, geoField } = this.buildQuery(attrValObject, options);
    return onSnapshot(queryRef, (querySnapshot) => {
      const records = this.processQuerySnapshot(querySnapshot, { geoField });
      cb(records);
    });
  }

  /**
   * Filtra los registros de la colección basándose en los atributos y valores especificados y devuelve el total de documentos.
   * @param {Object} attrValObject - Objeto que contiene los atributos y valores para filtrar los registros.
   * @param {Object} options - Opciones adicionales para la consulta.
   * @returns {number} - El total de documentos que cumplen con los criterios de filtrado.
   */
  static async filterByAttributesCount(attrValObject, options) {
    const { queryRef } = this.buildQuery(attrValObject, options);

    const querySnapshot = await getDocs(queryRef);
    return querySnapshot.size;
  }

  /**
   * Construye la consulta filtrada basada en los atributos y opciones especificados.
   * @param {Object} attrValObject - Objeto que contiene los atributos y valores para filtrar los registros.
   * @param {Object} options - Opciones adicionales para la consulta.
   * @returns {Query} - La referencia de la consulta filtrada.
   * @private
   */
  static buildQuery(attrValObject, options) {
    let queryRef = this.getCollectionRef();
    let geoField = null;

    Object.entries(attrValObject).forEach(([key, value]) => {
      if (key && value) {
        // Filtrar por valores que estén en el array
        if (Array.isArray(value)) {
          queryRef = query(queryRef, where(key, "array-contains-any", value));
        } 
        // Filtrar por valores booleanos con valor en string
        else if (value === "true" || value === "false") {
          const booleanValue = JSON.parse(value);
          queryRef = query(queryRef, where(key, "==", booleanValue));
        } 
        // Filtrar por rangos (menor o igual, mayor o igual, igual)
        else if (typeof value === "object" &&
          ("in" in value || "in-array" in value || "lte" in value || "gte" in value || "equal" in value || "ne" in value)
        ) {    
          if ("in" in value && Array.isArray(value.in)) {
            queryRef = query(queryRef, where(key, "in", value.in));
          }
          if ("in-array" in value && Array.isArray(value['in-array'])) {
            queryRef = query(queryRef, where(key, "array-contains-any", value['in-array']));
          }
          if ("lte" in value) {
            queryRef = query(queryRef, where(key, "<=", value.lte));
          }
          if ("gte" in value) {
            queryRef = query(queryRef, where(key, ">=", value.gte));
          }
          if ("equal" in value) {
            queryRef = query(queryRef, where(key, "==", value.equal));
          }
          if ("ne" in value) {
            queryRef = query(queryRef, where(key, "!=", value.ne));
          }
        } 
        // Filtrar por ubicación 
        else if (typeof value === "object" &&
          ("lat" in value && "lng" in value && "distance" in value)
        ) {    
          setGeoQuery(key, value, queryRef);
          geoField = { field: key, ...value };
        }
        // Filtrar multiselect with true-list
        else if (typeof value === "object" &&
          _.size(value)
        ) {    
          _.forEach(value, (val, field) => {
            queryRef = query(queryRef, where(key + '.' + field, "==", true));
          });
        }
        // Filtrar por igualdad exacta
        else {
          queryRef = query(queryRef, where(key, "==", value));
        }
      }
    });

    if (options?.orderBy?.field) {
      queryRef = query(queryRef, orderBy(options.orderBy.field, options.orderBy.direction || 'asc'));
    }
    else {
      queryRef = query(queryRef, orderBy(documentId(), 'asc'));
    }

    if (options?.limit) {
      queryRef = query(queryRef, limit(options.limit));
    }

    if (options?.startAt) {
      queryRef = query(queryRef, startAt(options.startAt));
    }

    if (options?.endAt) {
      queryRef = query(queryRef, endAt(options.endAt));
    }

    // TODO test and refactor
    // if (options?.startAfter) {
    //   queryRef = query(queryRef, startAfter(options.startAfter));
    // }

    return { queryRef, geoField };
  }

  /**
   * Procesa el QuerySnapshot y devuelve los registros filtrados.
   * @param {QuerySnapshot} querySnapshot - El resultado de la consulta.
   * @param {Object} options - Opciones adicionales para el procesamiento.
   * @returns {Array} - Un arreglo de registros filtrados.
   * @private
   */
  static processQuerySnapshot(querySnapshot, options) {
    const records = [];
    const geoField = options?.geoField;

    querySnapshot.forEach((doc) => {
      const newDoc = new this({ id: doc.id, ...doc.data() });

      if (geoField) {
        const { field, lat, lng, distance } = geoField;
        if (newDoc.data[field] && !newDoc.data.deleted) {
          const geofire = getGeofire();
          const center = [parseFloat(lat), parseFloat(lng)];
          const latlng = [parseFloat(newDoc.data[field].lat), parseFloat(newDoc.data[field].lng)];
          // Find cities within the specified radius of the given coordinates
          const radiusInM = parseInt(distance) * 1000;
          // Calculate the actual distance
          const distanceInKm = geofire.distanceBetween(latlng, center);
          const distanceInM = distanceInKm * 1000;
          // Check if the document is within the specified radius
          if (distanceInM <= radiusInM) {
            records.push(newDoc);
          }
        }
      } else {
        records.push(newDoc);
      }
    });

    return records;
  }

  static async saveSort(docsSorted) {
    const collectionRef = this.getCollectionRef();
    const batch = writeBatch(db);
  
    for (let i = 0; i < docsSorted.length; i++) {
      const typeId = docsSorted[i].id;
      const docRef = doc(collectionRef, typeId);
      batch.update(docRef, {
        sort: i,
        modifiedAt: new Date().toISOString()
      });
    }
  
    await batch.commit();
    return docsSorted;
  }  

  static async createManyBatch(objectsToCreate) {
    const collectionRef = this.getCollectionRef();
    const batch = writeBatch(db);
  
    for (let i = 0; i < objectsToCreate.length; i++) {
      let objectData = objectsToCreate[i];
      const docRef = doc(collectionRef);
      objectData.deleted = objectData.deleted || false;
  
      // Optionally, you can include additional fields or update existing ones
      batch.set(docRef, {
        ...objectData,
        createdAt: new Date().toISOString(),
        modifiedAt: new Date().toISOString(),
      });
    }
  
    await batch.commit();
    return objectsToCreate;
  }

  // update applying the docsData[i]
  static async updateBatch(docsData) {
    const collectionRef = this.getCollectionRef();
    const batch = writeBatch(db);
  
    for (let i = 0; i < docsData?.length; i++) {
      const typeId = docsData[i].id;
      const docRef = doc(collectionRef, typeId);
      const dataToSave = replaceUndefinedAndNaNWithNull(docsData[i]);
      batch.update(docRef, {
        ...dataToSave,
        modifiedAt: new Date().toISOString()
      });
    }
  
    await batch.commit();
    return docsData;
  }

  // update applying only the modifiers to each doc
  static async updateMany(docsDataIds, modifiers) {
    const collectionRef = this.getCollectionRef();
    const batch = writeBatch(db);
  
    for (let i = 0; i < docsDataIds.length; i++) {
      const typeId = docsDataIds[i]?.id ? docsDataIds[i].id : docsDataIds[i];
      const docRef = doc(collectionRef, typeId);
      const cleanData = replaceUndefinedAndNaNWithNull({
        ...modifiers,
        modifiedAt: new Date().toISOString()
      });
      batch.update(docRef, cleanData);
    }
  
    await batch.commit();
    return docsDataIds;
  }

  ////////////////////////////////////////////////////////
  // Instance methods
  ////////////////////////////////////////////////////////


  // update
  async save() {
    if (!this.id) {
      throw new Error('Cannot save model without an ID. Use create() for new documents.');
    }
    const docRef = doc(this.constructor.getCollectionRef(), this.id);
    this.data.modifiedAt = new Date().toISOString();
    const { data, ...cleanData } = this.data;
    await updateDoc(docRef, replaceUndefinedAndNaNWithNull(cleanData) );
  }

  async delete() {
    this.data.deleted = true;
    this.data.deletedDate = new Date().toISOString();
    await this.save();
  }

  async deleteField(fieldName) {
    const docRef = doc(this.constructor.getCollectionRef(), this.id);
    await updateDoc(docRef, { [fieldName]: deleteField() });
  }

  copy() {
    return new this.constructor({ id: this.id, ..._.cloneDeep(this.data) });
  }

  onSnapshot(callback) {
    const docRef = doc(this.constructor.getCollectionRef(), this.id);
    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        const updatedModel = new this.constructor({ id: docSnap.id, ...docSnap.data() });
        callback(updatedModel);
      } else {
        callback(null);
      }
    });
    return unsubscribe;
  }
}

const ModelClass = Model;

////////////////////////////////////////////////////////
// Hooks 
////////////////////////////////////////////////////////

export const useStateResults = (Model, query, onFetch) => {
  const [records, setRecords] = useState([]);

  useEffect(() => {
    fetchRecords();
  }, []);

  const fetchRecords = async () => {
    try {
      let docs = [];
      if (query) {
        docs = await Model.filterByAttributes(query);
      } else {
        docs = await Model.getAll();
      }
      sortDocsByField(docs, 'sort');
      if (onFetch) {
        docs = await onFetch(docs);
      }
      setRecords(docs);
    } catch (error) {
      console.log('Error fetching:', error);
    }
  };

  return records;
}

export const useStateSingleResult = ({
  Model,
  entitySlug,
  id, // fallback to nameSlug
  nameSlug,
  refreshers = []
}) => {
  const [record, setRecord] = useState(null);
  
  useEffect(() => {
    fetchRecords();
  }, [id, nameSlug, ...refreshers]);
  
  const fetchRecords = async () => {
    let ModelToUse = Model;
    if (!ModelToUse && entitySlug) {
      ModelToUse = ModelClass.extend(entitySlug);
    }
    if (!ModelToUse || !(id || nameSlug)) { return null; }
    try {
      let doc;
      if (id) {
        doc = await ModelToUse?.findById(id);
      }
      if (!doc || nameSlug) {
        doc =  await ModelToUse?.where('nameSlug', '==', nameSlug || id);
        doc = doc.filter(doc => !doc.data.deleted);
        doc = doc[0];
      }
      setRecord(doc);
    } catch (error) {
      console.log('Error fetching:', error);
    }
  };

  return record;
}

export const GetOneModel = async (collectionName, docId) => {
  // Create a dynamic extended model class using the collectionName
  const ExtendedModel = Model.extend(collectionName);

  try {
    // Find the document by its ID
    const document = await ExtendedModel.findById(docId);

    if (document) {
      return document;
    } else {
      console.log(`Document not found in collection: ${collectionName}`);
      return null;
    }
  } catch (error) {
    console.log(`Error fetching document in collection: ${collectionName}`, error);
    return null;
  }
};

function setGeoQuery(key, { lat, lng, distance }, queryRef) {
  const geofire = getGeofire();
  // Find cities within the specified radius of the given coordinates
  const center = [parseFloat(lat), parseFloat(lng)];
  const radiusInM = parseInt(distance) * 1000;
  // Calculate the geohash query bounds
  const bounds = geofire.geohashQueryBounds(center, radiusInM);
  // order by
  queryRef = query(queryRef, orderBy(key + 'Hash'));
  // Loop through the bounds and create Firestore queries
  for (const b of bounds) {
    queryRef = query(
      queryRef,
      startAt(b[0]), 
      endAt(b[1])
    );
  }
  return queryRef;
}
