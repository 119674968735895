import _ from 'lodash';
import React from 'react';
import { IonTitle } from '@ionic/react';
import { inputClasses } from '../../components/EntityTaxonomyForm';
import { EntityList } from '../entity/RouteEntityCrud';
import { withPrefix } from '../instance/utilsInstance';
import SwitchInputB from '../../components/Form/SwitchInputB';
import { SkeletonLoading } from '../../components/ui/SkeletonLoading';


const ContractsList = (props) => {
  let {
    title = "Contrataciones",
    instance,
    customerId,
    contractsDocs,
    showSelector,
    selectedIds,
    onChangeSelectedIds,
    onAfterRead,
    hardFilter,
    ExtraFields,
    EmptyContent,
    userId
  } = props;
  const fetchItems = async ({ model, setDocs }) => {
    if (contractsDocs) {
      setDocs(contractsDocs);
      return;
    }
    if (!customerId && !userId) { return null; }
    let query = {
      deleted: 'false'
    };
    if (customerId) {
      query.customerId = customerId;
    }
    if (userId) {
      query.userId = userId;
    }
    if (hardFilter) {
      query = hardFilter(query);
    }
    let docs = await model.filterByAttributes(query);
    docs = _.sortBy(docs, 'issuedDate');
    if (onAfterRead) {
      let result = await onAfterRead(docs);
      if (result) {
        docs = result;
      }
    }
    setDocs(docs);
  }

  if (!customerId && !userId) {
    return null;
  }

  const handleToggleSelect = (docId) => {
    let newSelectedIds;
    if (selectedIds.includes(docId)) {
      newSelectedIds = selectedIds.filter(id => id !== docId);
    } else {
      newSelectedIds = [...selectedIds, docId];
    }
    onChangeSelectedIds(newSelectedIds); // Notificar al componente padre sobre el cambio
  };

  return (<>
    {title ? (
      <IonTitle className={`px-0 ${inputClasses.fieldLabel}`}>
        {title}
      </IonTitle>
    ) : null}
    <EntityList
      only={['name', 'issuedDate', 'totalAmount', 'status']}
      {...props}
      entitySlug={withPrefix(instance, 'contracts')}
      fetchItems={fetchItems}
      refreshers={[customerId, userId]}
      goToForm={() => {}}
      // showEmptyMessage={false}
      showToolbar={false}
      showBtnCrud={false}
      ExtraFields={ExtraFields}
      EmptyContent={EmptyContent}
      ItemExtraActions={({ doc }) => (<>
        {showSelector ? (
          <SwitchInputB
            value={selectedIds.includes(doc.id)} // Verificar si el ID está en la lista de seleccionados
            onChange={() => handleToggleSelect(doc.id)} // Pasar el ID al hacer clic
            // textTrue="Cobrar"
            // textFalse="Pendiente"
          />
        ) : null}
      </>)}
      Loader={SkeletonLoading}
    />
  </>);
};

export default ContractsList;
