import { generateColorPalette } from "../libs/utilsColors";

/////////////////////////////////////////
// Theme specification
/////////////////////////////////////////

export default {
  /////////////////////////////////////////
  // FONTS
  /////////////////////////////////////////
  fonts: {
    mainFont: 'GT Walsheim Pro',
    mainFontAlt: 'sans-serif',
    secondaryFont: 'Lato',
    secondaryFontAlt: 'sans-serif',
  },

  /////////////////////////////////////////
  // COLORS
  /////////////////////////////////////////
  colors: {
    ...generateColorPalette({
      "primary": "#211cf4",
      "secondary": "#1ac3ff",
      "tertiary": "#9e67f7",
      "success": "#3cb371",
      "warning": "#ffd700",
      "danger": "#DC143C",
      "dark": "#062A56",
      "medium": "#5684BB",
      "light": "#F6FBFF"
    })
  },
};