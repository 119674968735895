import _ from 'lodash';
import { Link } from "react-router-dom";
import { FaWhatsapp, FaInstagram } from 'react-icons/fa';
import { AnimationAddClassWhenVisible } from "../../../components/ui/AnimationAddClassWhenVisible";
import { compileStringTemplate, lineBreaks } from "../../../libs/utils";
import { BtnList } from "../parts/PartBtnList";
import { IonIcon } from "@ionic/react";
import { mailOutline, callOutline, logoFacebook } from 'ionicons/icons';
import { urlPrefix } from '../../instance/utilsInstance';
import { sectionCardBtnsFields } from './BlockSectionCardBtns';
import { useLayoutHome } from '../../layoutHome/useLayoutHome';
import { Background } from '../parts/PartBackground';
import ViewGPSLazy from '../../../components/ViewGPSLazy';
import { getConfig } from '../../../config';


export const phoneObjectFields = {
  'useBrandPhone': {
    type: 'boolean',
    name: 'Utilizar mismo que la marca',
    defaultValue: true
  },
  'phoneNumber': {
    type: 'phone',
    name: 'Número personalizado'
  },
};

export const whatsappObjectFields = {
  ...phoneObjectFields,
  'phoneMessage': {
    type: 'textArea',
    name: 'Mensaje predeterminado'
  }
};

export const emailObjectFields = {
  'emailDirection': {
    type: 'text',
    name: 'Email'
  },
  'emailMessage': {
    type: 'textArea',
    name: 'Mensaje predeterminado'
  }
};

export default function () {
  return {
    type: 'pageFooter',
    variant: 'contactsLinksFixedCTA',
    template: PageFooterBContactsLinksFixedCta,
    presets,
    params: {
      'bgStyles': {
        name: 'Fondo',
        type: 'part',
        part: 'background'
      },
      'textsColor': {
        name: 'Colores de los textos',
        type: 'color',
        defaultValue: '#ffffff'
      },
      'socialLinksColors': {
        name: 'Colores de enlaces sociales',
        type: 'object',
        fields: {
          'borderColor': {
            type: 'color',
            name: 'Borde',
            defaultValue: '#ffffff'
          },
          'bgColor': {
            type: 'color',
            name: 'Fondo'
          },
          'textColor': {
            type: 'color',
            name: 'Texto',
            defaultValue: '#ffffff'
          }
        }
      },
      'lastbgStyles': {
        name: 'Fondo del último footer',
        type: 'part',
        part: 'background'
      },
      'lastFooterTextColor': {
        name: 'Color del texto del último footer',
        type: 'color',
        defaultValue: '#ffffff'
      }
    },
    strings: {
      'socialLinks': {
        type: 'object',
        name: 'Enlaces sociales',
        fields: {
          'socialLinkTitle': {
            type: 'text',
            name: 'Título de los enlaces'
          },
          'linkWebsiteLabel': {
            type: 'text',
            name: 'Enlace a la página: Etiqueta a mostrar'
          },
          'linkWebsite': {
            type: 'text',
            name: 'Enlace a la página: URL'
          },
          'linkFacebook': {
            type: 'text',
            name: 'Redes Sociales: Enlace a Facebook'
          },
          'linkInstagram': {
            type: 'text',
            name: 'Redes Sociales: Enlace a Instagram'
          },
        }
      },
      'phoneCall': {
        type: 'object',
        name: 'Teléfono para llamadas',
        fields: phoneObjectFields
      },
      'whatsapp': {
        type: 'object',
        name: 'Teléfono para Whatsapp',
        fields: whatsappObjectFields
      },
      'email': {
        type: 'object',
        name: 'Correo electrónico',
        fields: emailObjectFields
      },
      'address': {
        type: 'object',
        name: 'Dirección y GPS',
        fields: {
          'useBrandGps': {
            type: 'boolean',
            name: 'Ubicación a utilizar',
            textTrue: 'Utilizar ubicación del branding',
            textFalse: 'Utilizar ubicación personalizada',
            defaultValue: true
          },
          'gps': {
            type: 'gps',
            name: 'Ubicación GPS personalizada',
            accuracyType: 'exact',
            includePlace: true
          },
          'addressString': {
            type: 'textArea',
            name: 'Ubicación en texto',
            descriptionText: 'Dirección y referencias sobre como llegar'
          }
        }
      },
      'showMadeWithLove': {
        type: 'boolean',
        name: 'Mostrar hecho con amor desde',
        defaultValue: true
      },
      'madeWithLoveLabel': {
        type: 'text',
        name: 'Hecho con amor: etiqueta para mostrar a la derecha'
      },
      'showPoliticsLinks': {
        type: 'boolean',
        name: 'Mostrar enlaces de póliticas',
        defaultValue: true,
        descriptionText: 'Políticas de privacidad y Seguridad de datos'
      },
      'showPanelLink': {
        type: 'boolean',
        name: 'Mostrar acceso para usuarios',
        defaultValue: true,
        descriptionText: 'Enlace al panel de administración'
      },
      'btnList': {
        type: 'collection',
        name: 'Botones',
        fields: sectionCardBtnsFields
      }
    },
    partsMap: [
      {
        'label': 'Primer Bloque',
        'background': ['bgStyles'],
      },
      {
        'label': 'Enlaces Sociales',
        'strings': ['socialLinks'],
      },
      {
        'label': 'Teléfono',
        'strings': ['phoneCall'],
      },
      {
        'label': 'Whatsapp',
        'strings': ['whatsapp'],
      },
      {
        'label': 'Email',
        'strings': ['email'],
      },
      {
        'label': 'Dirección',
        'strings': ['address'],
      },
      {
        'label': 'Último Bloque',
        'background': ['lastbgStyles'],
      },
      {
        'label': 'Hecho con Amor',
        'strings': ['showMadeWithLove', 'madeWithLoveLabel'],
      },
      {
        'label': 'Enlaces de Políticas',
        'strings': ['showPoliticsLinks'],
      },
      {
        'label': 'Acceso de Usuarios',
        'strings': ['showPanelLink'],
      },
      {
        'label': 'Botones Fijos',
        'strings': ['btnList'],
      },
    ]
  };
}

export function PageFooterBContactsLinksFixedCta(props) {
  let { 
    bgStyles, 
    lastbgStyles,
    showPanelLink, showPoliticsLinks,
    btnList,
    textsColor,
    lastFooterTextColor,
    socialLinks = {},
    phoneCall,
    whatsapp,
    email,
    address,
    socialLinksColors,
    showMadeWithLove,
    madeWithLoveLabel,
    instance, specDesign, history 
  } = props;
  let { socialLinkTitle, linkWebsite, linkWebsiteLabel, linkFacebook, linkInstagram } = socialLinks;

  const { offsetBottom } = useLayoutHome();

  let panelUrl = instance ? (instance !== 'main' ? `/${instance}/login` : '/login') : '/login';
  
  let classes = {
    ctaBtn: `
      w-12 h-12 pt-2.5
      text-brand-primary-contrast text-2xl text-center cursor-pointer rounded-full
      border-2
      hover:scale-110 transition-all duration-300
    `
  };

  linkFacebook = compileStringTemplate(linkFacebook, specDesign.stringsVars);
  linkFacebook = _.startsWith(linkFacebook, 'http') ? linkFacebook : (linkFacebook ? 'https://www.facebook.com/' + linkFacebook : null);
  linkInstagram = compileStringTemplate(linkInstagram, specDesign.stringsVars);
  linkInstagram = _.startsWith(linkInstagram, 'http') ? linkInstagram : (linkInstagram ? 'https://www.instagram.com/' + linkInstagram : null);

  let emailToUse = compileStringTemplate(email?.emailDirection, specDesign.stringsVars);
  let phoneCallNumber = phoneCall?.useBrandPhone ? specDesign.stringsVars?.brand?.callNumber : phoneCall?.phoneNumber;
  let whatsappNumber = whatsapp?.useBrandPhone ? specDesign.stringsVars?.brand?.whatsappNumber : whatsapp?.phoneNumber;

  let colorsMap = props.colors || specDesign.colors || getConfig().colors || {};
  const getColorHex = (val) => {
    return val?.hex || colorsMap[val] || val;
  };
  let colorText = getColorHex(textsColor) || getColorHex('dark-contrast');
  let colorTextLastFooter = getColorHex(lastFooterTextColor) || getColorHex('dark-contrast');

  let socialLinksBorderColor = getColorHex(socialLinksColors?.borderColor) || colorText;
  let socialLinksBgColor = getColorHex(socialLinksColors?.bgColor);
  let socialLinksTextColor = getColorHex(socialLinksColors?.textColor) || colorText;

  return (
    <Background
      params={bgStyles}
      specDesign={specDesign}
      instance={instance}
      classes={{ bgContainer: "snap-start" }} 
      classDefault="bg-brand-dark"
    >
      <div className=" py-10 md:py-16" >
        {/* address */}
        <div className="container-width grid grid-flow-row grid-cols-1 md:grid-cols-2 gap-4">
          {/* links */}
          {/* cta y social links */}
          {(_.size(socialLinks) || address?.addressString) ? (
            <AnimationAddClassWhenVisible classToAdd="animate-fadeIn">
              <div className="px-8 md:px-0 mb-8 md:mb-0">
                {/* cta titles */}
                {socialLinkTitle ? (
                  <h3 className="mb-2 font-semibold text-4xl md:text-5xl cursor-pointer font-brand-main rounded-sm" style={{ color: colorText }}>
                    {lineBreaks(compileStringTemplate(socialLinkTitle, specDesign.stringsVars))}
                  </h3>
                ) : null}
                {/* social links */}
                {linkFacebook || linkInstagram || whatsappNumber ? (
                  <div className="flex flex-wrap place-content-start gap-2 mt-4">
                    {linkFacebook ? (
                      <div className={classes.ctaBtn} style={{ borderColor: socialLinksBorderColor, backgroundColor: socialLinksBgColor }}>
                        <a className="inline-block" href={linkFacebook} target='_blank' rel="noopener noreferrer" style={{ color: socialLinksTextColor || colorText }}>
                          <IonIcon icon={logoFacebook} />
                        </a>
                      </div>
                    ) : null}
                    {linkInstagram ? (
                      <div className={classes.ctaBtn} style={{ borderColor: socialLinksBorderColor, backgroundColor: socialLinksBgColor }}>
                        <a className="inline-block" href={linkInstagram} target='_blank' rel="noopener noreferrer" style={{ color: socialLinksTextColor || colorText }}>
                          <FaInstagram />
                        </a>
                      </div>
                    ) : null}
                    {whatsappNumber ? (
                      <div className={classes.ctaBtn} style={{ borderColor: socialLinksBorderColor, backgroundColor: socialLinksBgColor }}>
                        {/* TODO utilizar useBrandPhone */}
                        <a className="inline-block" href={`https://wa.me/${whatsappNumber}?text=${whatsapp.phoneMessage}`} target='_blank' rel="noopener noreferrer" style={{ color: socialLinksTextColor || colorText }}>
                          <FaWhatsapp />
                        </a>
                      </div>
                    ) : null}
                    {emailToUse ? (
                      <div className={classes.ctaBtn} style={{ borderColor: socialLinksBorderColor, backgroundColor: socialLinksBgColor }}>
                        {/* TODO utilizar useBrandEmail */}
                        <a className="inline-block" href={`mailto:${emailToUse}?subject=${compileStringTemplate(email.emailMessage, specDesign.stringsVars)}`} target="_blank" rel="noopener noreferrer" style={{ color: socialLinksTextColor || colorText }}>
                          <IonIcon icon={mailOutline} />
                        </a>
                      </div>
                    ) : null}
                    {phoneCallNumber ? (
                      <div className={classes.ctaBtn} style={{ borderColor: socialLinksBorderColor, backgroundColor: socialLinksBgColor }}>
                        {/* TODO utilizar useBrandEmail */}
                        <a className="inline-block" href={`tel:+${phoneCallNumber}`} target="_blank" rel="noopener noreferrer" style={{ color: socialLinksTextColor || colorText }}>
                          <IonIcon icon={callOutline} />
                        </a>
                      </div>
                    ) : null}
                  </div>
                ) : null}
                <div className="pt-12 text-base" style={{ color: colorText }}>
                  {linkWebsite ? (
                    <a href={linkWebsite} className="mb-2 block font-semibold text-lg md:text-xl cursor-pointer font-brand-mulish rounded-sm">
                      {lineBreaks(compileStringTemplate(linkWebsiteLabel, specDesign.stringsVars)) || linkWebsite}
                    </a>
                  ) : null}
                  {address?.addressString ? (
                    <p className="italic">{lineBreaks(compileStringTemplate(address.addressString, specDesign.stringsVars))}</p>
                  ) : null}
                  {whatsappNumber ? (
                    <p className="opacity-70">
                      Whatsapp: 
                      <a className="ml-2 border-b border-dashed border-brand-dark-contrast cursor-pointer" href={`https://wa.me/${whatsappNumber}?text=${whatsapp.phoneMessage}`} target='_blank' rel="noopener noreferrer">
                        +{whatsappNumber}
                      </a>
                    </p>
                  ) : null}
                  {phoneCallNumber ? (
                    <p className="opacity-70">
                      Llamadas:
                      <a className="ml-2 border-b border-dashed border-brand-dark-contrast cursor-pointer" href={`tel:+${phoneCallNumber}`} target='_blank' rel="noopener noreferrer">
                        +{phoneCallNumber}
                      </a>
                    </p>
                  ) : null}
                  {emailToUse ? (
                    <p className="opacity-70">
                      Correo: 
                      <a className="ml-2 border-b border-dashed border-brand-dark-contrast cursor-pointer" href={`mailto:${emailToUse}?subject=${compileStringTemplate(email.emailMessage, specDesign.stringsVars)}`} target='_blank' rel="noopener noreferrer">
                        {emailToUse}
                      </a>
                    </p>
                  ) : null}
                </div>
              </div>
            </AnimationAddClassWhenVisible>
          ) : null}

          {/* map */}
          {(address?.useBrandGps 
              ? specDesign.stringsVars?.brand?.defaultGps 
              : address?.gps
            ) ? (
              <div className="px-8 md:px-0">
                <ViewGPSLazy
                  value={address.useBrandGps ? specDesign.stringsVars?.brand?.defaultGps : address.gps}
                  includePlace={true}
                  mapZoom={15}
                  classes={{
                    mapHeight: 'h-[300px]'
                  }}
                />
              </div>
            ) : null
          }
        </div>
      </div>

      {/* last menu */}
      {(showPanelLink || showPoliticsLinks || showMadeWithLove) ? (
        <Background
          params={lastbgStyles}
          specDesign={specDesign}
          instance={instance}
          classDefault="bg-brand-dark-tint"
        >
          <div className="text-center px-4 py-8 md:py-8 pb-20" style={{ color: colorTextLastFooter }}>
            {/* <div className="mb-6 text-center">
              <a href="/" className="px-4 py-2 rounded-full shadow-md bg-brand-dark-shade">
                <img
                  src={getConfig().mainSpecDesign.logo.logoLight}
                  alt={getConfig().mainSpecDesign.stringsVars.brand.siteName}
                  className="h-6 inline-block"
                />
              </a>
            </div> */}
            {showMadeWithLove ? (
              <div className="mb-8 px-2">
                <div className="flex justify-center flex-nowrap w-full">
                  Hecho con
                  <img src="/icons/heart.svg" alt="amor" className="beating mx-1"/>
                  {madeWithLoveLabel}
                </div>
                <div className="mt-1">
                  <span className="mr-1.5">Potenciado por</span>
                  <a href="/" className="underline">
                    {_.capitalize(getConfig().domain)}
                  </a>
                </div>
              </div>
            ) : (
              <div className="mb-8 px-2">
                <div className="">
                  <span className="mr-1.5">Potenciado por</span>
                  <a href="/" className="underline">
                    {_.capitalize(getConfig().domain)}
                  </a>
                </div>
              </div>
            )}
            {showPoliticsLinks ? (<>
              <Link to={urlPrefix(instance, '/m/p/privacy-policy')} className="underline">Política de privacidad</Link>
                <span className=""> | </span>
              <Link to={urlPrefix(instance, '/m/p/data-security')} className="underline">Seguridad de datos</Link>
            </>) : null}
            {(showPanelLink && showPoliticsLinks) ? (
              <span className=""> | </span>
            ) : null}
            {showPanelLink ? (<>
              <Link to={panelUrl} className="underline">Administración</Link>
            </>) : null}
          </div>
        </Background>
      ) : null}

      {/* fixed footer */}
      {btnList?.length ? (
        <div className="fixed bottom-4 right-4 md:right-6 min-w-40 pr-2 z-[350] flex place-content-end" style={{ paddingBottom: offsetBottom }}>
          <BtnList
            specDesign={specDesign}
            strings={{ items: btnList }}
            params={{
              type: 'row', 
              gap: '4',
              // align
              // justify
            }}
            classes={{ btnListContainer: 'mt-8 w-fit' }}
            history={history}
            instance={instance}
          />
        </div>
      ) : null}
    </Background>
  );
}

const presets = {
  'base': { // Define your 'base' preset here
    'bgStyles': [
      {
        "bgColor": "#222222", // Example: Dark gray background
        "screen": "xxs",
        "bgVideoUrl": "",
        "bgOverlayAlpha": 0,
        "type": "color"
      }
    ],
    'textsColor': '#ffffff', // Example: White text color
    'socialLinksColors': {
      'borderColor': '#ffffff', // Example: White border for social links
      'bgColor': 'transparent', // Example: Transparent background for social links
      'textColor': '#ffffff'  // Example: White text color for social links
    },
    'lastbgStyles': [
      {
        "bgColor": "#111111", // Example: Darker gray for the last footer section
        "screen": "xxs",
        "bgVideoUrl": "",
        "bgOverlayAlpha": 0,
        "type": "color"
      }
    ],
    'lastFooterTextColor': '#D2D2D2', // Example: Light gray text color for the last footer
    // ... add default values for other parameters in your 'base' preset
  },

  'base-details': {
    "bgStyles": [
      {
        "bgColor": "primary-shade",
        "bgOverlayAlpha": 0,
        "screen": "xxs",
        "type": "color",
        "bgVideoUrl": ""
      }
    ],
    'socialLinksColors': {
      'borderColor': 'secondary',
      'bgColor': 'null',
      'textColor': 'secondary'
    },
    "lastbgStyles": [
      {
        "screen": "xxs",
        "bgVideoUrl": "",
        "bgOverlayAlpha": 0,
        "bgColor": "secondary-tint",
        "type": "color"
      }
    ],
    "textsColor": null,
    'lastFooterTextColor': 'primary-shade',
  }
};