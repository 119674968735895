import _ from "lodash";
import { Carousel } from "react-responsive-carousel";
import { AnimationAddClassWhenVisible } from "../../../components/ui/AnimationAddClassWhenVisible";
import { compileStringTemplate, lineBreaks } from "../../../libs/utils";
import { Background } from "../parts/PartBackground";
import { getImageURL, stackClasses } from "../../../libs/utils";
import { cardContainerStylesField } from "../parts/stylesFields";
import { CardContainer } from "../parts/PartCardContainer";
import { Image } from "../parts/PartImage";
import { Text } from "../parts/PartText";
import { Card } from "../parts/PartCard";
import { BtnsList, sectionCardBtnsFields } from "./BlockSectionCardBtns";
import { useState } from "react";

export default function () {
  return {
    type: "image",
    variant: "imageWithContent",
    template: CarouselWithContent,
    presets,
    params: {
      imageFirst: {
        name: "Imagen primero",
        type: "boolean",
        defaultValue: true,
      },
      bgStyles: {
        name: "Fondo",
        type: "part",
        part: "background",
      },
      cardContainerStyles: cardContainerStylesField,
      titleStyles: {
        name: "Título",
        descriptionText: "Estilos según tamaño de pantalla",
        type: "part",
        part: "text",
      },
      titleCardStyles: {
        name: "Tarjeta del Título",
        descriptionText: "Estilos según tamaño de pantalla",
        type: "part",
        part: "card",
      },
      subtitleStyles: {
        name: "Subtítulo",
        descriptionText: "Estilos según tamaño de pantalla",
        type: "part",
        part: "text",
      },
      subtitleCardStyles: {
        name: "Tarjeta del Subtítulo",
        descriptionText: "Estilos según tamaño de pantalla",
        type: "part",
        part: "card",
      },
      carouselCardStyles: {
        name: "Tarjeta de Imagen",
        descriptionText: "Estilos según tamaño de pantalla",
        type: "part",
        part: "card",
      },
      contentStyles: {
        name: "Contenido",
        descriptionText: "Estilos según tamaño de pantalla",
        type: "part",
        part: "text",
      },
      contentCardStyles: {
        name: "Tarjeta del Contenido",
        descriptionText: "Estilos según tamaño de pantalla",
        type: "part",
        part: "card",
      },
      btnListStyles: {
        name: "Botones",
        descriptionText: "Estilos según tamaño de pantalla",
        type: "part",
        part: "card",
      },
    },
    // fields of text tab
    strings: {
      items: {
        type: "collection",
        name: "Items del Carrusel",
        fields: {
          images: {
            type: "gallery",
            name: "Imagenes",
            descriptionText: "Se utilizara la primera imagen de la lista",
          },
          title: {
            type: "textArea",
            name: "Título",
          },
          subtitle: {
            type: "textArea",
            name: "Subtítulo",
          },
        },
      },
      title: {
        type: "textArea",
        name: "Título",
      },
      subtitle: {
        type: "textArea",
        name: "Subtítulo",
      },
      content: {
        type: "textArea",
        name: "Contenido",
      },
      btnList: {
        type: "collection",
        name: "Botones",
        fields: sectionCardBtnsFields,
      },
    },
    partsMap: [
      {
        label: "Bloque",
        background: ["bgStyles"],
        card: ["cardContainerStyles"],
      },
      {
        label: "Carrusel",
        strings: ["items"],
        card: ["carouselCardStyles"],
      },
      {
        label: "Título",
        strings: ["title"],
        text: ["titleStyles"],
        card: ["titleCardStyles"],
      },
      {
        label: "Subtítulo",
        strings: ["subtitle"],
        text: ["subtitleStyles"],
        card: ["subtitleCardStyles"],
      },
      {
        label: "Contenido",
        strings: ["content"],
        text: ["contentStyles"],
        card: ["contentCardStyles"],
      },
      {
        label: "Botones",
        strings: ["btnList"],
        card: ["btnListStyles"],
      },
    ],
  };
}

const imgUrl = (imageSrc) => {
  let url = _.isArray(imageSrc) ? imageSrc[0] : imageSrc;
  url = _.startsWith(url, "http") ? url : getImageURL(url, "xl", null);

  return url;
};

const ItemCarrousel = ({ items, design = "square", className }) => {
  const [expandedImage, setExpandedImage] = useState(null);
  let classes = {};
  if (design === "square") {
    classes.img =
      "h-[450px] sm:h-[450px] lg:h-[450px] object-cover md:object-top";
  }
  if (design === "horizontal") {
    classes.img =
      "h-[300px] sm:h-[300px] lg:h-[400px] object-cover md:object-top";
  }

  if (!items?.length) {
    return null;
  }

  const handleExpand = (imageUrl) => {
    setExpandedImage(imageUrl);
  };

  const handleClose = () => {
    setExpandedImage(null);
  };

  return (
    <>
      <Carousel
        showThumbs={false}
        showStatus={false}
        infiniteLoop={true}
        autoPlay={true}
        className={className}
      >
        {items?.map((item, index) => (
          <div key={index} className="relative">
            <img
              src={imgUrl(item.images)}
              alt={item.title}
              className={classes.img}
            />
            <button
              onClick={() => handleExpand(imgUrl(item.images))}
              className="absolute top-6 right-6 bg-black bg-opacity-50 hover:bg-opacity-70 text-white p-2 rounded-full transition-all duration-300"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15"
                />
              </svg>
            </button>
            <div className="absolute bottom-4 sm:bottom-1 w-full mx-auto md:space-y-3 pb-4 xl:pb-12">
              <h3 className="text-brand-primary-contrast font-brand-main px-4 text-2xl drop-shadow-md">
                {lineBreaks(item.title)}
              </h3>
              <p className="text-white font-brand-main px-4 text-lg drop-shadow-md">
                {lineBreaks(item.subtitle)}
              </p>
            </div>
          </div>
        ))}
      </Carousel>

      {/* Modal para imagen expandida */}
      {expandedImage && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-90"
          onClick={handleClose}
        >
          <div className="relative max-w-[90vw] max-h-[90vh]">
            <img
              src={expandedImage}
              alt="Expanded view"
              className="max-w-full max-h-[90vh] object-contain"
            />
            <button
              onClick={handleClose}
              className="absolute top-6 right-6 bg-black bg-opacity-50 hover:bg-opacity-70 text-white p-2 rounded-full transition-all duration-300"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export function CarouselWithContent(props) {
  let {
    items,
    title,
    subtitle,
    content,
    bgStyles,
    specDesign,
    titleStyles,
    titleCardStyles,
    subtitleStyles,
    subtitleCardStyles,
    contentStyles,
    contentCardStyles,
    cardContainerStyles,
    carouselCardStyles,
    imageFirst,
    btnListStyles,
    btnList,
    history,
    instance,
  } = props;

  return (
    <Background
      params={bgStyles}
      classes={{ bgContainer: "snap-start" }}
      specDesign={specDesign}
    >
      <CardContainer params={cardContainerStyles} specDesign={specDesign}>
        <div className="">
          <div className="flex flex-col md:flex-row lg:items-center gap-4 sm:gap-8">
            {/* Carousel para móviles - siempre visible arriba en móvil */}
            <div className="md:hidden w-full">
              <Card params={carouselCardStyles} specDesign={specDesign}>
                {items?.length ? (
                  <ItemCarrousel
                    items={items}
                    design="horizontal"
                    className="rounded-lg overflow-hidden shadow-lg"
                  />
                ) : null}
              </Card>
            </div>

            {/* Contenido y carousel para desktop */}
            <div
              className={`hidden md:block md:basis-1/2 ${
                !imageFirst ? "md:order-last" : ""
              }`}
            >
              <Card params={carouselCardStyles} specDesign={specDesign}>
                {items?.length ? (
                  <ItemCarrousel
                    items={items}
                    design="horizontal"
                    className="rounded-lg overflow-hidden shadow-lg"
                  />
                ) : null}
              </Card>
            </div>

            <Card
              params={contentCardStyles}
              specDesign={specDesign}
              classes={{
                cardBgContainer:
                  "md:basis-1/2 content-center text-left lg:text-base text-sm lg:text-start",
              }}
            >
              {title ? (
                <AnimationAddClassWhenVisible
                  classToAdd="transition-opacity"
                  classDefault="duration-1000 delay-[200ms]"
                >
                  <Card params={titleCardStyles} specDesign={specDesign}>
                    <Text
                      textParams={titleStyles}
                      classDefault="text-black font-bold font-brand-main text-3xl lg:text-[42px] xl:text-[43px] 2xl:text-[45px] leading-tight"
                      specDesign={specDesign}
                    >
                      {lineBreaks(
                        compileStringTemplate(title, specDesign.stringsVars)
                      )}
                    </Text>
                  </Card>
                </AnimationAddClassWhenVisible>
              ) : null}
              {subtitle ? (
                <AnimationAddClassWhenVisible
                  classToAdd="transition-opacity"
                  classDefault="duration-1000 delay-[500ms]"
                >
                  <Card params={subtitleCardStyles} specDesign={specDesign}>
                    <Text
                      textParams={subtitleStyles}
                      classDefault="pb-4 text-gray-400 font-bold font-brand-main text-3xl lg:text-[42px] xl:text-[43px] 2xl:text-[45px] leading-tight"
                      specDesign={specDesign}
                    >
                      {lineBreaks(
                        compileStringTemplate(subtitle, specDesign.stringsVars)
                      )}
                    </Text>
                  </Card>
                </AnimationAddClassWhenVisible>
              ) : null}
              {content ? (
                <AnimationAddClassWhenVisible
                  classToAdd="transition-opacity"
                  classDefault="duration-1000 delay-[500ms]"
                >
                  <Text
                    textParams={contentStyles}
                    classDefault="pb-6 font-brand-secondary text-xl md:text-[22px] 2xl:text-2xl leading-relaxed"
                    specDesign={specDesign}
                  >
                    {lineBreaks(
                      compileStringTemplate(content, specDesign.stringsVars)
                    )}
                  </Text>
                </AnimationAddClassWhenVisible>
              ) : null}
              {btnList?.length ? (
                <Card params={btnListStyles} specDesign={specDesign}>
                  <AnimationAddClassWhenVisible
                    classToAdd="transition-opacity"
                    classDefault="duration-1000 delay-[700ms]"
                  >
                    <div>
                      <BtnsList
                        items={btnList}
                        specDesign={specDesign}
                        history={history}
                        instance={instance}
                      />
                    </div>
                  </AnimationAddClassWhenVisible>
                </Card>
              ) : null}
            </Card>
          </div>
        </div>
      </CardContainer>
    </Background>
  );
}

const presets = {
  base: {
    bgStyles: [
      {
        bgStyles: "#ffffff",
        screen: "xxs",
        bgVideoUrl: "",
        bgOverlayAlpha: 0,
        type: "color",
      },
    ],
    cardContainerStyles: [
      {
        screen: "xxs",
        marginX: 4,
        marginY: 24,
      },
      {
        screen: "md",
        marginX: 8,
        marginY: 32,
      },
      {
        screen: "lg",
        marginY: 40,
        marginX: 20,
      },
    ],
    titleStyles: [
      {
        textSize: "3xl",
        fontVariations: ["bold"],
        textAlign: "left",
        screen: "xxs",
        textColor: "#000000",
        font: "main",
      },
      {
        textSize: "4xl",
        screen: "md",
      },
      {
        textSize: "5xl",
        screen: "2xl",
      },
    ],
    subtitleStyles: [
      {
        textSize: "2xl",
        fontVariations: ["bold"],
        textAlign: "left",
        screen: "xxs",
        textColor: "#9CA3AF",
        font: "secondary",
      },
      {
        textSize: "3xl",
        screen: "md",
      },
      {
        textSize: "4xl",
        screen: "lg",
      },
    ],
    contentStyles: [
      {
        textSize: "base",
        textAlign: "left",
        screen: "xxs",
        textColor: "#000000",
        font: "main",
      },
      {
        textSize: "md",
        screen: "md",
      },
      {
        textSize: "lg",
        screen: "xl",
      },
    ],
  },

  "base-details": {
    bgStyles: [
      {
        bgColor: "primary-shade",
        bgVideoUrl: "",
        type: "color",
        bgOverlayAlpha: 0,
        screen: "xxs",
      },
    ],
    contentStyles: [
      {
        textAlign: "left",
        screen: "xxs",
        textColor: "primary-contrast",
        fontVariations: ["normal"],
        font: "secondary",
        textSize: "base",
      },
    ],
    subtitleStyles: [
      {
        screen: "xxs",
        textColor: "secondary",
        textSize: "base",
        fontVariations: ["bold", "italic"],
        textAlign: "left",
        font: "secondary",
      },
      {
        screen: "md",
        textSize: "base",
      },
      {
        screen: "lg",
        textSize: "base",
      },
    ],
    subtitleCardStyles: [
      {
        align: "left",
        marginY: 2,
        marginX: "none",
        screen: "xxs",
      },
    ],
    cardContainerStyles: [
      {
        marginX: 12,
        marginY: 24,
        screen: "xxs",
      },
      {
        marginX: 12,
        screen: "md",
        marginY: 32,
      },
      {
        marginX: 20,
        screen: "lg",
        marginY: 40,
      },
    ],
    titleStyles: [
      {
        screen: "xxs",
        textAlign: "left",
        textSize: "3xl",
        textColor: "primary-contrast",
        font: "main",
        fontVariations: ["bold"],
      },
      {
        textSize: "4xl",
        screen: "md",
      },
      {
        screen: "2xl",
        textSize: "5xl",
      },
    ],
  },
};
