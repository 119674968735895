import _, { set } from 'lodash';
import { RawInputObjectForm } from '../../../components/Form';
import { useEffect, useMemo, useRef, useState } from 'react';
import { withPrefix } from '../../instance/utilsInstance';
import { ObjectFormWrapped } from '../../../components/Form/RawInputObjectForm';
import { IonButton, IonIcon, IonLoading, useIonLoading } from '@ionic/react';
import { menuOutline } from 'ionicons/icons';
import { DocCard, viewCardInlineClasses } from '../../entity/docCardsVariants';
import EntityGetOneDocument from '../../../components/EntityGetOneDocument';
import { getConfig } from '../../../config';
import { useEntityFullBySlug } from '../../entity/Entity';
import { RouteEntityCrudForm } from '../../entity/RouteEntityCrudForm';


const RenderForm = (props) => {
  const { instance, value, onChange, getData } = props;
  const [ invoiceData, setInvoiceData ] = useState(null);
  const [ isModalOpen, setIsModalOpen ] = useState(false);
  const [present] = useIonLoading();
  let entitySlug = withPrefix(instance, getConfig().modules.sales.invoicesEntitySlug)
 
  const assignInvoiceData = (savedDoc) => {
    console.log('assign', savedDoc)
    onChange(savedDoc);
    setInvoiceData(savedDoc?.data);
    setIsModalOpen(false);
  };

  const openModal = () => {
    let invoiceData = getData();
    setInvoiceData(invoiceData);
    setIsModalOpen(true);
    present({
      duration: 1500
    });
  };

  const onCloseForm = () => {
    setIsModalOpen(false);
  };
  
  return (
    <div className="">
      {/* Modal con formulario de datos invoices */}
      {/* entity form */}
      {isModalOpen ? (
        <RouteEntityCrudForm
          context={{
            entitySlug,
            docId: 'new',
            asObject: true,
            asModal: true,
            isModalOpen,
            onAfterSave: assignInvoiceData,
            onClose: onCloseForm,
            defaultValue: invoiceData
          }}
          classes={{
            loadingContainer: "!hidden"
          }}
        />
      ) : null}

      {/* triggers btns */}
      <IonButton onClick={() => openModal()} size="small" fill={'outline'} color={'primary'}>
        <IonIcon icon={menuOutline} slot="start" size="small"></IonIcon>
        <span className="text-xs">Datos invoices</span>
      </IonButton>
    </div>
  );
};

const RenderShow = (props) => {
  const { displayedValue, instance } = props;

  return (
    <div className="">
      <EntityGetOneDocument
      entitySlug={withPrefix(instance, getConfig().modules.sales.invoicesEntitySlug)}
      docId={displayedValue}>
        {(entitySpecs) => (
          <DocCard
            {...props}
            doc={entitySpecs.doc}
            entitySpecs={entitySpecs}
            outstandingOnly={true}
            design="flat"
            classes={viewCardInlineClasses}
          />
        )}
      </EntityGetOneDocument>
    </div>
  );
};

const dataTypeInvoiceForCreditCollection = {
  RenderForm,
  RenderShow,
  RenderShowList: RenderShow
};

export default dataTypeInvoiceForCreditCollection;
