import { useState, useEffect } from 'react';
import Model from '../../libs/ModelClass';

export default class TaxonomyType extends Model {
  static collectionName = 'taxonomyTypes';

  constructor(data) {
    super(data);
  }

  static async findByEntityId(entityId) {
    const types = await this.where('entityId', '==', entityId);
    types.sort((a, b) => a.data.sort - b.data.sort);
    return types;
  }

  static async findByNameSlug(nameSlug) {
    const types = await this.where('nameSlug', '==', nameSlug);
    types.sort((a, b) => a.data.sort - b.data.sort);
    return types;
  }
}

export const useTaxonomyTypes = ({ entityId, nameSlug, onFetch }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, [entityId, nameSlug]);

  /**
   * Obtiene los tipos de taxonomía disponibles y los almacena en el estado local.
   */
  const fetchData = async () => {
    try {
      let types = [];
      if (entityId) {
        types = await TaxonomyType.findByEntityId(entityId);
      } else if (nameSlug) {
        types = await TaxonomyType.findByNameSlug(nameSlug);
      }
      onFetch && onFetch(types);
      setData(types);
    } catch (error) {
      console.log('Error fetching taxonomy types:', error);
    }
  };

  return { 
    entityTaxonomyTypes: data
  };
};
