import _ from 'lodash';
import React from 'react';
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonItem, IonCheckbox, IonBadge, IonList, IonAccordion, IonLabel, IonButton, IonButtons, IonAccordionGroup } from '@ionic/react';
import dayjs from 'dayjs';
import { useCreditCollectionsStore } from './useCreditCollectionsStore';
import { inputClasses } from '../../../components/EntityTaxonomyForm';
import { getAmountNum } from '../../../components/Form/utilsCurrency';
import { usePanel } from '../../panel/usePanel';
import { getConfig } from '../../../config';
import { withPrefix } from '../../instance/utilsInstance';
import ObjectShow from '../../entity/ObjectShow';
import { viewRowClasses } from '../../entity/docCardsVariants';
import { paymentFields } from '../PaymentsList';

const PaymentsByContractSelector = ({ title, contractsDocs }) => {
  const { instance } = usePanel();
  const { paymentsByContract, selectedPaymentsIds, setSelectedPaymentsIds } = useCreditCollectionsStore();
  const paymentsEntitySlug = withPrefix(instance, getConfig().modules.sales.paymentsEntitySlug);

  const handleSelectPayment = (paymentId) => {
    const isSelected = selectedPaymentsIds.includes(paymentId);
    const updatedSelectedIds = isSelected
      ? selectedPaymentsIds.filter((id) => id !== paymentId)
      : [...selectedPaymentsIds, paymentId];
    setSelectedPaymentsIds(updatedSelectedIds);
  };

  return (
    <div className="mt-6">
      {contractsDocs.map((contractDoc) => (
        paymentsByContract[contractDoc.data.id]?.length && (
          <IonCard key={contractDoc.id} className="mb-4 mx-0 px-0">
            <IonCardHeader>
              <span className={inputClasses.fieldLabel}>
                {title || 'Pagos'}
              </span>
            </IonCardHeader>
            <IonCardContent>
              <IonList>
                {paymentsByContract[contractDoc.data.id]?.map((paymentDoc) => (
                  <IonItem key={paymentDoc.id} lines="none">
                    {paymentDoc.data.status === 'pending' && (
                      <IonButtons className="self-start">
                        <IonButton 
                          onClick={() => handleSelectPayment(paymentDoc.id)}
                          color="medium"
                          shape="round"
                          size="small"
                          fill="clear"
                        >
                          <IonCheckbox
                            slot="icon-only"
                            checked={selectedPaymentsIds.includes(paymentDoc.id)}
                          />
                        </IonButton>
                      </IonButtons>
                    )}
                    <div className="pl-1 py-2 space-y-1">
                      <div>
                        <span className={`${inputClasses.fieldLabel} !inline mr-2`}>
                          Pago
                        </span> {paymentDoc.data.num}
                        <div className="inline relative !ml-2 top-1 space-x-1.5">
                          {paymentDoc.data.status === 'punitive' && (
                            <IonBadge slot="end" color="danger">Vencido</IonBadge>
                          )}
                          {paymentDoc.data.status === 'duedate' && (
                            <IonBadge slot="end" color="danger">Vencido</IonBadge>
                          )}
                          {paymentDoc.data.status === 'pending' && (
                            <IonBadge slot="end" color="warning">Pendiente</IonBadge>
                          )}
                          {paymentDoc.data.status === 'paid' && (
                            <IonBadge slot="end" color="success">Pagado</IonBadge>
                          )}
                        </div>
                      </div>

                      <ObjectShow
                        instance={instance}
                        entitySlug={paymentsEntitySlug}
                        value={paymentDoc}
                        fields={_.pick(paymentFields, ['amount', 'dueDate'])}
                        design="flat"
                        classes={viewRowClasses}
                      />

                      {/* show more */}
                      {/* Implementa un Ion accordion que al presionar muestre el detalle del pago con ObjectShow */}
                      <IonAccordionGroup size="small">
                        <IonAccordion size="small">
                          <div slot="header" className="text-left text-brand-medium font-medium text-xs">
                            Detalle del pago
                          </div>
                          <div className="" slot="content">
                            <ObjectShow
                              instance={instance}
                              entitySlug={paymentsEntitySlug}
                              value={paymentDoc}
                              fields={_.omit(paymentFields, ['num', 'amount', 'dueDate'])}
                              design="flat"
                              classes={viewRowClasses}
                            />
                          </div>
                        </IonAccordion>
                      </IonAccordionGroup>

                    </div>
                  </IonItem>
                ))}
              </IonList>
            </IonCardContent>
          </IonCard>
        )
      ))}
    </div>
  );
};

export default PaymentsByContractSelector;
