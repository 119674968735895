import { Fragment, memo } from "react";
import { EntityDocListView } from "../../components/EntityDocView";
import useBreakpoints from "../../libs/useBreakpoints";
import { getImageURL } from "../../libs/utils";


export const ShowCategoryFields = (props) => {
  let { item, fieldsDocs, instance, entitySlug, classes } = props;
  return (
    fieldsDocs ? (
      <EntityDocListView
        doc={item}
        instance={instance}
        entitySlug={entitySlug}
        taxonomyTypesDocList={fieldsDocs}
        outstandingOnly={false}
        viewType="show"
        classes={classes}
        render={({ doc, ViewData, mainAttr, mainImgAttr, classes }) => (
          fieldsDocs.map((taxonomyType) => (
            taxonomyType.data.nameSlug !== 'variantImgs' && taxonomyType.data.outstanding ? (
              <ViewData
                key={taxonomyType.data.nameSlug}
                field={taxonomyType.data.nameSlug}
                classes={{
                  fieldContainer: `text-xs font-normal mb-1.5`,
                  fieldLabel: '!block mb-1.5'
                }}
              />
            ) : null
          ))
        )}
      />
    ) : null
  );
};

const CartItemThumbGrid = (props) => {
  let {
    items,
    entitySpecs,
    instance,
    history,
    onShow,
    classes = {},
    renderAsGrid = true,
    categoryFieldsById,
    getFieldsDocsByIdsList,
    imgProportion = 'vertical-square'
  } = props;
  const { isMinBreakpointActive } = useBreakpoints();
  const { entitySlug } = entitySpecs;

  const renderItems = (items) => (
    items.map((item) => {
      let fieldsDocs = getFieldsDocsByIdsList(item.data['itemCategory_fieldsIds']);

      return (
        <Fragment key={item.id}>
          <EntityDocListView
            {...entitySpecs}
            doc={item}
            classes={{ fieldLabel: "hidden" }}
            render={({ ViewData, mainAttr, mainImgAttr, classes }) => (
              <button 
                type="button"
                onClick={() => {
                  onShow(item);
                }}
                className="
                  h-full flex-1 flex flex-col border border-gray-300 rounded-md overflow-hidden 
                  shadow-md hover:shadow-xl
                  transition-all duration-200 ease-in-out
                  text-left
                "
              >
                {/* image xs */}
                <img 
                  className={`${imgProportion} w-full object-cover`} 
                  src={item?.data?.variantImgs?.length && getImageURL(item.data.variantImgs[0], (isMinBreakpointActive('md') ? 'md' : 'xs'))} 
                /> 
                {/* data */}
                <div className="bg-white pb-1 w-full">
                  <div className="my-4 px-4 flex flex-row flex-wrap gap-2 md:gap-4">
                    <div className="w-full">
                      <ViewData
                        field="name"
                        classes={{ fieldContainer: 'md:text-md lg:text-lg font-semibold leading-tight' }}
                      />
                      <ViewData
                        field="price"
                        classes={{ fieldContainer: 'text-sm md:text-base text-gray-500 my-1 lg:my-0', fieldValueItemCurrencyFlag: '!hidden' }}
                      />
                    </div>
                    
                    <hr className="w-full my-1 md:my-0" />
                    {/* 
                      show data of categoryFieldsDoc
                    */}
                    <ShowCategoryFields
                      item={item}
                      fieldsDocs={fieldsDocs}
                      instance={instance}
                      entitySlug={entitySlug}
                      classes={classes}
                    />
                  </div>
                </div>
              </button>
            )}
          />
        </Fragment>
      );
    })
  );

  return (
    items ? (
      renderAsGrid ? (
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4 md:gap-5 items-start">
          {renderItems(items)}
        </div>
      ) : (
        renderItems(items)
      )
    ) : null
  );
};

export default CartItemThumbGrid;

export const CartItemThumbGridMemo = memo(CartItemThumbGrid, (prevProps, nextProps) => {
  return prevProps.items === nextProps.items;
});