import _ from 'lodash';
import { assignDeep, stackClasses } from "../../libs/utils";
import { getPrefix, withOutPrefix, withPrefix } from '../../modules/instance/utilsInstance';


export const parseEntitySlug = (entitySlug, instance) => {
  return getPrefix(entitySlug) === 'instance' ? withPrefix(instance, withOutPrefix(entitySlug)) : entitySlug;
};

export const processRenderProps = (props) => {
  let { 
    fieldName,
    taxonomyType,
    taxonomyTypeDoc,
    values,
    // onChange, // TODO remove, debe ser onAfterChange, onChange esta reservado para RawInputs debido a que los dataTypes utilizan input.onChange del form
    fieldsRequired,
    paramsByFieldSlug,
    isFilter
  } = props;

  const taxonomyTypeData = taxonomyType?.data || taxonomyTypeDoc?.data || taxonomyType;
  const title = taxonomyTypeData?.name;
  const _fieldName = fieldName || taxonomyTypeData?.fieldName || taxonomyTypeData?.nameSlug;
  
  let overrideParams = { ...props.overrideParams };
  if (paramsByFieldSlug && paramsByFieldSlug[_fieldName]) {
    overrideParams = _.clone(overrideParams);
    overrideParams = assignDeep(overrideParams, paramsByFieldSlug[_fieldName]);
  }

  const param = {
    ...taxonomyTypeData,
    ...taxonomyTypeData?.param,
    ...taxonomyTypeData?.style,
    ...overrideParams,
    title,
    fieldsRequired,
    disabled: isFilter ? false : (overrideParams?.disabled || taxonomyTypeData?.readOnly),
    show: taxonomyTypeData?.show
  };
  let style = param?.style || {};
  const design = props?.design || style?.design || param?.design || 'default';
  param.classes = stackClasses(props?.classes, style?.classes, taxonomyTypeData?.classes);
  param.instance = props.instance || taxonomyTypeData?.instance;
  let entitySlug = props?.entitySlug;
  entitySlug = parseEntitySlug(entitySlug, param.instance);
  // to select documents
  param.entitySlug = param?.entityNameSlug || param?.entitySlug;
  param.entitySlug = parseEntitySlug(param.entitySlug, param.instance);
  // fallback, used as filter
  entitySlug = entitySlug || param.entitySlug;

  return {
    entitySlug,
    taxonomyTypeData,
    title,
    _fieldName,
    param,
    style,
    design
  }
};

export const getOptionLabel = (data, fieldName, invoiceTaxonomyTypes) => {
  if (!data || !fieldName || !invoiceTaxonomyTypes?.length) {
    return null;
  }
  const taxonomyTypeDoc = invoiceTaxonomyTypes.find((taxonomyType) => taxonomyType.data.nameSlug === fieldName);
  const value = data[fieldName];
  if (!taxonomyTypeDoc) {
    return data[fieldName];
  }
  const option = taxonomyTypeDoc.data.param?.options.find((option) => option.value === value);
  return option ? option.label : null;
};