import { useForm } from 'react-final-form';
import { useCreditCollectionsStore } from './useCreditCollectionsStore';
import { inputClasses } from '../../../components/EntityTaxonomyForm';
import { ShowAmount } from '../../../components/Form/utilsCurrency';
import ContractsListAccordion from '../contracts/ContractsListAccordion';

const ColumnRight = ({ instance }) => {
  const form = useForm();
  const {
    contractsOfContact,
    selectedPaymentsIds
  } = useCreditCollectionsStore();

  return (
    <div className="space-y-6">
      {/* Lista de contratos del contacto */}
      {contractsOfContact?.length ? (
        <ContractsListAccordion
          title="Contratos del Contacto"
          contractsDocs={contractsOfContact}
        />
      ) : null}
    </div>
  );
};

export default ColumnRight;
