import _ from 'lodash';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { LayoutAdmin } from "../panel/LayoutAdmin";
import SectionCrudForm from "../../components/SectionCrudForm";
import Model, { useStateSingleResult } from "../../libs/ModelClass";
import useEntityTaxonomyForm, { inputClasses } from '../../components/EntityTaxonomyForm';
import PartOfModule, { usePartOfModule } from '../../components/Module/PartOfModule';
import { useModule } from '../../libs/ModuleContext';
import toast from 'react-hot-toast';
import BadgeLoading from '../../components/ui/BadgeLoading';
import { usePanel } from '../panel/usePanel';
import { useCanAddMoreDocs } from './MaxCountLimitAlert';
import { useModuleSettings } from './useModuleSettings';
import { useReadDocById } from './useReadDocById';
import { withProvider } from '../../libs/utils';


export const columnRightClasses = {
  container: '',
  firstCol: 'md:basis-1/2',
  secondCol: 'md:basis-1/2'
};

const formContext = createContext();

export const useFormContext = () => {
  const context = useContext(formContext);
  if (!context) throw new Error("There is no Panel provider");
  return context;
};

export function FormContextProvider(props) {
  let { children } = props;
  const [ defaultFieldsRequired, setFieldsRequired ] = useState([]);
  const [ fieldsRequiredMap, setFieldsRequiredMap ] = useState({}); // { [key: string]: [string, string] }
  const [ fieldsRequired, setFieldsRequiredInner ] = useState([]);

  useEffect(() => {
    setFieldsRequiredInner([
      ...defaultFieldsRequired,
      ...Object.values(fieldsRequiredMap).flat()
    ]);
    return () => {
      setFieldsRequiredInner([ ...defaultFieldsRequired ]);
    };
  }, [defaultFieldsRequired, fieldsRequiredMap]);

  return (
    <formContext.Provider
      value={{
        fieldsRequired,
        defaultFieldsRequired,
        setFieldsRequired,
        fieldsRequiredMap,
        setFieldsRequiredMap
      }}
    >
      {children}
    </formContext.Provider>
  );
}

function SectionFormWrapper(props) {
  let {
    instance,
    entitySlug,
    entityDoc,
    doc,
    docId,
    history,
    match,
    defaultValue,
    asModal,
    onClose,
    handleSave,
    FormInputFields,
    canAddMoreDocs,
    taxonomyTypesDocList,
    // onFormChange,
    fireEventReducer,
    isAllowed
  } = props;
  const { defaultFieldsRequired, fieldsRequired, setFieldsRequired, fieldsRequiredMap, setFieldsRequiredMap } = useFormContext();
  const { moduleSettings } = useModuleSettings({ entitySlug });

  const allFields = [
    ...defaultFieldsRequired,
    ...Object.values(fieldsRequiredMap).flat()
  ];

  const onValidation = (values, errors) => {
    // all required fields
    allFields.forEach(field => {
      if (!values[field]) {
        errors[field] = ' '; 
      }
    });
    // [TODO] realiar validación de taxonomías según tipo y param
  };

  // fire event onFormChange
  let onFormChange = async (values, previousValues, form, doc) => {
    props.onFormChange?.(props);
    await fireEventReducer('onFormChange', { doc, formValues: values, previousValues, form, instance, entitySlug, entityDoc, taxonomyTypesDocList }, values);
  };  

  return (
    <>
      {/* Entity Form */}
      {doc ? (<>
        <SectionCrudForm
          {...props}
          instance={instance}
          entitySlug={entitySlug}
          entityDoc={entityDoc}
          doc={doc}
          onSave={handleSave}
          onValidation={onValidation}
          onFormChange={onFormChange}
          fieldsRequired={allFields}
          FormInputFields={FormInputFields}
          editStyle={asModal ? 'modal' : "route"}
          showTitle={true}
          showSaveButton={moduleSettings?.showBtnSave === false ? false : (docId === 'new' ? canAddMoreDocs?.canAddMoreDocs : true)}
          defaultValue={defaultValue}
          onClose={onClose}
        />
        <PartOfModule
          type="crud"
          action="UnderForm"
          entitySlug={entitySlug}
          param={{ doc, instance, entityDoc, entitySlug, taxonomyTypesDocList, isAllowed, history, match }} 
        />
      </>) : null}
    </>
  );
};

function EntityCrudFormRaw(props) {
  let {
    context,
    entityDoc,
    history,
    match,
    attachPrefix,
    defaultValue,
    asModal,
    asObject,
    classes = {}
  } = props;
  
  const entitySlug = props.entitySlug || context?.entitySlug || entityDoc?.data?.nameSlug || match.params.entitySlug;
  const docId = context?.docId || props.docId || match.params.docId;
  const isEdit = docId !== 'new';
  const ExtendedModel = Model.extend(entitySlug);
  const { fireEventReducer, isAllowed } = useModule();
  const { selectedInstance } = usePanel();
  const instanceDoc = context?.instanceDoc || selectedInstance;
  const instance = props.instance || instanceDoc?.data?.hash || 'main';
  const { moduleSettings } = useModuleSettings({ entitySlug });
  const { fieldsRequired, setFieldsRequired, fieldsRequiredMap, setFieldsRequiredMap } = useFormContext();
  const { taxonomyTypesDocList, EntityTaxonomyForm } = useEntityTaxonomyForm({ 
    instance,
    entityId: entityDoc?.id,
    entitySlug,
    fieldsRequired, 
    setFieldsRequired
  });
  const canAddMoreDocs = useCanAddMoreDocs(entitySlug, moduleSettings, selectedInstance);
  const { doc, fireAfterRead } = useReadDocById({ doc: props.doc, docId, defaultValue, instance, instanceDoc, entitySlug, entityDoc, taxonomyTypesDocList });
  let FormAppendColumnRight = usePartOfModule({
    type: "crud",
    action: "FormAppendColumnRight",
    entitySlug,
    // param: { values: formProps.values, doc, instance, entityDoc, entitySlug, taxonomyTypesDocList, isAllowed }
  });
  const showColumnRight = FormAppendColumnRight || moduleSettings.columnRightTaxonomyTypesSlugs?.length;
  const onClose = context?.onClose || props.onClose;

  const handleSave = async (formValues) => {
    try {
      let modifiedFormValues = await fireEventReducer('beforeSave', { doc, isEdit, instance, entitySlug, taxonomyTypesDocList, instanceDoc, entityDoc }, formValues);
      // assign instanceId for global entities
      if (instanceDoc && !entityDoc?.data.isInstance && instanceDoc?.data.hash !== 'main') {
        modifiedFormValues.instanceId = instanceDoc.id;
      }
      if (context?.onBeforeSave) {
        await context.onBeforeSave(modifiedFormValues);
      }
      let savedItem = doc.copy();
      savedItem.data = modifiedFormValues;
      if (!asObject) {
        savedItem = await ExtendedModel.createOrUpdate(modifiedFormValues);
        await fireEventReducer('afterSave', { doc: savedItem, formValues, modifiedFormValues, isEdit, instance, entitySlug, taxonomyTypesDocList, instanceDoc, entityDoc }, savedItem);
      }
      if (context?.onAfterSave) {
        return context?.onAfterSave(savedItem);
      }
      if (!asObject && moduleSettings.redirectOnSave === true) {
        history.replace(attachPrefix(`/a/entity/${entityDoc?.data?.nameSlug}/${savedItem.id}`));
      }
      if (!asObject && moduleSettings.redirectOnSave === 'form') {
        // only for new
        if (!isEdit) {
          // go to form route
          history.replace(attachPrefix(`/a/entity/${entityDoc?.data?.nameSlug}/${savedItem.id}/form`));
          // force rerender
          history.push(attachPrefix(`/a/entity/${entityDoc?.data?.nameSlug}/${savedItem.id}/form`));
        } 
      }
      else {
        fireAfterRead(savedItem, true);
      }
    } catch (error) {
      console.error(error);
      toast.error(error?.message || error?.code);
    }
  };

  const FormInputFields = ((formProps) => (<>
    <PartOfModule
      type="crud"
      action="FormPrepend"
      entitySlug={entitySlug}
      param={{ values: formProps.values, doc, instance, entityDoc, entitySlug, taxonomyTypesDocList, isAllowed, history, match, formApi: formProps.form, fieldsRequired, setFieldsRequired, fieldsRequiredMap, setFieldsRequiredMap }} 
    />

    <div className={`${FormAppendColumnRight?.classes?.container || ''} ${showColumnRight ? `flex flex-col md:flex-row gap-2 md:gap-4` : ''}`}>
      <div className={FormAppendColumnRight?.classes?.firstCol || ''}>
        <EntityTaxonomyForm
          {...props}
          {...formProps}
          {...{fieldsRequired, setFieldsRequired, fieldsRequiredMap, setFieldsRequiredMap}}
          classes={{
            ...classes,
            ...inputClasses
          }}
          overrideParams={{
            showLabel: true,
            showClearBtn: true,
          }}
          entitySlug={entitySlug}
        />
      </div>
      {showColumnRight ? (
        <div className={FormAppendColumnRight?.classes?.secondCol || ''}>
          {FormAppendColumnRight?.Render ? (
            <FormAppendColumnRight.Render {...{ values: formProps.values, doc, instance, entityDoc, entitySlug, taxonomyTypesDocList, isAllowed, history, match, formApi: formProps.form }} /> 
          ) : null}
          { moduleSettings.columnRightTaxonomyTypesSlugs?.length ? (
            <EntityTaxonomyForm
              {...props}
              {...formProps}
              {...{fieldsRequired, setFieldsRequired, fieldsRequiredMap, setFieldsRequiredMap}}
              classes={{
                ...classes,
                ...inputClasses
              }}
              overrideParams={{
                showLabel: true,
                showClearBtn: true,
              }}
              entitySlug={entitySlug}
            />
          ) : null}
        </div>
      ) : null }
    </div>
    
    <PartOfModule
      type="crud"
      action="FormAppend"
      entitySlug={entitySlug}
      param={{ values: formProps.values, doc, instance, entityDoc, entitySlug, taxonomyTypesDocList, isAllowed, history, match, formApi: formProps.form, fieldsRequired, setFieldsRequired, fieldsRequiredMap, setFieldsRequiredMap }} 
    />
  </>));

  const Content = useMemo(() => {
    return () => (
      doc ? (
        <SectionFormWrapper {...{ instance, entitySlug, entityDoc, doc, docId, history, match, defaultValue, asModal, onClose, handleSave, FormInputFields, canAddMoreDocs, taxonomyTypesDocList, fireEventReducer, isAllowed }} />
      ) : null
    );
  }, [docId, doc, canAddMoreDocs?.canAddMoreDocs, taxonomyTypesDocList]);

  if (!doc) {
    return (
      <div className={`pt-32 flex place-content-center content-center items-center font-brand-main ${classes?.loadingContainer}`}>
        <BadgeLoading className="text-brand-dark" />
      </div>
    );
  }

  return <Content />;
}

export const EntityCrudForm = withProvider(EntityCrudFormRaw, FormContextProvider);

export function RouteEntityCrudForm(props) {
  let {
    context,
    withLayout = true,
    match,
    history,
    location,
    showInstanceSelector = true,
    TitleToolbarRight,
    selectedInstance,
    classes,
    ContentRender
  } = props;
  const panelLibs = usePanel();
  const { attachPrefix } = panelLibs;
  const entitySlug = props.entitySlug || context?.entitySlug || match.params.entitySlug;
  const docId = context?.docId || props.docId || match.params.docId;
  const entityDoc = useStateSingleResult({
    Model: Model.extend('entities'), 
    nameSlug: entitySlug
  });
  const selectedInner = useMemo(() => (
    selectedInstance || panelLibs?.selectedInstance
  ), [selectedInstance, panelLibs?.selectedInstance]);
  
  // initial value
  let defaultValue = context?.defaultValue || props.defaultValue;

  // presenting style
  let asModal = context?.asModal || props.asModal;
  let asObject = props?.asObject || context?.asObject;

  withLayout = withLayout && !asModal;

  const listUrl = attachPrefix('/a/entity/' + entityDoc?.data.nameSlug + '/list');
  const breadcrumbs = context?.breadcrumbs || [
    {
      url: listUrl,
      title: entityDoc?.data?.name
    },
    {
      title: "Formulario"
    }
  ];

  // attach instanceId to global entities
  if (!entityDoc?.data.isInstance && selectedInner?.data.hash !== 'main') {
    defaultValue = {
      instanceId: selectedInner?.id,
      ...(defaultValue || {})
    };
  }

  ContentRender = ContentRender || context?.ContentRender || EntityCrudForm;

  // if (!entityDoc?.id || !selectedInner?.id) {
  //   return (
  //     <div className={`pt-32 flex place-content-center content-center items-center font-brand-main ${classes?.loadingContainer}`}>
  //       <BadgeLoading className="text-brand-dark" />
  //     </div>
  //   );
  // }

  const content = entityDoc && (
    <ContentRender
      {...props}
      entityDoc={entityDoc}
      attachPrefix={attachPrefix}
      docId={docId}
      defaultValue={defaultValue}
      asModal={asModal}
      asObject={asObject}
    />
  );

  if (!withLayout) {
    return content;
  }

  return (
    <LayoutAdmin 
      history={history} 
      defaultHref={context?.defaultBackRoute || listUrl}
      breadcrumbs={breadcrumbs}
      TitleToolbarRight={TitleToolbarRight}
    >
      {content}
    </LayoutAdmin>
  );
}
