import _ from 'lodash';
import EntityGetOneDocument from './EntityGetOneDocument';
import Entity, { useEntityFullBySlug } from '../modules/entity/Entity';
import { stackClasses, getImageURL, numberFormat } from '../libs/utils';
import PartOfModule from './Module/PartOfModule';
import { ShowRelatedListMaterials } from './Form/RawInputRelatedListMaterials';
import { useAuth } from '../modules/user/AuthContext';
import config from '../config';
import { Link } from 'react-router-dom';
import { useModule } from '../libs/ModuleContext';
import dayjs from 'dayjs';
import { FieldViewSelectButtons } from './Form/FormFieldSelectButtons';
import { FieldViewSelectButtonsWithColors } from './Form/FormFieldSelectButtonsWithColors';
import { getPrefix, urlPrefix } from '../modules/instance/utilsInstance';
import { ShowAmount } from './Form/utilsCurrency';
import { useCallback, useMemo } from 'react';
import NotionShowLazy from './ui/NotionShowLazy';
import MarkdownLazy from './ui/MarkdownLazy';
import SliderGalleryLazy from './ui/SliderGalleryLazy';
import ViewGPSLazy from './ViewGPSLazy';
import { ViewSelectedDocuments } from './Form/RawInputSelectDocumentExtended';


const ViewRelatedDoc = ({ nameSlug, doc, entitySlug, classes, instance, isAllowed, DecoratorRender, FieldLabel, param, taxonomyType }) => {
  const rendered = useMemo(() => {
    const normalizedFieldPrefix = _.replace(taxonomyType.data.nameSlug, 'Id', '_');

    return (
      param?.fromJson ? (
        <div className={classes?.fieldContainer}>
          <FieldLabel />
          <div className={classes?.fieldValue}>
            <span className="">{doc.data[normalizedFieldPrefix + 'mainAttr']}</span>
          </div>
        </div>
      ) : (
        <EntityGetOneDocument
          key={nameSlug}
          entitySlug={entitySlug}
          docId={doc.data[ nameSlug ]}
        >
          {({ doc, mainAttr, mainImgAttr }) => {
            return (<>
              <div className={classes?.fieldContainer}>
                <FieldLabel />
                <div className={classes?.fieldValue}>
                  {DecoratorRender ? (
                    <DecoratorRender doc={doc} />
                  ) : (
                    <>
                      {(isAllowed && isAllowed(entitySlug, ['list'])) ? (
                        <Link to={urlPrefix(instance, `/a/entity/${entitySlug}/${doc?.id}`)} className="border-b border-black">
                          {doc.data[mainAttr]}
                        </Link>
                      ) : (
                        <span className="">{doc.data[mainAttr]}</span>
                      )}
                    </>
                  )}
                  {doc.data.deleted ? (<span className="px-1 py-0.5 ml-2 text-xs text-red-600 bg-red-100 rounded-full">eliminado</span>) : null}
                </div>
              </div>
            </>);
          }}
        </EntityGetOneDocument>
      )
    );
  }, [nameSlug, doc.data[nameSlug]]);

  return (
    rendered
  );
};

const getComponent = (props) => {
  let {
    value,
    instance,
    userAuth,
    entitySlug,
    doc,
    field,
    viewType,
    classes,
    parts,
    decorator,
    DecoratorRender,
    outstandingOnly,
    outstandingExclude,
    force,
    taxonomyTypesDocList,
    isAllowed,
    only,
    exclude
  } = props;
  
  if (!doc) return null;
  
  instance = instance || getPrefix(entitySlug) || 'main';
  
  if (field === 'mainAttr') {
    let mainAttr = Entity.getMainAttr(taxonomyTypesDocList);
    field = mainAttr;
  }
  if (field === 'mainImgAttr') {
    let mainImgAttr = Entity.getMainImgAttr(taxonomyTypesDocList);
    field = mainImgAttr;
  }
  
  const taxonomyType = taxonomyTypesDocList?.find(taxonomyType => taxonomyType.data?.nameSlug === field);
  
  if (!taxonomyType) {
    return null;
  }
  
  // Show conditions
  if (taxonomyType.data.deleted || taxonomyType.data.hiddeInViews) { return null; }
  if (only?.length && !only?.includes(taxonomyType.data.nameSlug)) { return null; }
  if (exclude?.length && exclude?.includes(taxonomyType.data.nameSlug)) { return null; }

  if (userAuth?.rolesDoc?.data.nameSlug !== config.modules.user.userTopRoleSlug) {
    let omit = false;
    // restrict by owners only
    if (
      taxonomyType.data.forOwner
      && !(
        userAuth?.userDoc?.id === doc?.data?.owner
        || userAuth?.userDoc?.id === doc?.data?.userId
      )
    ) {
      omit = true;
    }
    
    // restrict by moderators only
    if (
      taxonomyType.data.forMainModerator
      && userAuth?.userDoc && userAuth?.rolesDoc
      // && userAuth.rolesDoc?.data.nameSlug !== config.modules.user.userTopRoleSlug
    ) {
      omit = true;
    }
    if (omit) {
      return null;
    }
  }

  const { type, name, nameSlug, outstanding, param, style } = taxonomyType.data;

  const valueToUse = value || doc.data[nameSlug];

  const displayedValue = decorator ? decorator(valueToUse) : getDisplayedValue(
    valueToUse,
    type,
    taxonomyType
  );

  const FieldLabel = () => (
    <span className={`pr-2 font-semibold ${classes?.fieldLabel}`}>
      {name}
    </span>
  );

  // outstanding only
  if (!force && outstandingOnly && !outstanding && !only && !exclude) {
    // Si se requieren solo elementos destacados y este no es destacado, no mostrarlo
    return null;
  }
  // oustanding exclude
  if (outstandingExclude && outstanding) {
    return null;
  }
  if (type === 'gps') {
    let params = { ...taxonomyType.data.param };
    if (taxonomyType.data.param?.accuracyType === 'zone') {
      params.accuracyLevel = params.accuracyLevel - 1;
    }
    return displayedValue ? (
      <div key={nameSlug} className={classes?.fieldContainer}>
        <FieldLabel />
        <div className={classes?.fieldValue}>
          <ViewGPSLazy
            value={displayedValue}
            classes={classes}
            {...params}
          />
        </div>
      </div>
    ) : null;
  }

  if (type === 'gallery') {
    if (!displayedValue || !_.isArray(displayedValue)) {
      return null;
    }
    const slidersData = displayedValue.map(filePath => {
      return {
        key: filePath,
        title: filePath,
        imageUrl: getImageURL(filePath, taxonomyType.data.param?.doResize ? 'xl' : null, taxonomyType.data.param?.folder, instance),
        thumbUrl: getImageURL(filePath, taxonomyType.data.param?.doResize ? 'xs' : null, taxonomyType.data.param?.folder, instance)
      }
    });

    let designToUse = viewType === 'list' ? taxonomyType.data.style?.designList : taxonomyType.data.style?.designShow;

    const TemplateSlider = () => {
      return (taxonomyType.data.param?.imgProportion === 'vertical-wide') ? (
        <SliderGalleryLazy
          slidersData={slidersData}
          // showSliderAtInit={showSliderAtInit}
          // gridPlace={gridPlace}
          heightProportion={1.5}
          showGrid={slidersData.length > 1}
          gridClass={designToUse === 'sliders-xl' ? 'images-grid xs vertical' : 'images-grid xxs vertical'}
          thumbBtnClass="image-card"
        >
          {({ InnerSlider, Grid }) => (
            <div className="flex flex-row w-full">
              <div className="pr-1 w-4/5">
                {InnerSlider}
              </div>
              <div className="pl-1 w-1/5">
                {Grid}
              </div>
            </div>
          )}
        </SliderGalleryLazy>
      ) : (
        <SliderGalleryLazy
          slidersData={slidersData}
          // showSliderAtInit={showSliderAtInit}
          // gridPlace={gridPlace}
          heightProportion={1}
          showGrid={slidersData.length > 1}
          gridClass={designToUse === 'sliders-xl' ? 'images-grid xs mt-3' : 'images-grid xxs mt-3'}
          thumbBtnClass="image-card">
        </SliderGalleryLazy>
      );
    };

    const TemplateGrid = () => {
      return (
        <div className={`grid grid-flow-row ${designToUse === 'grid-xs' ? 'grid-cols-2 lg:grid-cols-3' : 'grid-cols-1 lg:grid-cols-2'} gap-xs my-2`}>
          {_.map(slidersData, ({ key, title, imageUrl }, index) => (
            // thumb
            <div key={key}
              className=""
            >
              <img src={imageUrl} alt={title} />
            </div>
          ))}
        </div>
      );
    };

    return displayedValue ? (
      slidersData && slidersData.length ? (
        <div key={nameSlug} className={classes?.fieldContainer}>
          <FieldLabel />
          <div className={`${taxonomyType.data.style?.classToApply || ''} ${classes?.fieldValue}`}>
            {_.includes(designToUse, 'sliders') ? (
              <TemplateSlider />
            ) : (
              <TemplateGrid />
            )}
          </div>
        </div>
      ) : null
    ) : null;
  }

  if (type === 'selectOneEntityDocument') {
    const entitySlug = param?.entitySlug || param?.entityNameSlug; // retrocompatible

    return displayedValue ? (
      <ViewRelatedDoc
        {...{
          entitySlug,
          nameSlug,
          doc,
          classes,
          instance,
          isAllowed,
          DecoratorRender,
          FieldLabel,
          param,
          taxonomyType
        }}
      />
    ) : null;
  }

  if (type === 'selectManyEntityDocument') {
    const entitySlug = param?.entitySlug || param?.entityNameSlug; // retrocompatible

    return displayedValue ? (
      <div className={classes?.fieldContainer}>
        <FieldLabel />
        <div className={classes?.fieldValue}>
          <ViewSelectedDocuments
            {...props}
            instance={instance}
            entitySlug={entitySlug}
            value={displayedValue}
            multiple={true}
          />
        </div>
      </div>
    ) : null;
  }

  if (type === 'textArea') {
    // TODO: agregar parser de markdown al mostrar, botones WYSIWYG para markdown en FormFieldTextArea
    return displayedValue ? (
      <div key={nameSlug} className={classes?.fieldContainer}>
        <FieldLabel />
        <div className={classes?.fieldValue}>
          {style?.design === 'simple' || style?.design === 'default' ? (
            displayedValue
          ) : null}
          {style?.design === 'richMarkdown' ? (
            <div className="prose lg:prose-sm">
              <MarkdownLazy>{displayedValue}</MarkdownLazy>
            </div>
          ) : null}
          {style?.design === 'richWYSIWYG' ? (
            displayedValue
          ) : null}
          {style?.design === 'notion' ? (
            <div className="">
              <NotionShowLazy value={displayedValue} />
            </div>
          ) : null}
        </div>
      </div>
    ) : null;
  }

  if (type === 'coded') {
    const RenderShow = taxonomyType.data?.RenderShow || taxonomyType?.RenderShow;
    return displayedValue ? (
      <div key={nameSlug} className={classes?.fieldContainer}>
        <FieldLabel />
        <div className={classes?.fieldValue}>
          {RenderShow ? (
            <RenderShow {...{ displayedValue, doc, field, entitySlug, taxonomyTypeData: taxonomyType.data }} />
          ) : (
            <PartOfModule
              type="codedDataType"
              fieldName={nameSlug}
              action={viewType === 'list' ? 'RenderShowList' : 'RenderShow'}
              entitySlug={entitySlug}
              param={{ displayedValue, doc, field, instance, entitySlug, taxonomyTypeData: taxonomyType.data }} 
            />
          )}
        </div>
      </div>
    ) : null;
  }

  if (type === 'relatedListMaterials') {
    return displayedValue ? (
      <div key={nameSlug} className={classes?.fieldContainer}>
        <FieldLabel />
        <div className={classes?.fieldValue}>
          <ShowRelatedListMaterials relatedListId={displayedValue} />
        </div>
      </div>
    ) : null;
  }

  if (type === 'boolean') {
    return (<div key={nameSlug} className={classes?.fieldContainer}>
      <FieldLabel />
      <div className={classes?.fieldValue}>
        {!!doc?.data[nameSlug] ? (
          <span className={classes?.fieldValueTrue || 'text-gray-900'}>{param?.textTrue}</span>
        ) : (
          <span className={classes?.fieldValueFalse || 'text-gray-400'}>{param?.textFalse}</span>
        )}
      </div>
    </div>);
  }

  if (type === 'select' || type === 'multiselect') {
    return displayedValue ? (
      <div key={nameSlug} className={classes?.fieldContainer}>
        <FieldLabel />
        <div className={classes?.fieldValue}>
          {style?.design === 'buttons' || style?.design === 'select' || style?.design === 'default' || !style?.design ? (
            <FieldViewSelectButtons 
              {...{ taxonomyType, value: displayedValue, classes, parts }}
              showLabel={false}
              classes={{
                fieldViewSelectedOption: '',
                fieldViewUnselectedOption: 'hidden',
              }}
            />
          ) : null}
          {style?.design === 'buttonsColors' ? (
            <FieldViewSelectButtonsWithColors 
              {...{ taxonomyType, value: displayedValue, classes, parts }}
              showLabel={false}

            />
          ) : null}
        </div>
      </div>
    ) : null;
  }

  if (type === 'list') {
    return displayedValue ? (
      <div key={nameSlug} className={classes?.fieldContainer}>
        <FieldLabel />
        <div className={classes?.fieldValue}>
          {displayedValue?.map((item, index) => (
            <div className={classes?.fieldValueItem || ''} key={index}>
              <div className={classes?.fieldValueItemLabel}>
                {item.label}
              </div>
              {taxonomyType.data.param.typesToShow?.timeRange ? (
                <div className={classes?.fieldValueItemTimeRange}>
                  {item.timeRange.start} - {item.timeRange.end}
                </div>
              ) : null}
              {taxonomyType.data.param.typesToShow?.amount ? (
                <div className={classes?.fieldValueItemCurrency}>
                  <span className="">{item.amount?.qty}</span>
                  <span className="">{item.amount?.country?.symbol}</span>
                  <span className={`react-tel-input pl-1 space-x-1 text-xs ${classes?.fieldValueItemCurrencyFlag}`}>
                    <div className={`flag inline-block -mt-1 ${item.amount?.country?.iso2?.toLowerCase()}`}></div>
                    <span className="">{item.amount?.country?.code}</span>
                  </span>
                </div>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    ) : null;
  }

  if (type === 'numberAmount') {
    if (!displayedValue?.qty || !displayedValue?.country) {
      return null;
    }
    return displayedValue ? (
      <div key={nameSlug} className={classes?.fieldContainer}>
        <FieldLabel />
        <div className={classes?.fieldValue}>
          <ShowAmount amount={displayedValue} classes={{ currencyFlag: classes.fieldValueItemCurrencyFlag }} />
        </div>
      </div>
    ) : null;
  }

  if (type === 'selectCity') {
    return doc.data[nameSlug + 'Labels']?.length ? (
      <div key={nameSlug} className={classes?.fieldContainer}>
        <FieldLabel />
        <div className={classes?.fieldValue}>
          {doc.data[nameSlug + 'Labels'].reverse().join(', ')}
        </div>
      </div>
    ) : null;
  }

  if (type === 'color') {
    const colorsMap = config?.colors || {};
  
    const getColorHex = (val) => {
      return val?.hex || colorsMap[val] || val;
    };

    return valueToUse ? (
      <div key={nameSlug} className={`${classes?.fieldContainer}`}>
        <FieldLabel />
        <div className={`${classes?.fieldValue}`}>
          <div
            className={`rounded-full inline-block w-6 h-6 top-1 mr-1`}
            style={{
              backgroundColor: getColorHex(valueToUse)
            }}
          />
          <span>{valueToUse}</span>
        </div>
      </div>
    ) : null;
  }

  if (type === 'collection') {
    return valueToUse ? (
      <div key={nameSlug} className={`${classes?.fieldContainer}`}>
        <FieldLabel />
      </div>
    ) : null;
  }

  if (type === 'object') {
    return null;
  }

  return (
    displayedValue ? (
      <div key={nameSlug} className={classes?.fieldContainer}>
        <FieldLabel />
        <div className={classes?.fieldValue}>
          {displayedValue}
        </div>
      </div>
    ) : null
  );
}

/**
 * Obtiene el valor de visualización de una taxonomía según su tipo y valor almacenado.
 *
 * @param {any} value - El valor almacenado de la taxonomía.
 * @param {string} type - El tipo de taxonomía.
 * @param {object} taxonomyType - El objeto que representa el tipo de taxonomía.
 * @returns {string} - El valor de visualización correspondiente.
 */
const getDisplayedValue = (value, type, taxonomyType) => {
  if (type === 'date' && value) {
    return dayjs(value).format(taxonomyType.data?.show?.format || 'ddd, DD MMMM YYYY');
  }
  return value;
};

export const ViewField = (props) => {
  const {
    field,
    doc,
    taxonomyTypesDocList,
  } = props;
  const { user } = useAuth();
  const { isAllowed } = useModule();

  if (!taxonomyTypesDocList?.length || !field || !doc) return null;
  
  return getComponent({
    ...props,
    userAuth: user,
    isAllowed
  });
};

const EntityDocView = (props) => {
  let { entitySlug, render } = props;
  let { taxonomyTypesDocList, mainAttr, mainImgAttr } = useEntityFullBySlug({ entitySlug });
  let { user } = useAuth();
  let { isAllowed } = useModule();

  return (
    taxonomyTypesDocList?.length
    ? render({
      ViewData({ field, classes, decorator, DecoratorRender, ...viewProps }) {
        return taxonomyTypesDocList?.length ? getComponent({
          ...props,
          ...viewProps,
          taxonomyTypesDocList,
          field,
          classes: stackClasses(props?.classes, classes),
          decorator,
          DecoratorRender,
          userAuth: user,
          isAllowed
        }) : null;
      },

      doc: props?.doc,
      taxonomyTypesDocList, mainAttr, mainImgAttr
    })
    : null
  );
};

export const EntityDocListView = (props) => {
  const { doc, render, refreshers=[], taxonomyTypesDocList, mainAttr, mainImgAttr } = props;
  const { user } = useAuth();
  const { isAllowed } = useModule();

  // Memoriza la función ViewData para evitar que se recree en cada renderizado
  const ViewData = useCallback(({ value, field, classes, decorator, DecoratorRender, ...viewProps }) => {
    if (!taxonomyTypesDocList?.length) return null;
    return getComponent({
      ...props,
      ...viewProps,
      taxonomyTypesDocList,
      mainAttr,
      mainImgAttr,
      userAuth: user,
      isAllowed,
      ...refreshers,
      value,
      field,
      classes: stackClasses(props.classes, classes),
      decorator,
      DecoratorRender
    });
  }, [doc, taxonomyTypesDocList, user, ...refreshers]);

  // Memoriza la llamada a render para evitar que se recree en cada renderizado
  const memoizedRender = useMemo(() => {
    if (!taxonomyTypesDocList?.length) return null;

    return render({
      ViewData,
      doc,
      taxonomyTypesDocList, 
      mainAttr, 
      mainImgAttr,
      classes: props.classes
    });
  }, [doc, ViewData]);

  return memoizedRender;
};

export default EntityDocView;
