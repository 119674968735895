import _ from 'lodash';
import { useEffect, useMemo, useState } from "react";
import { IonAccordion, IonAccordionGroup, IonIcon, IonItem, IonLabel, IonList, IonMenuToggle } from "@ionic/react";
import { useAuth } from "../user/AuthContext";
import { useLocation } from "react-router-dom";
import { useModule } from "../../libs/ModuleContext";
import { usePanel } from "./usePanel";
import { useAsyncMemo } from "use-async-memo";
import config, { getMenuPages } from "../../config";
import Model from "../../libs/ModelClass";
import BadgeLoading from '../../components/ui/BadgeLoading';
import { useMenuIsSelected } from './MenuMainContent';


const entitiesEntitySlug = config.modules.entity.entitiesEntitySlug;
const EntityModel = Model.extend(entitiesEntitySlug);

const fetchEntities = async (queryFilter) => {
  let entities = await EntityModel.filterByAttributes(queryFilter);
  entities = entities?.filter(entity => !entity.data.deleted);
  entities = _.sortBy(entities, 'data.sort');
  return entities;
};

const MenuOfSections = (props) => {
  let { selectedSection, specsMenu, menuName, openMenu, closeMenu } = props;
  const { userAuth } = useAuth();
  const location = useLocation();
  const moduleLibs = useModule();
  const { isLoadingInstance, instance, selectedInstance, attachPrefix } = usePanel();
  const [ openAccordion, setOpenAccordion ] = useState();
  let { checkIsCurrentPage } = useMenuIsSelected();
  const { isAllowed } = moduleLibs;

  const sectionsList = useMemo(() => {
    if (selectedInstance && userAuth?.userDoc) {
      return specsMenu || getMenuPages({ menuName: selectedSection?.sectionKey || menuName, userAuth, isAllowed, selectedInstance, instance });
    }
    return [];
  }, [selectedSection, userAuth?.userDoc, selectedInstance?.id]);

  const entitiesDocsByTabs = useAsyncMemo(async () => {
    if (selectedInstance?.id) {
      let entitiesOfInstance = await fetchEntities({
        instanceId: selectedInstance?.id,
        isInstance: 'true'
      });
      let entitiesGlobals = await fetchEntities({
        isInstance: 'false'
      });
      let entities = entitiesOfInstance.concat(entitiesGlobals);
      entities = entities?.filter((entityDoc) => {
        let canShow = true;
        // access to section
        if (!isAllowed(entityDoc.data.nameSlug, ['section'])) {
          canShow = false;
        }
        // visibility of global entities for instances
        if (!entityDoc.data.isInstance && !entityDoc.data.isVisibleForInstance) {
          // if is global entity and not visible for instance
          canShow = false;
        }
        return canShow;
      });
      let byTabs = _.groupBy(entities || [], 'data.showInTab');
      return byTabs;
    }
    return {};
  }, [selectedInstance?.id]);

  useEffect(() => {
    if (selectedSection?.sectionKey) {
      let sectionsMenuSpecs = getMenuPages({ menuName: selectedSection.sectionKey, userAuth, isAllowed: moduleLibs.isAllowed, selectedInstance, instance });
      let currentParentSection = sectionsMenuSpecs?.find(section => {
        if (section?.items) {
          return section.items.find(item => {
            let isCurrent = checkIsCurrentPage(item);
            if (isCurrent) {
              return section;
            }
          });
        }
      });
      if (currentParentSection) {
        setOpenAccordion(currentParentSection.tabKey);
      }
    }
  }, [selectedSection, location]);

  const handleAccordionChange = (value) => {
    setOpenAccordion(value);
  };

  if (!selectedInstance || !entitiesDocsByTabs || isLoadingInstance) {
    return (
      <div className="flex px-4 py-2 place-content-start items-center font-brand-main">
        <BadgeLoading className="text-brand-dark" />
      </div>
    );
  }

  return (<>
    <IonAccordionGroup
      expand="inset"
      className="mx-1"
      value={openAccordion}
      onIonChange={(e) => handleAccordionChange(e.detail.value)}
    >
      {sectionsList?.map((section, index) => {
        let sectionItems = section.items?.filter((item) => (
          item.permissions
          ? isAllowed(item.permissions?.resource, item.permissions?.actions)
          : true
        ));
        return (
          (section?.permissions
            ? isAllowed(section?.permissions?.resource, section?.permissions?.actions)
            : true
          ) ? (
            // sub menu items
            section.tabKey ? (
              (
                sectionItems?.length
              ) ? (
                <IonAccordion value={section.tabKey} key={index}>
                  <IonItem slot="header" lines="none">
                    {section.ionIcon ? (
                      <IonIcon icon={section.ionIcon} color="light" className="mr-2" />
                    ) : null}
                    {section.faIcon ? (
                      <div className="py-3 pr-2">
                        <section.faIcon className="text-xl" />
                      </div>
                    ) : null}
                    <IonLabel className="">{section.title}</IonLabel>
                  </IonItem>
                  <div className="" slot="content">
                    {/* entities items */}
                    {entitiesDocsByTabs[section.tabKey]?.map((entityDoc, index) => (
                      <IonMenuToggle key={index} autoHide={false}>
                        <IonItem
                          routerLink={attachPrefix(`/a/entity/${entityDoc.data.nameSlug}/list`)}
                          className={location.pathname.includes(attachPrefix(`/a/entity/${entityDoc.data.nameSlug}/list`)) ? 'selected' : ''}
                        >
                          {/* <IonIcon icon={folderOutline} size="small"></IonIcon> */}
                          <IonLabel>
                            {entityDoc.data.name}
                          </IonLabel>
                        </IonItem>
                      </IonMenuToggle>
                    ))}
                    {/* extra section items */}
                    {sectionItems?.map((item, index) => (
                      <IonMenuToggle key={index} autoHide={false}>
                        <IonItem
                          routerLink={attachPrefix(item.url)}
                          className={location.pathname.includes(attachPrefix(item.url)) ? 'selected' : ''}
                        >
                          {item.ionIcon ? (
                            <IonIcon icon={item.ionIcon} size="small" className="mr-2 text-brand-light" />
                          ) : null}
                          {item.faIcon ? (
                            <div className="py-3 pr-2">
                              <item.faIcon className="text-brand-light text-xl" />
                            </div>
                          ) : null}
                          <IonLabel>
                            {item.title}
                          </IonLabel>
                        </IonItem>
                      </IonMenuToggle>
                    ))}
                  </div>
                </IonAccordion>
              ) : null
            )
            // single menu item
            : (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem
                  routerLink={attachPrefix(section.url)}
                  className={section.url === '/' ? '' : location.pathname.includes(attachPrefix(section.url)) ? 'selected' : ''}
                  // routerDirection="none"
                  lines="none"
                  detail={false}
                >
                  {section.ionIcon ? (
                    <IonIcon icon={section.ionIcon} color="light" />
                  ) : null}
                  {section.faIcon ? (
                    <div className="py-3 pr-2">
                      <section.faIcon className="text-brand-light text-xl" />
                    </div>
                  ) : null}
                  <IonLabel className="ml-2">{section.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            )
          ) : null
        );
      })}
    </IonAccordionGroup>
  </>);
};

export default MenuOfSections;