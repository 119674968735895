import React, { useEffect } from 'react';
import { useForm } from 'react-final-form';
import { useCreditCollectionsStore } from './useCreditCollectionsStore';
import { EntityShow } from '../../entity/RouteEntityCrudShow';
import { inputClasses } from "../../../components/EntityTaxonomyForm";
import { withPrefix } from '../../instance/utilsInstance';
import { SkeletonLoading } from '../../../components/ui/SkeletonLoading';
import ContractsListAccordion from '../contracts/ContractsListAccordion';


const contactsEntitySlug = "contacts";

const RelatedData = ({ instance }) => {
  const form = useForm();
  const { customerId, userId } = form.getState().values;
  const { contractsIds, contractsOfContact, contactClientDoc, fetchPaymentsByContract } = useCreditCollectionsStore();

  return (
    <div className={inputClasses?.fieldContainer}>
      {/* Contacto Cliente */}
      {contactClientDoc ? (
        <div className="mb-4">
          <div className={inputClasses?.fieldLabel}>
            <span>Contacto Cliente</span>
          </div>
          <EntityShow
            docId={contactClientDoc.id}
            entitySlug={withPrefix(instance, contactsEntitySlug)}
            showBtns={false}
            Loader={SkeletonLoading}
          />
        </div>
      ) : null}
    </div>
  );
};

export default RelatedData;
