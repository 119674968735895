import _ from 'lodash';
import { IonBadge, IonButton, IonButtons, IonCheckbox, IonIcon, IonRippleEffect } from '@ionic/react';
import { useLocation } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { useEntityFullBySlug } from './Entity';
import { 
  cog,
  checkboxOutline,
  closeOutline,
  eyeOutline
} from 'ionicons/icons';
import { LayoutAdmin } from "../panel/LayoutAdmin";
import SectionCrudModel from "../../components/SectionCrudModel";
import Model, { useStateSingleResult } from "../../libs/ModelClass";
import PartOfModule from '../../components/Module/PartOfModule';
import { useModule } from "../../libs/ModuleContext";
import { useListDoc } from './docCardsVariants';
import { parseAttrValParams } from '../../libs/utils';
import BadgeLoading from '../../components/ui/BadgeLoading';
import { usePanel } from '../panel/usePanel';
import { InstanceInlineSelectorForMain } from '../instance/InstanceSelector';
import { useCanAddMoreDocs } from './MaxCountLimitAlert';
import { useModuleSettings } from './useModuleSettings';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
// import { useFilterPagination } from './useFilterPagination';


// TODO
// Mostrar/Ocultar botón ADD
// Mostrar barra de filtros
export function EntityCrudList(props) {  
  let {
    context,
    selectedInstance,
    entitySpecs,
    history,
    editStyle = "route",
    parsedParams,
    processFilter,
    navigateTo,
    handleActions,
    fetchItems,
    filterMenuSlug,
    showBtnShow = true,
    showBtnCrud = true,
    ExtraFields,
    ExtraActions,
    ItemExtraActions,
    showSelectionUI = true,
    isSelectionActivate = false,
    selectedDocsIds,
    onSelection,
    onSeleccionActivate,
    onSeleccionCancel,
    EmptyContent,
    forceAllowed = false
  } = props;
  const match = useRouteMatch();
  const { isAllowed } = useModule();
  const location = useLocation();
  const { contractModelMetaData, attachPrefix } = usePanel();
  const [ refresh, doRefresh ] = useState(0);
  const [ selectedDocs, setSelectedDocs ] = useState([]);
  const [ isShowSelectionMode, setShowSelectionMode ] = useState(isSelectionActivate);
  
  const entityDoc = props.entityDoc || entitySpecs?.entityDoc;
  const entitySlug = entityDoc?.data?.nameSlug || entitySpecs?.entitySlug;
  const ExtendedModel = Model.extend(entitySlug);
  
  const ListDoc = useListDoc({ entitySpecs, isAllowed, ExtraFields });
  const { moduleSettings } = useModuleSettings({ entitySlug, contractModelMetaData });
  const canAddMoreDocs = useCanAddMoreDocs(entitySlug, moduleSettings, selectedInstance, refresh);

  useEffect(() => {
    doRefresh(refresh + 1);
  }, [location]);

  // asignar docs faltantes
  useEffect(() => {
    syncSelectedDocsWithIds(selectedDocsIds);
  }, [selectedDocsIds, selectedDocs]);
  
  const validateForm = () => {};
  const handleBeforeSave = () => {};
  const handleDelete = () => {};

  const hardFilter = props.hardFilter || context?.hardFilter || (
    async(query) => {
      // hard filter for main instance with instance hash as prefix
      if (!entityDoc?.data.isInstance && selectedInstance?.id && selectedInstance?.data.hash !== 'main') {
        query['instanceId'] = selectedInstance.id;
      }
      return query;
    }
  );

  // tenemos selectedDocs como state local y selectedDocsIds como prop, debemos mantener los selectedDocs sincronizados
  const syncSelectedDocsWithIds = async (selectedDocsIds) => {
    if (selectedDocsIds?.length) {
      // comparar con selectedDocs y obtener los ids faltantes
      const missingIds = _.difference(selectedDocsIds, selectedDocs.map(doc => doc.id));
      if (missingIds.length) {
        // fetch missing docs
        const newSelectedDocs = await ExtendedModel.where('id', 'in', missingIds);
        setSelectedDocs([...newSelectedDocs, ...selectedDocs]);
      }      
    }
  };

  const assignSelectedDoc = (doc) => {
    let newSelectedDocs = selectedDocs.some(({ id }) => id === doc.id)
                            ? selectedDocs.filter(({ id }) => id !== doc.id)
                            : [...selectedDocs, doc];
    setSelectedDocs(newSelectedDocs);
    onSelection?.(doc, newSelectedDocs);
  };

  const handleActivateSelection = () => {
    setShowSelectionMode(true);
    onSeleccionActivate?.(); // Callback opcional para activar selección
  };

  const handleCancelSelection = () => {
    setShowSelectionMode(false);
    onSeleccionCancel?.(); // Callback opcional para cancelar selección
  };
  
  const ExtraActionsToUse = () => (<>
    {/* module extra actions */}
    <PartOfModule
      type="crud"
      action="ListHeaderExtraActions"
      entitySlug={entitySlug}
      param={{ Model, isAllowed, canAddMoreDocs, entitySlug, entityDoc, location, entitySpecs, moduleSettings, attachPrefix }}
    />
    {/* Selected Count */}
    {showSelectionUI && (
      <IonButtons slot="end" className="flex items-center space-x-2">
        {!isShowSelectionMode ? (
          <IonButton fill="clear" size="small" onClick={handleActivateSelection}>
            <IonIcon icon={checkboxOutline} slot="start" />
            Seleccionar
          </IonButton>
        ) : (
          <>
            <IonBadge fill="clear" size="small" color="secondary">
              {selectedDocs?.length || 0} Seleccionados
            </IonBadge>
            <IonButton fill="clear" color="medium" size="small" onClick={handleCancelSelection}>
              <IonIcon icon={closeOutline} slot="start" />
            </IonButton>
          </>
        )}
      </IonButtons>
    )}
    {/* go to entity config */}
    {(selectedInstance?.id && selectedInstance?.data.hash === 'main') && isAllowed('panel', ['tab:config']) ? (
      <IonButtons slot="end">
        <IonButton
          routerLink={attachPrefix(`/a/entityCreator/${entityDoc?.data.id}/form`)}
          size="small"
          fill="clear"
          color="secondary"
        >
          <IonIcon icon={cog}></IonIcon>
        </IonButton>
      </IonButtons>
    ) : null}
    {/* props extra actions */}
    {ExtraActions && <ExtraActions />}
  </>);
  
  // items actions
  const ListBtns = context?.ListBtns || (({ doc }) => {
    const isSelected = !!_.find(selectedDocsIds, (selectedId) => (selectedId === doc.id));

    return <>
      {/* select checkbox */}
      {showSelectionUI && isShowSelectionMode ? (
        <IonButton onClick={() => assignSelectedDoc(doc)} size="small" fill="clear" color={isSelected ? 'secondary' : 'medium'} shape="round">
          <IonCheckbox
            slot="start"
            checked={isSelected ? "checked" : null}
            color={isSelected ? "secondary" : "medium"}
          />
        </IonButton>
      ) : null}
      {/* show */}
      {isAllowed(entitySlug, ['read', 'instance:read']) && showBtnCrud && showBtnShow && moduleSettings.showBtnShow ? (
        <IonButton
          size="small"
          fill="clear"
          color="primary"
          routerLink={attachPrefix(`/a/entity/${entityDoc?.data.nameSlug}/${doc.id}`)}
        >
          <IonIcon slot="start" icon={eyeOutline}></IonIcon>
          Ver
        </IonButton>
      ) : null}
      {/* module items extra actions */}
      <PartOfModule
        type="crud"
        action="ListItemExtraActions"
        entitySlug={entitySlug}
        param={{ doc, Model, isAllowed, canAddMoreDocs, entitySlug, entityDoc, location, entitySpecs, moduleSettings, attachPrefix }}
      />
    </>;
  });
  
  if (entitySpecs?.isLoading) {
    return (
      <div className="py-12 flex place-content-center content-center items-center font-brand-main">
        <BadgeLoading className="text-brand-dark" />
      </div>
    );
  }

  if (!forceAllowed && !isAllowed(entitySlug, ['list', 'instance:list'])) {
    return null;
  }

  let dataMode = "all";
  let listStyle = "list";
  if (!fetchItems && entitySpecs?.filterMenuDocList?.find(item => item.data.nameSlug === filterMenuSlug)) {
    dataMode = "filter-paginated";
    listStyle = "filter-paginated";
  }
  dataMode = props.dataMode ? props.dataMode : dataMode;
  listStyle = props.listStyle ? props.listStyle : listStyle;

  let navigateToToUse = navigateTo || ((viewType, doc) => (
    viewType === 'form' && attachPrefix(`/a/entity/${entitySpecs?.entitySlug}/${doc ? doc.id : 'new'}/form`)
  ));

  // overrule navigateTo
  if (handleActions) {
    navigateToToUse = null;  
  }

  const canShowBtnAdd = props.showBtnAdd // top priority
                        || moduleSettings.showBtnAdd === false // second priority, if true check next priority
                          ? false 
                          : canAddMoreDocs?.canAddMoreDocs; // third priority

  return (
    <SectionCrudModel
      {...moduleSettings}
      {...props}
      model={ExtendedModel}
      entitySlug={entitySlug}
      editStyle={editStyle}
      dataMode={dataMode}
      listStyle={listStyle}
      
      navigateTo={navigateToToUse}
      handleActions={handleActions}
      refresh={refresh}
      onRefresh={() => doRefresh(refresh + 1)}

      // callbacks 
      fetchItems={fetchItems}
      hardFilter={hardFilter}
      onValidation={validateForm}
      handleBeforeSave={handleBeforeSave}
      onDelete={handleDelete}

      // UI
      ListItem={ListDoc}
      ListBtns={ListBtns}
      ExtraActions={ExtraActionsToUse}
      EmptyContent={EmptyContent}

      // show
      showBtnAdd={canShowBtnAdd}

      // dataMode 'filter-paginated'
      filterPath={match.url}
      entitySpecs={entitySpecs}
      queryParams={parsedParams}
      processFilter={processFilter}
    />
  );
}

export function EntityList(props) {
  let {
    // only,
    // exclude,
    // instance,
    // entitySlug,
    // parsedParams
    // history,
    // location,
    match,
    Loader
  } = props;
  const entitySlug = props.entitySlug || match.params.entitySlug;
  const entitySpecs = useEntityFullBySlug({ entitySlug: entitySlug || 'entity' });
  if (
    entitySpecs?.isLoading
  ) {
    if (Loader) {
      return <Loader />;
    }
    return (
      <div className="py-12 flex place-content-center content-center items-center font-brand-main">
        <BadgeLoading className="text-brand-dark" />
      </div>
    );
  }

  return (
    <EntityCrudList
      {...props}
      entitySpecs={entitySpecs}
      // fetchItems={props.fetchItems}
      navigateTo={props.goToForm}
      filterMenuSlug={props.filterMenuSlug || 'panelSearch'}
    />
  );
}

export function RouteEntityCrud(props) {
  let {
    context,
    withLayout = true,
    match,
    history,
    location,
    showInstanceSelector = true
  } = props;
  const { selectedInstance, attachPrefix } = usePanel();
  const [ selectedInner, setSelectedInner ] = useState(selectedInstance);
  const { action, 0: attrValParams } = props.match.params;
  const entitySlug = context?.entitySlug || match.params.entitySlug;
  const parsedParams = useMemo(() => {
    return {
      ...(props.parsedParams || {}),
      ...parseAttrValParams( attrValParams ),
      ...parseAttrValParams( location.hash.replace('#/', '') )
    };
  }, [location.hash]);
  const filterMenuSlug = context?.filterMenuSlug || 'panelSearch';
  const entitySpecs = useEntityFullBySlug({ entitySlug, filterMenuSlug });
  const entityDoc = entitySpecs?.entityDoc;
  
  const breadcrumbs = context?.breadcrumbs || [
    {
      title: entityDoc?.data?.name
    }
  ];

  useEffect(() => {
    if (selectedInstance?.id && selectedInstance.id !== selectedInner?.id) {
      setSelectedInner(selectedInstance);
    }
  }, [selectedInstance?.id]);

  // for Main instance
    // show instance selector for global entities from main instance
  let TitleToolbarRightToUse = context?.TitleToolbarRight;
  if (selectedInstance?.data.hash === 'main' && showInstanceSelector) {
    TitleToolbarRightToUse = (() => (
      entityDoc?.data && !entityDoc.data.isInstance && (
        <InstanceInlineSelectorForMain selectedInstance={selectedInner} setSelectedInstance={setSelectedInner} />
      )
    ));
  }

  // for others instances
    // dont show instance selector
    // hardFilter lists
    // attach instanceId to saved docs

  if (
    !entityDoc?.id
    || !selectedInner?.id
  ) {
    return (
      <div className="py-12 flex place-content-center content-center items-center font-brand-main">
        <BadgeLoading className="text-brand-dark" />
      </div>
    );
  }

  const content = (
    <EntityCrudList
      {...props}
      {...{
        entitySpecs,
        parsedParams,
        attachPrefix,
        ...(context?.goToForm && { navigateTo: context.goToForm }),
        ...(context?.fetchItems && { fetchItems: context.fetchItems }),
      }}
      filterMenuSlug={filterMenuSlug}
      selectedInstance={selectedInner}
      showEmptyMessage={context?.showEmptyMessage}
    />
  );  

  if (!withLayout) {
    return content;
  }
  
  return (
    <LayoutAdmin 
      history={props.history} 
      defaultHref={context?.defaultBackRoute}
      breadcrumbs={breadcrumbs}
      TitleToolbarRight={TitleToolbarRightToUse}
    >
      {content}
    </LayoutAdmin>
  );
}
