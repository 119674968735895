import dayjs from "dayjs";
import Model from "../../../libs/ModelClass";
import { withPrefix } from "../../instance/utilsInstance";
import toast from "react-hot-toast";


export const afterRead = async (props, docData) => {
  if (!docData.id)  {
    docData.paidDate = docData.paidDate || dayjs().format('YYYY-MM-DD');
  }
  return docData;
};

// omit tmp vars beforeSave
export const beforeSave = async ({ instanceDoc, entityDoc }, formValues) => {
    // omit tmp vars beforeSave
    let data = { ...formValues };
    Reflect.deleteProperty(data, 'tmp');
    return data;
};

// update payments status to paid and set paidDate
export const afterSave = async (props, savedDoc) => {
  let {
    doc,
    formValues,
    isEdit,
    instance
  } = props;
  if (doc.data.paymentsIds?.length) {
    let PaymentsModel = Model.extend(withPrefix(instance, 'payments'));
    // TODO save with batch
    for (const paymentId of formValues.paymentsIds) {
      let paymentDoc = await PaymentsModel.findById(paymentId);
      if (paymentDoc) {
        paymentDoc.data.status = 'paid';
        paymentDoc.data.paidDate = formValues.paidDate;
        paymentDoc.data.collectionId = doc.id;
        await paymentDoc.save();
      }
    }
    toast.success('Pagos actualizados correctamente');
  }
};