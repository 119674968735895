import { useEffect, useState } from 'react';
import _ from 'lodash';
import { LayoutAdmin } from "../panel/LayoutAdmin";
import SectionCrudForm from "../../components/SectionCrudForm";
import Entity from './Entity';
import { Content as RouteEntityTaxonomy } from './RouteEntityCreatorTaxonomy';
import { Content as RouteEntityFilterMenu } from './RouteEntityCreatorFilterMenu';

import { 
  FormField,
  FormFieldCheckboxToggle,
  FormFieldSelect,
  FormFieldSelectDocumentModel,
} from '../../components/Form';
import { sortDocsByField } from '../../libs/utils';
import { usePanel } from '../panel/usePanel';
import { inputClasses } from '../../components/EntityTaxonomyForm';
import config from '../../config';


export function Content(props) {
  let {
    history, match, attachPrefix
  } = props;
  const { entityId } = match.params;
  const [selectedEntity, setSelectedEntity] = useState({});
  const [entitiesDocList, setEntitiesDocList] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      if (entityId === 'new') {
        setSelectedEntity({});
      } else {
        const entity = await Entity.findById(entityId);
        setSelectedEntity(entity);
      }
    };
    fetch();
  }, [entityId]);
  
  useEffect(() => {
    const fetch = async () => {
      if (!entitiesDocList) {
        let entityList = await Entity.getAll();
        entityList = sortDocsByField(entityList, 'sort');
        entityList = entityList.filter(entity => !entity?.data?.deleted);
        setEntitiesDocList(entityList);
      }
    };
    fetch();
  }, [entitiesDocList]);

  const handleSave = async (formValues) => {
    if (!formValues.nameSlug) {
      formValues.nameSlug = _.camelCase(formValues.name || '');
    }    
    // Lógica para guardar la entidad
    const savedItem = await Entity.createOrUpdate(formValues);
    if (entityId === 'new') {
      history.push(attachPrefix(`/a/entityCreator/${savedItem.id}/form`));
    }
  };

  const fieldsRequired = ['name'];

  const onValidation = (values, errors) => {
    if (!values.name) {
      errors.name = ' ';
    }
  };

  const FormInputFields = (({ form, values, classes }) => (
    <div className="space-y-8">
      <FormField name="name" title="Nombre" placeholder="Nombre" fieldsRequired={fieldsRequired} classes={classes} />
      <FormField name="nameSlug" title="ID" placeholder={_.camelCase(values?.name || '')} fieldsRequired={fieldsRequired} classes={classes} />
      <FormFieldCheckboxToggle
        name="isInstance"
        title="Entidad de instancia"
        textTrue="si, entidad de instancia"
        textFalse="no, entidad global"
        colorFalse="gray-500"
        defaultValue={false}
        classes={classes}
      />
      {values.isInstance ? (
        <FormFieldSelectDocumentModel
          name="instanceId"
          modelName="instances"
          title="Instancia"
          placeholder="Instancia"
          classes={classes}
          onSelect={(optionValue, selectedOption) => {
            let { doc } = selectedOption || {};
            let nameSlug = values.nameSlug;
            // add prefix
            if (doc) {
              let slugParts = nameSlug.split('.');
              if (slugParts.length > 1) {
                nameSlug = `${doc?.data.hash}.${slugParts[1]}`;
              }
              else {
                nameSlug = `${doc?.data.hash}.${nameSlug}`;
              }
            }
            // remove prefix
            else {
              let slugParts = nameSlug.split('.');
              if (slugParts.length > 1) {
                nameSlug = slugParts[1];
              }
            }
            form.change('nameSlug', nameSlug);
          }}
        />
      ) : null}

      {entitiesDocList ? (
        <>
          <FormFieldSelect
            name="showInTab"
            title="Mostrar en menú"
            fieldsRequired={fieldsRequired}
            options={config.modules.panel.tabsOptions}
            classes={classes}
          />
          <FormFieldCheckboxToggle
            name="isVisibleForInstance"
            title="Visibilidad para Instancias"
            textTrue="si, visible para Instancias"
            textFalse="no, sólo desde main"
            colorFalse="gray-500"
            defaultValue={false}
            classes={classes}
          />
          {/* <FormFieldSelect 
            name="entityParent"
            title="Entidad superior"
            fieldsRequired={fieldsRequired}
            options={entitiesDocList.map((doc) => ({
              value: doc?.id,
              label: doc?.data?.name
            }))}
          /> */}
        </>
      ) : null}

      {entitiesDocList ? (
        <div>
          <h2 className=" border-b border-gray-300">Datos relacionados</h2>
          <p className="!mb-4 text-xs text-gray-500 font-normal">ID de entidades relacionadas separadas por coma.</p>
          <div className="grid grid-cols-2 gap-2">
            <div className="">
              <FormField name="relSlugsBelongsTo" title="belongsTo" classes={{fieldLabel: 'text-xs font-normal', ...classes}} />
              <FormField name="relSlugsHasOne" title="hasOne" classes={{fieldLabel: 'text-xs font-normal', ...classes}} />
            </div>
            <div className="">
              <FormField name="relSlugsBelongsToMany" title="belongsToMany" classes={{fieldLabel: 'text-xs font-normal', ...classes}} />
              <FormField name="relSlugsHasMany" title="hasMany" classes={{fieldLabel: 'text-xs font-normal', ...classes}} />
            </div>
          </div>
        </div>
      ) : null}

      {entitiesDocList ? (
        <div>
          <h2 className="!mb-4 border-b border-gray-300">Limites</h2>
          <FormField type="number" name="limitMaxRecords" title="Cantidad máxima de registros" classes={{fieldLabel: 'text-xs font-normal', ...classes}} />      
        </div>
      ) : null}

    </div>
  ));

  return (<>
    {/* Entity Form */}
    <SectionCrudForm
      title={'Entidad ' + selectedEntity?.data?.name}
      doc={selectedEntity}
      onSave={handleSave}
      editStyle="route"
      // validatiion
      fieldsRequired={fieldsRequired}
      onValidation={onValidation}
      FormInputFields={FormInputFields}

      classes={inputClasses}
    />

    {entityId !== 'new' ? (<>
      {/* FilterMenu */}
      <RouteEntityFilterMenu entityId={entityId} entitySlug={selectedEntity?.data?.nameSlug} />
      
      {/* Taxonomy */}
      <RouteEntityTaxonomy entityId={entityId} entitySlug={selectedEntity?.data?.nameSlug} entityDoc={selectedEntity} />
    </>) : ''}
  </>);
}

export function RouteEntityCreatorForm(props) {
  const { attachPrefix } = usePanel();
  const sectionUrl = attachPrefix(`/a/entityCreator/list`);

  return (
    <LayoutAdmin 
      history={props.history} 
      defaultHref={sectionUrl}
      breadcrumbs={[{
        url: sectionUrl,
        title: 'Entidades'
      }, {
        title: "Formulario"
      }]}
    >
      <Content {...props} attachPrefix={attachPrefix} />
    </LayoutAdmin>
  );
}
