import { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { IonMenu, IonRouterOutlet, IonSplitPane } from '@ionic/react';

import { useAuth } from "./modules/user/AuthContext";

import { RouteEntityCrud } from "./modules/entity/RouteEntityCrud";
import { RouteEntityCrudForm } from "./modules/entity/RouteEntityCrudForm";
import { RouteEntityCrudShow } from "./modules/entity/RouteEntityCrudShow";
import { RouteEntityCreator } from "./modules/entity/RouteEntityCreator";
import { RouteEntityCreatorForm } from "./modules/entity/RouteEntityCreatorForm";

import { AdminModulePage } from "./modules/panel/AdminModulePage";

import { AdminUserCustomerData } from "./modules/user/AdminUserCustomerData";
import { AdminUserSetCustomerData } from "./modules/user/AdminUserSetCustomerData";
import { PanelProvider, usePanel } from "./modules/panel/usePanel";
import { MenuMainContent } from "./modules/panel/MenuMainContent";
import { withProvider } from "./libs/utils";
import { SettingsLoader } from "./modules/panel/useSettings";
import { DesignLoader } from "./modules/blockStack/DesignLoader";
import { SecretaryProvider } from "./modules/omniChat/useSecretary";
import { AdminTabHome } from "./modules/panel/AdminTabHome";


export function LayoutPanel(props) {
  const { children } = props;
  let { menuMainShowType, setMenuMainShowType } = usePanel();

  const getPanelMenuType = () => {
    if (menuMainShowType === 'auto') {
      return 'overlay';
    } else {
      return menuMainShowType;
    }
  };

  const shouldShowSplitPane = menuMainShowType !== 'hidden';

  return (
    shouldShowSplitPane ? (
      <IonSplitPane contentId="main" when="xl">
        {/* Menu */}
        <IonMenu
          type={getPanelMenuType()}
          contentId="main"
          side="start"
          menuId="main"
          className="md:z-10"
        >
          <MenuMainContent triggerId="main" history={props.history} menuName="main" />
        </IonMenu>

        <IonRouterOutlet id="main">
          {children}
        </IonRouterOutlet>
      </IonSplitPane>
    ) : (
      <IonRouterOutlet id="main">
        {children}
      </IonRouterOutlet>
    )
  );
}

function Panel (props) {
  const { match, history } = props;
  const { userAuth } = useAuth();

  const attachPrefix = (url) => {
    return match.params?.instance ? `/${match.params.instance}${url}` : url;
  }

  useEffect(() => {
    if (userAuth && !userAuth?.userDoc?.data) {
      history.push(attachPrefix('/login'));
    }
  }, [userAuth]);
  
  // TODO kickout based on appPage's permissions

  return (
    <Switch>
      {/* TAB Panel main */}
      <Route path={["/a/panel", "/:instance/a/panel"]}>
        <IonRouterOutlet>
          <Switch>
            <Route path={["/a/panel", "/:instance/a/panel"]} exact component={AdminTabHome}/>
          </Switch>
        </IonRouterOutlet>
      </Route>

      {/* Entity Creator */}
      <Route path={["/a/entityCreator", "/:instance/a/entityCreator"]}>
        <IonRouterOutlet>
          <Switch>
            <Route path={["/a/entityCreator/list", "/:instance/a/entityCreator/list"]} component={RouteEntityCreator}/>
            <Route path={["/a/entityCreator/:entityId/form", "/:instance/a/entityCreator/:entityId/form"]} component={RouteEntityCreatorForm}/>
          </Switch>
        </IonRouterOutlet>
      </Route>

      {/* User */}
      <Route path={["/a/user", "/:instance/a/user"]}>
        <IonRouterOutlet>
          <Route path={["/a/user/update-data", "/:instance/a/user/update-data"]} component={AdminUserSetCustomerData}/>
          <Route path={["/a/user/set-data", "/:instance/a/user/set-data"]} render={props => 
              <AdminUserSetCustomerData 
                {...props} 
                mode="register"
                showGoBack={false}
                showMenu={false}
                showGoToProfile={false}
              />
            }
          />
          <Route path={["/a/user/data", "/:instance/a/user/data"]} component={AdminUserCustomerData}/>
        </IonRouterOutlet>
      </Route>

      {/* Entity */}
      <Route path={["/a/entity", "/:instance/a/entity"]}>
        <IonRouterOutlet>
          <Switch>
            <Route path={["/a/entity/:entitySlug/list", "/:instance/a/entity/:entitySlug/list"]} exact component={RouteEntityCrud}/>
            <Route path={["/a/entity/:entitySlug/:docId/form", "/:instance/a/entity/:entitySlug/:docId/form"]} exact component={RouteEntityCrudForm}/>
            <Route path={["/a/entity/:entitySlug/:docId", "/:instance/a/entity/:entitySlug/:docId"]} exact component={RouteEntityCrudShow}/>
          </Switch>
        </IonRouterOutlet>
      </Route>

      {/* Module custom routes */}
      <Route path={["/a/:entitySlug/:action", "/:instance/a/:entitySlug/:action"]} exact component={AdminModulePage}/>
    </Switch>
  );
}

export const PanelWrapped = withProvider(Panel, [
  PanelProvider,
  SettingsLoader,
  DesignLoader,
  SecretaryProvider,
  LayoutPanel
]);